<template>
  <div class="instructions">
    <ion-grid class="ion-no-padding">
      <ion-row v-if="settings.display_pretest_instruction">
        <ion-col class="instructions__list">
          <div class="instructions__list--title">
            <ion-label class="ion-text-wrap">{{ state.language_set.test_information_title }}</ion-label>
          </div>
          <ul>
            <li v-if="data.num_of_questions && data.num_of_questions>0">
              {{ state.language_set.test_information_noq }}: <strong>{{ data.num_of_questions }}</strong>
            </li>

            <li v-if="settings.display_time_limit">
              {{ state.language_set.test_information_time_limit }}: <strong>{{
                convertDurationString(settings.display_time_limit)
              }}</strong>
            </li>

            <li v-if="isGroupTest">
              {{ state.language_set.test_information_attempts }}: <strong>{{ getAttemptsText() }}</strong>
            </li>

            <li v-if="settings.resume_later == false">
              <ion-label class="ion-text-wrap">{{ state.language_set.test_information_no_sfl }}</ion-label>
            </li>
            <li v-else>
              <ion-label class="ion-text-wrap">{{ state.language_set.test_information_sfl }}</ion-label>
            </li>

            <li v-if="settings.questions_per_page > 0">
              <ion-label class="ion-text-wrap">{{ state.language_set.test_information_qpp }}:
                <strong>{{ settings.questions_per_page }}</strong></ion-label>
            </li>

            <li v-if="settings.allow_click_previous == false">
              <ion-label class="ion-text-wrap">{{ state.language_set.test_information_cant_go_back }}</ion-label>
            </li>
            <li v-else>
              <ion-label class="ion-text-wrap">{{ state.language_set.test_information_can_go_back }}</ion-label>
            </li>

            <li v-if="settings.must_answer_question == false">
              <ion-label class="ion-text-wrap">{{
                  state.language_set.test_information_must_answer_all_false
                }}
              </ion-label>
            </li>
            <li v-else>
              <ion-label class="ion-text-wrap">{{
                  state.language_set.test_information_must_answer_all_true
                }}
              </ion-label>
            </li>


            <li v-if="settings.passmark != null && settings.passmark>0">
              <ion-label class="ion-text-wrap">{{ state.language_set.test_information_passmark }}: <strong>
                {{ settings.passmark }}%</strong></ion-label>
            </li>
          </ul>

        </ion-col>
      </ion-row>
      <ion-row v-if="data.test_instruction!=null && data.test_instruction!=''" class="test-instruction">
        <html-content :content="data.test_instruction"></html-content>
      </ion-row>
    </ion-grid>

  </div>
</template>

<script>
import {IonCol, IonGrid, IonLabel, IonRow} from '@ionic/vue';
import timeUtil from "../../utils/timeUtil";
import {computed, reactive} from "vue";
import store from "@/store";
import HtmlContent from "../html/HtmlContent";
import constant from "../../constant";

export default {
  name: "Instruction",
  components: {
    HtmlContent,
    IonCol,
    IonLabel,
    IonGrid,
    IonRow
  },
  props: {
    settings: Object,
    data: Object
  },
  setup(props, ctx) {
    const state = reactive({
      language_set: store.getters.languageSet
    });


    const {convertDurationString} = new timeUtil()

    function getAttemptsText() {
      let attempts_text = '';
      switch (props.data.attempts_allowed) {
        case constant.ATTEMPTS_UNLIMITED: //unlmited attempts for group
          attempts_text = state.language_set['test_information_unlimited_attempts'];
          break;
        default:
          attempts_text = props.data.attempts_allowed;
          break;
      }
      return attempts_text;

    }

    const isGroupTest = computed(() => {
      if (store.getters.groupType == constant.GROUP_TYPE_GROUP) {
        return true;
      } else {
        return false;
      }
    });


    return {state, convertDurationString, getAttemptsText, isGroupTest};
  },
};
</script>

<style lang="scss" scoped>

.instructions {


  &__list {
    //margin: $medium-space 0;
    margin: 0;
    padding: $medium-space $large-space $large-space;

    background-color: $color-gray-01;
    border-radius: $default-border-radius;
    margin-bottom: 32px;

    ul {
      padding-left: 20px;

      li {
        color: $color-gray-primary-2;

        &:not(:last-child) {
          margin-bottom: $small-space;
        }

        &::marker {
          font-weight: bold;
          color: $color-gray-primary;
          font-size: $medium-2-font-size;
        }

        & strong {
          margin-left: $small-space;
        }
      }
    }


    &--title {
      font-weight: bold;
      margin-bottom: $medium-space;
    }
  }
}

.test-instruction {
  margin-bottom: 32px;
  overflow-x:auto;
}


</style>