<template>
  <div class="banner" v-if="state.display">
    <p id="powered_by">Powered by</p>
    <p>
      <a href="https://www.classmarker.com/" target="new"
         title="ClassMarker - Professional Online Testing Quiz maker">

          <img id="cm_logo" data-cy="logo-classmarker"
               :src="BASE_URL+'static/images/classmarker-logo.svg'"
               alt="ClassMarker logo" width="90"/>
      </a>
    </p>
    <p>
      <a href="https://www.classmarker.com/" target="new"
         title="ClassMarker - Professional Online Testing Quiz maker" >Online Quiz Maker</a>
    </p>
  </div>
</template>

<script>

import {defineComponent, reactive} from 'vue';
import store from "@/store";

export default defineComponent({
  name: 'CmPowerByBanner',
  components: {},
  props: {},
  setup(props, ctx) {
    let state = reactive({
      display: store.getters.theme.display_cm_powered_by
    });
    const BASE_URL = process.env.BASE_URL;
    return {state,BASE_URL}
  },


});
</script>

<style lang="scss" scoped>
.banner {

  position: relative;
  margin: auto;
  width: 250px;
  border-top: solid #ddd 1px;
  border-bottom: solid #ddd 1px;
  margin: auto;
  margin-bottom: auto;
  padding: 10px 0;
  //margin-bottom: 100px;
}

p {
  text-align: center;
  margin: 3px;
  padding: 0;
  font-size: 13px;
  line-height: 15px;
}
a{
  color:$text-color-primary !important;
}

#powered_by{
 font-size: 11px;
}
#cm_logo{
    margin-top: 4px;
    margin-bottom: 4px;
    width: 115px;
}
</style>
