<template>
  <ion-grid style="width:100%">

    <ion-row>
      <ion-list class="checkbox-list ion-no-padding" lines="none" style="width:100%">
        <ion-list-header class="check-list-header ion-no-padding">
          <div class="input">
            <ion-label class="ion-text-wrap label default-font overflow-x-auto" v-bind:class="{'required':mustAnswer, 'error':error}"
                       aria-labelledby="">
              <span id="question-text">
                <html-content :content="extraInfoQuestion.question"
                              :allow-magnifying-image="true"></html-content>
              </span>
            </ion-label>
          </div>
        </ion-list-header>

        <div class="answers-area" :class="{'error':error}" role="group" title="group"
             :aria-labelledby="'extra-info-'+id">

          <div class="non-visible" :id="'extra-info-'+id">
            <html-content :content="extraInfoQuestion.question"
                          :allow-magnifying-image="false"></html-content>
          </div>

          <ion-item v-for="(answer,index) in extraInfoQuestion.answers" :key="index">
            <div class="checkbox-options-area">
              <ion-checkbox mode="md" color="secondary" @ionChange="updateAnswers(id, answer)"
                            :checked="answerSelected(answer)"
                            :class="displayErrorClass"></ion-checkbox>
              <ion-label class="ion-text-wrap checkbox-label">{{ answer }}</ion-label>
            </div>
          </ion-item>
        </div>
      </ion-list>

    </ion-row>

    <ion-row>
      <div class="form_error">{{ error }}</div>
    </ion-row>

  </ion-grid>
</template>


<script>
import {IonCheckbox, IonGrid, IonItem, IonLabel, IonList, IonListHeader, IonRow} from '@ionic/vue';
import {computed, reactive} from "vue";
import UpdateAnswerMessageEmitter from "./UpdateAnswerMessageEmitter";
import HtmlContent from "../html/HtmlContent";


export default {
  name: "ExtraQuestionCheckBox",
  components: {
    HtmlContent,
    IonList,
    IonCheckbox,
    IonLabel,
    IonItem,
    IonListHeader,
    IonGrid,
    IonRow
  },
  props: {
    extraInfoQuestion: {
      type: Object,
      default() {
        return {
          questions: null,
          answers: [], // string array,
          user_answer: []
        }
      }
    },
    id: Number,
    mustAnswer: {
      type: Boolean
    },
    error: {
      type: String,
      required: false,
      default: null,
    }
  },
  emits: ['answer_change'],
  setup(props, ctx) {
    const state = reactive({
      selected_answers: props.extraInfoQuestion.user_answer ?? []
    });


    function updateAnswers(question_id, value) {
      let index = -1;
      for (let [idx, answer] of state.selected_answers.entries()) {
        if (answer === value) {
          index = idx;
          break;
        }
      }

      if (index != -1) {
        state.selected_answers.splice(value, 1);
      } else {
        state.selected_answers.push(value);
      }

      // use composition api to setup answer message emitter
      const {emitAnswers} = UpdateAnswerMessageEmitter(ctx);
      emitAnswers(question_id, state.selected_answers);
    }

    const displayErrorClass = computed(() => {
      if (props.error) {
        return 'error';
      }
    })

    function answerSelected(value) {
      if (state.selected_answers != null && state.selected_answers.includes(value)) {
        return true
      }
    }

    return {
      state, updateAnswers, displayErrorClass, answerSelected
    };
  },
};
</script>

<style scoped lang="scss">
//@import '@/theme/variables';
//@import '@/theme/mixins';


.check-list-header {
  min-height: 22px !important;
}

.checkbox-list {
  max-width: 100%;

  ion-item {
    --min-height: 38px;
    margin-bottom: 2px !important;
  }

  .checkbox-options-area {
    margin-left: 10px;
    padding-left:0px;
  }
}

.answers-area.error {
  border-left: solid 2px $color-red
}

.non-visible {
  visibility: hidden;
  position: absolute;
  z-index: -1;
}
</style>