<template>
  <ion-page>
    <ion-content :fullscreen="true" scrollX="true" scrollY="true">
      <Header></Header>
      <div class="template">
        <TestTitle :display-price="state.show_price"></TestTitle>
        <ion-grid v-if="state.price !== null" :class="{'margin-bottom-42': state.price !== null &&(
            state.price.payment_introduction!==null && state.price.payment_introduction!==''
             || state.price.company_info!==null && state.price.company_info!=''
            )}">
          <ion-row class="template__description" v-if="state.price !== null">
            <ion-col class="ion-no-padding" style="white-space: pre-wrap;">
              <html-content :content="state.price.payment_introduction"></html-content>
            </ion-col>
          </ion-row>
          <ion-row class="template__description company-info" v-if="state.price !== null
          && state.price.company_info !== null && state.price.company_info !== ''"
                   style="white-space: pre-wrap;line-height:0.8; padding-top:29px">
            <html-content :content="state.price.company_info"></html-content>
          </ion-row>
        </ion-grid>
        <ion-grid>
          <ion-row>
            <ion-card class="authentication__card">
              <ion-card-content class="ion-no-padding authentication__content">

                <StartPanel v-if="state.panel == PANEL_START" v-on:email-used="emailUsed"
                            :key="PANEL_START"></StartPanel>
                <RegisterPanel v-else-if="state.panel == PANEL_REGISTER" v-on:start-panel="setStartPanel"
                               :key="PANEL_REGISTER"></RegisterPanel>
                <ResumePanel v-else-if="state.panel == PANEL_RESUME" v-on:start-panel="setStartPanel"
                             :key="PANEL_RESUME"></ResumePanel>

              </ion-card-content>
            </ion-card>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {defineComponent, reactive} from 'vue';
import RegisterPanel from "@/components/login/RegisterPanel";
import ResumePanel from "@/components/login/ResumePanel";
import StartPanel from "@/components/login/StartPanel"
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow
} from "@ionic/vue";
import Header from "../components/html/Header";
import store from "@/store";
import HtmlContent from "../components/html/HtmlContent";
import TestTitle from "../components/html/TestTitle";

export default defineComponent({
  name: 'Login',
  components: {
    HtmlContent,
    StartPanel,
    ResumePanel,
    RegisterPanel,
    IonRow,
    IonContent,
    IonGrid,
    IonCardContent,
    IonCol,
    IonButton,
    IonCard,
    IonCardHeader,
    Header,
    IonPage,
    TestTitle,

  },
  setup(props, ctx) {
    const PANEL_START = 'start';
    const PANEL_REGISTER = 'register';
    const PANEL_RESUME = 'resume';

    const state = reactive({
      header_background_class: 'background-gray',
      price: store.state.Test.data.price,
      show_price: true,
      language_set: store.getters.languageSet,
      panel: PANEL_START,

    });


    function emailUsed(used) {
      if (used) {
        state.panel = PANEL_RESUME;
      } else {
        state.panel = PANEL_REGISTER;
      }
    }

    function setStartPanel() {
      state.panel = PANEL_START;
    }

    return {state, emailUsed, PANEL_START, PANEL_REGISTER, PANEL_RESUME, setStartPanel};
  },


});
</script>

<style scoped lang="scss">
/*@import '@/theme/classmarker_theme';*/

.background-white {
  background-color: $color-white;
}

.background-gray {
  background-color: $color-gray-01;
}

.authentication {
  &__card {
    width: 100%;
    padding: $large_space 0 $large-space;
    margin: 0;
    border-radius: $default-border-radius;
    box-shadow: none;
  }


  &__tab {
    font-size: $medium-font-size;
    font-family: $default-font-family;
    text-transform: none;
    font-weight: normal;
    height: 100%;
    padding: $medium-space $large-space $medium-space;
    margin: 0;

    --ripple-color: transparent;
    --background: transparent !important;

    &::part(native) {
      padding: 0;
      color: $color-gray-primary;
    }

    &--selected {
      font-weight: bold;
      background-color: $color-white;
    }
  }

  &__header {
    margin-bottom: $medium-space;
  }

  &__content {
    margin: 0 $large-space;
  }

  pre {
    width: 100%;
  }

}

.margin-bottom-42 {
  margin-bottom: 42px;
}

.company-info :deep(.bbcode){
  line-height: 22px;
}
</style>