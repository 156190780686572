<template>
  <ion-icon aria-hidden="true"
            :class="cssClass"
            :src="require('../../assets/icons/arrowRight.svg')"/>
</template>

<script>
import {IonIcon} from "@ionic/vue";
import {defineComponent} from 'vue';

/**
 * Default Arrow Icon Component. This make scv file to component which can apply css to the
 * icon directly
 *
 * usage: by adding custom class, this can change colour or behaviours icon
 *  <arrow-icon class="arrow-right" aria-hidden="true"></arrow-icon>
 *
 *
 */
export default defineComponent({
  name: 'ArrowIcon',
  components: {
    IonIcon,
  },
  props: {
    cssClass: {
      type: String, default: () => {
        return "";
      }
    }
  },
  setup(props, ctx) {
    return {};
  }
})
</script>

<style lang="scss" scoped>
</style>