<template>
  <ion-grid style="width:100%" >
    <ion-row>
      <ion-item lines="none" class="ion-no-margin" style="width:100%">
        <div class="input">
          <ion-label class="ion-text-wrap label default-font overflow-x-auto" v-bind:class="{'required':mustAnswer, 'error':error}">
            <html-content :content="extraInfoQuestion.question" :allow-magnifying-image="true"></html-content>
          </ion-label>
          <ion-input type="text" v-model="state.answer" @ionBlur="emitAnswers(id, state.answer)" @ionFocus="focused()"
                     :class="displayErrorClass"></ion-input>
        </div>
      </ion-item>
    </ion-row>

    <ion-row>
      <div class="form_error">{{ error }}</div>
    </ion-row>
  </ion-grid>
</template>

<script>
import {IonGrid, IonInput, IonItem, IonLabel, IonRow} from '@ionic/vue';
import {computed, reactive} from "vue";
import UpdateAnswerMessageEmitter from "./UpdateAnswerMessageEmitter";
import HtmlContent from "../html/HtmlContent";

export default {
  name: "ExtraQuestionInputBox",
  components: {
    IonInput,
    IonLabel,
    IonItem,
    IonGrid,
    IonRow,
    HtmlContent,
  },
  props: {
    extraInfoQuestion: {
      type: Object,
      default() {
        return {
          question: '',
          user_answer: null
        }
      }
    },
    id: Number,
    mustAnswer:{
      type:Boolean
    },
    error: {
      type: String,
      required: false,
      default: null,
    }
  },

  setup(props, ctx) {
    const state = reactive({
      answer: props.extraInfoQuestion.user_answer
    });

    const {emitAnswers} = UpdateAnswerMessageEmitter(ctx);

    const displayErrorClass = computed(() => {
      if (props.error != null && props.error.trim() != '') {
        return 'error';
      }
    });

    function focused() {
      ctx.emit('input_focused')
    }

    return {
      state, emitAnswers, displayErrorClass, focused
    };
  },
};
</script>

<style lang="scss" scoped>

.ion-no-margin {
  margin-top: 0px !important;
}
/**
Ensure items in ion-label's are clickable
 */
ion-label{
  pointer-events: all!important;
}

.label{
  max-width: 100%;
}
</style>