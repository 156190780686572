<template>

  <ion-card class="card" v-bind:class="{'slide-down':state.open, 'slide-up': !state.open}" data-cy="result-category-stat-card">
    <ion-card-header class="card__header ion-no-padding" @click="state.open=!state.open">
      <ion-card-title class="card__header__title" :class="{active:state.open}">
        {{ state.language_set.cat_stats_title }}
        <rotatable-arrow-down :rotate="!state.open" :shadow-box="false"
                              style="position:relative; bottom:-2px;float:right"></rotatable-arrow-down>

      </ion-card-title>
    </ion-card-header>
    <!--    <transition name="slide-down">-->
    <ion-card-content class="card__content ion-no-padding">
      <IonList>
        <IonListHeader class="ion-no-padding border--bottom--gray">
          <div class="card__content__category" v-bind:class="{'card__content__category--no-points':!displayPoints}">
            <strong>{{ state.language_set.category }}</strong>
          </div>
          <div class="card__content__percentage"><strong>{{ state.language_set.r_percentage }}</strong></div>
          <div class="card__content__points" v-if="displayPoints"><strong>{{ state.language_set.points }}</strong></div>

        </IonListHeader>
        <div>
          <IonItem v-for="category_stat in categoryStatistics" :key="category_stat.id" class="category-state-item" data-cy="result-category-stat-item">
            <div class="card__content__category" v-bind:class="{'card__content__category--no-points':!displayPoints}">
              {{ category_stat.category_name }}
            </div>
            <div class="card__content__percentage">
              {{ category_stat.percent }} %
            </div>
            <div class="card__content__points" v-if="displayPoints">
              {{ category_stat.point_scored }} / {{ category_stat.point_available }}
            </div>
          </IonItem>
        </div>
      </IonList>
    </ion-card-content>
    <!--    </transition>-->
  </ion-card>

</template>

<script>
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonItem,
  IonList,
  IonListHeader
} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref, watch} from 'vue';
import store from "@/store";
import RotatableArrowDown from "../icons/RotatableArrowDown";

export default defineComponent({
  name: 'CategoryStatsCard',
  components: {
    IonCard,
    IonCardHeader,
    IonIcon,
    IonCardContent,
    IonCardTitle,
    IonList,
    IonListHeader,
    IonItem,
    IonButton,
    RotatableArrowDown
  },
  props: {
    categoryStatistics: Array,
    displayPoints: Boolean
  },
  setup(props, ctx) {
    const state = reactive({
      open: true,
      language_set: store.getters.languageSet,
      initial_card_height: null
    });




    return {state};
  },


});
</script>

<style scoped lang="scss">
@import '../../theme/classmarker_theme';

.card {
  margin: 24px 0px 0px 0px;
  padding: 23px 0px 0px 0px;
  box-shadow: none;
  //height: 65px;


  &__header {
    height: 41px;
    background-color: $color-white;
    display: grid;
    grid-template-columns: 1fr max-content;
    cursor: pointer;


    & .active {
      border-bottom: 1px solid $color-gray-02;
    }

    &__title {
      color: $color-gray-primary !important;
      padding-left: 32px;
      padding-right: 32px;
      font-family: $default-font-family;
      font-size: $default-font-size;
      font-weight: bold;
    }
  }

  &__content {
    padding-left: 32px;
    padding-right: 32px;
    font-family: Roboto, "Helvetica Neue", sans-serif;

    &__category {
      width: 55%;
      @include responsive(size-small) {
        width: 100%
      }

      &--no-points {
        width: 75%;
      }
    }

    &__percentage {
      width: 25%;
      @include responsive(size-small) {
        width: 100%
      }
    }

    &__points {
      width: 20%;
      @include responsive(size-small) {
        width: 100%
      }
    }

    ion-list-header {
      font-size: 14px;
    }

    ion-item {
      font-size: 14px;

      &:last-of-type {
        --border-color: transparent;
      }
    }
  }
}

.slide-up {
  transform-origin: top center;
  max-height:65px;
  transition: max-height .3s ease;
}


.slide-down {
  max-height: 10000px;
  transform-origin: top center;
  transition: max-height 1.5s ease;
}

</style>