<template>
  <ion-list class="question-content-area ion-no-padding" lines="none" style="padding-top: 0;">
    <ion-list-header class="check-list-header ion-no-padding">
      <div class="input">
        <ion-label class="ion-text-wrap question-text">
          <html-content :content="question.question" :allow-magnifying-image="true"/>
        </ion-label>
      </div>
    </ion-list-header>

    <ion-item class="ion-no-margin answer-area">
      <ion-grid>
        <ion-row>
          <!-- TODO: convertLineBreak() -->
          <html-content :content="userAnswer"></html-content>

        </ion-row>
        <ion-row class="counter-area">
          {{ state.language_set.words }}: &nbsp;&nbsp;<strong>{{ countWord(userAnswer) }}</strong>
        </ion-row>
      </ion-grid>
    </ion-item>
  </ion-list>

  <banner v-if="feedback !== null && feedback.grade_required" id="info-banner" :message="state.language_set.essay_not_marked_yet_for_student"
          banner-type="warning"></banner>
</template>

<script>
import {IonGrid, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonRow, IonText, IonTextarea} from '@ionic/vue';
import {defineComponent, reactive} from 'vue';
import stringUtils from '../../../utils/stringUtils';
import HtmlContent from "../../html/HtmlContent";
import Banner from "../../html/Banner";
import store from "@/store";

export default defineComponent({
  name: 'TextArea',
  components: {
    IonTextarea,
    IonItem,
    IonLabel,
    IonListHeader,
    IonList,
    IonIcon,
    IonRow,
    IonGrid,
    HtmlContent,
    IonText,
    Banner

  },
  props: {
    question: Object,
    feedback: Object|null,
    userAnswer: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  setup(props, ctx) {
    const state = reactive({
      language_set: store.getters.languageSet
    });

    const {countWord} = stringUtils();


    return {state, countWord};
  },


})
;
</script>

<style lang="scss" scoped>
@import '../../../theme/classmarker_theme';
ion-grid{
  width:100%;
}
.textarea {
  margin-top: 0px;
}

.answer-area {
  margin-top: $between-space;
  margin-left: $medium-space;
  margin-right: $medium-space;

  line-height: 22px;
  font-size: $default-font-size;
  color: $text-color-primary;


}

.question-text {
  padding-bottom: 20px;
  border-bottom: 1px solid #E8E8E8;
}

.counter-area {
  align-items: center;
  margin-top: $between-space;
  letter-spacing: $letter-spacing;
  font-size: 11px;

}

.word-count {
  padding-top: $between-space;
}

</style>