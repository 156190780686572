export default function iframeMessageUtils() {

    const deleteIframeCookie = ((quiz_id) => {
        window.top.postMessage({
            'quiz_id': quiz_id,
            'action': 'delete_cookie'
        }, '*')
        return true;
    });

    const increaseAttemptsIframeCookie = ((quiz_id) => {
        window.top.postMessage({
            'quiz_id': quiz_id,
            'action': 'increase_attempts',
        }, '*')
        return true;
    });

    return {deleteIframeCookie, increaseAttemptsIframeCookie}
}



