<template>
  <ion-button class="icon-button-lg no-shadow" fill="clear" :disabled="disabled">
    <ion-icon class="icon receipt-icon"></ion-icon>
  </ion-button>
</template>

<script>
import {IonButton, IonIcon} from "@ionic/vue";
import {defineComponent, watch} from 'vue';

/**
 * Arrow Down Icon with rotation animation.
 * When rotate = true, it rotate to 180 down, false, rotate to 180 up
 * If the icon's colour need to change by font color, check component/question/TextArea.vue file.
 */
export default defineComponent({
  name: 'ReceiptButton',
  components: {
    IonIcon,
    IonButton
  },
  props: {
    disabled: Boolean,
  },

  setup(props, ctx) {
    return {};
  },


});
</script>

<style lang="scss" scoped>
/*@import '@/theme/classmarker_theme';*/

.icon-button-lg{
  border-radius: 100px;
  background: $color-blue-02;
  --background-hover: $color-blue-02;

  & .icon {
    background-size: 19.5px;
    width: 19.5px;
    height: 24px;
  }

  &:hover {
    border-radius: 100px !important;
    background: $color-blue-02 !important;

  }
}





</style>