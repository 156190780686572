export const LanguageModule = {
    namespaced: true,

    state: {
        data: {},
        reverse_direction:false
    },

    // Mutations are functions that effect the state. Never called by developer, it is called by actions function
    mutations: {
        SET_LANGUAGE(state, language) {
            state.data = language;

            if(language['code']==='ar'){
                state.reverse_direction = true;
            }
        },
    },


    // Actions are functions that you call through your application that call mutations.
    actions: {
        /**
         * Setup error message and type and display Global Error Modal
         * @param commit
         * @param error =>{type: String, message: String}
         */
        setLanguage({commit}, language) {
            commit('SET_LANGUAGE', language);
        },
    },

    // Modulising stores - spreading stores into different files
    modules: {}
}