<template>

  <span id="back-to-student-ui-button-area" v-if="displayBackButton">
    <form ref="form_ref" method="POST" @submit.prevent="submit">
      <ion-button fill="clear" type="submit">{{state.language_set.title_bar}}  <arrow-icon css-class="arrow-right" data-cy="dashboard-btn"></arrow-icon> </ion-button>
    </form>
  </span>
</template>

<script>
import {IonButton, IonCol, IonIcon, IonRow} from '@ionic/vue';
import {computed, defineComponent, getCurrentInstance, reactive, ref} from 'vue';
import HtmlContent from "./HtmlContent";
import constant from "../../constant";
import store from "@/store";
import authHandler from "../../router/authHandler";
import ArrowIcon from "../icons/ArrowIcon";


export default defineComponent({
  name: 'BackToStudentUiButton',
  components: {
    ArrowIcon,
    IonIcon,
    IonCol,
    IonRow,
    HtmlContent,
    IonButton,
  },
  props: {},
  setup(props, ctx) {
    const form_ref = ref([]);
    const state = reactive({
      language_set: store.getters.languageSet,
    });
    const access_token = store.getters.accessToken;
    const return_url = constant.STUDENT_GO_BACK_URL;

    // get current instance just like this
    const internalInstance = getCurrentInstance();
    let globalEmitter = internalInstance.appContext.config.globalProperties.$globalEmitter


    let displayBackButton = computed(() => {
      if (store.getters.groupType == constant.GROUP_TYPE_GROUP && store.getters.previewMode != true) {
        return true;
      }
      return false;
    })


    function submit() {

      globalEmitter.emit('go_back_to_student_menu');

      setTimeout(()=>{
        // create hidden field to inject access token;
        var token_input = document.createElement("input");
        token_input.setAttribute('type', 'hidden');
        token_input.setAttribute('name', 'token');
        token_input.setAttribute('value', access_token);

        // append hidden field to form
        form_ref.value.appendChild(token_input);
        // set url
        form_ref.value.action = return_url;

        //delete cookie before redirect
        let quiz_id = store.getters.quizId;
        authHandler.deleteToken(quiz_id);

        // submit the form
        form_ref.value.submit();

      }, 300);
    };


    return {state, return_url, submit, form_ref, displayBackButton};


  },


});
</script>

<style lang="scss" scoped>
/*@import '@/theme/classmarker_theme';*/
#back-to-student-ui-button-area {
  ion-button {
    //width: 190px;
    width: fit-content;
    height: 22px;

    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;

    /* identical to box height, or 183% */

    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;

    color: #68747E;
    --background: transparent;
  }
}

.arrow-right{
  color:#68747E;
  height:8px;
}

</style>