<template>
  <ion-card class="test__card">
    <ion-card-header class="test__card--header">

      <div>
        <ion-card-title>{{ state.language_set.question }} {{ question_index }} {{ state.language_set.of }}
          {{ numOfQuestion }}
        </ion-card-title>
        <ion-card-subtitle mode="md"> {{ question.category }}</ion-card-subtitle>
      </div>
      <div class="test__card--bookmark--div" v-if="displayBookmarks">
        <ion-icon class="test__card--bookmark--icon" :class="bookmarkIconClass" slot="icon-only" aria-hidden="true"
                  :src="require('../../assets/icons/bookmark.svg')"/>
      </div>

    </ion-card-header>

    <ion-card-content class="ion-no-padding">
      <div v-if="question.sub_type === constant.QUESTION_MULTIPLE_CHOICE">
        <survey-multiple-choice :question="question" :user-answer="userAnswer.answer"
                                ></survey-multiple-choice>
      </div>

      <div v-if="question.sub_type === constant.QUESTION_MULTIPLE_RESPONSE">
        <survey-multiple-response :question="question" :user-answer="userAnswer.answer"
                                  ></survey-multiple-response>
      </div>


      <div v-if="question.sub_type === constant.QUESTION_FREE_TEXT">
        <survey-free-text :question="question" :user-answer="userAnswer.answer"></survey-free-text>
      </div>

      <div v-if="question.sub_type === constant.QUESTION_ESSAY">
        <text-area :question="question" :user-answer="userAnswer.answer ?? ''" :feedback="''"></text-area>
      </div>

    </ion-card-content>
  </ion-card>
</template>

<script>
import {IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon} from '@ionic/vue';
import {computed, defineComponent, reactive} from 'vue';
import UserAnswer from "../../store/model/userAnswer";
import constant from "../../constant";

import store from "@/store";
import TextArea from "@/components/test/feedbacks/TextArea.vue";
import SurveyFreeText from "@/components/test/feedbacks/SurveyFreeText.vue";
import SurveyMultipleResponse from "@/components/test/feedbacks/SurveyMultipleResponse.vue";
import SurveyMultipleChoice from "@/components/test/feedbacks/SurveyMultipleChoice.vue";

export default defineComponent({
  name: 'QuestionFeedback',
  components: {
    SurveyMultipleChoice,
    SurveyMultipleResponse,
    SurveyFreeText,
    TextArea,
    IonCard,
    IonCardHeader,
    IonButton,
    IonIcon,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle
  },
  props: {
    question: Object,
    question_index: Number,
    numOfQuestion: Number,
    displayBookmarks: Boolean,
    userAnswer: {
      type: UserAnswer
    },
    feedback: {
      type: Object,

    }
  },


  setup(props, ctx) {
    const state = reactive({
      language_set: store.getters.languageSet,
    });


    const bookmarkIconClass = computed(() => {
      if (props.feedback.flagged) {
        return 'flagged';
      }
      if (props.feedback.has_bookmarked) {
        // below line modified to remove this functionality, simply change back to 'has-bookmarked' to re-enable
        return 'flagged'; //has-bookmarked';
      }
    })

    return {state, bookmarkIconClass, constant};
  },


});
</script>

<style scoped lang="scss">
@import '../../theme/classmarker_theme';

#point-area {
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  box-sizing: border-box;
  border-radius: 5px;
  min-width: 75px;
  height: 56px;
  text-align: center;
  position: relative;
  bottom: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: $small-font-size;
  line-height: 22px;
  color: $color-gray-secondary;

  & #points {
    color: $color-gray-primary-2;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
  }
}

#feedback-area {
  margin-top: $medium-space;
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $medium-space;
}

.test {

  &__card {
    margin: 0px;

    &--header {
      background-color: $color-gray-01;
      height: 72px;
      display: grid;
      grid-template-columns: 1fr max-content max-content;
      padding-left: 24px;

      font-family: $default-font-family;

      & ion-card-title {
        font-size: $medium-2-font-size;
        font-weight: bold;
        color: $text-color-primary;
      }

      & ion-card-subtitle {
        font-size: $default-font-size;
        color: $text-color-secondary;
        line-height: 22px;
        letter-spacing: 0.02em;
      }

      .test__card--bookmark--div {
        ion-icon.test__card--bookmark--icon {
          font-size: 20px;
          margin: 0 10px;
          fill: #FFF;
          display: none;

          &.flagged {
            display: inline-block;
            stroke: #FFC700;
            fill: #FFC700;
          }

          &.has-bookmarked {
            display: inline-block;
            fill: #FFFFFF;
            stroke: #BEC8D1;
          }
        }
      }
    }
  }
}

/**
Common CSS components for child components
 */
:deep(.question-text) {
  margin-left: $medium-space;
  margin-right: $medium-space;
  margin-top: $medium-space;
  //font-family: 'Arial';
  line-height: 22px;
  //font-size: $default-font-size;
  color: $text-color-primary;
  display: block;
  width: calc(100% - #{$medium-space} * 2);
}

:deep(.question-option-list) {
  margin-left: $medium-space;
  margin-right: $medium-space;
}

:deep(.question-option) {
  //display: grid;
  width: calc(100% - 49px);
  display: inline-block;
  grid-template-columns: max-content 1fr;
  column-gap: $small-space;
}


:deep(.question-content-area) {
  margin-bottom: $medium-space;
  font-family: $default-font-family;
  align-self: flex-start;
  justify-self: center;
  font-size: $default-font-size
}

:deep(.question-index) {
  font-weight: bold;
  width: 20px;
  position: relative;
  float: left;
}

:deep(#info-banner) {
  width: 92%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 16px;
}


:deep(.icon-correct) {
  position: absolute;
  background-size: 20px;
  width: 20px;
  height: 20px;
  top: 11px;
  right: 12px;

  &.rtl {
    right: calc(100% - 32px);
  }
}


:deep(ion-list-header .question-text) {
  font-weight: initial;
  font-size: 14px;
}
</style>