<template mode="ios">

  <ion-list :id="'matching-question-'+question.id" class="radio-list question-content-area" style="padding-top: 0;">

    <ion-item id="question_area" class="question-text">
      <ion-label class="ion-no-margin question-text-label ion-text-wrap">
        <HtmlContent :content="question.question"></HtmlContent>
      </ion-label>
    </ion-item>

    <ion-grid>
      <ion-item v-for="clue in question.options.clues" :key="clue.id">
        <ion-row class="match-clue-list-item">
          <ion-col size-xs="5" size-sm="4">
            <ion-label class="ion-text-wrap overflow-x-auto">
              <html-content :content="clue.content" :allow-magnifying-image="true"></html-content>
            </ion-label>
          </ion-col>

          <ion-col size-xs="7" size-sm="4" class="match-column">
            <ion-label class="ion-text-wrap">
              <div v-if="clue.id in userAnswer && getMatchContent(userAnswer[clue.id])!==''" class="user-answer-area">

                <html-content class="user-answer-content overflow-x-auto" :content="getMatchContent(userAnswer[clue.id])"
                              :allow-magnifying-image="true"/>

                <ion-icon class="icon icon-correct " :class="getCorrectIconClassName(clue.id, userAnswer[clue.id])"
                          :aria-label="$getCorrectAriaText(getCorrectIconClassName(clue.id, userAnswer[clue.id]))"
                          :aria-hidden="$getCorrectAriaText(getCorrectIconClassName(clue.id, userAnswer[clue.id]))=='' ? true : false"
                />
              </div>
              <div v-else class="no-answer-column">
                <div class="no-answer-area">
                  {{ state.language_set.no_answer_given }}
                </div>
                <ion-icon class="icon icon-correct " :class="getCorrectIconClassName(clue.id, userAnswer[clue.id])"
                          :aria-label="$getCorrectAriaText(getCorrectIconClassName(clue.id, userAnswer[clue.id]))"
                          :aria-hidden="$getCorrectAriaText(getCorrectIconClassName(clue.id, userAnswer[clue.id]))=='' ? true : false"
                />
              </div>
            </ion-label>
          </ion-col>

          <ion-col size-xs="6" size-sm="4" class="correct-match-column">
            <div
                v-if="feedback.correct_answer !==null && correctAnswerLength >0
                && (!isAnswered(clue.id) || !isCorrect(clue.id, userAnswer[clue.id]))
                && getMatchContent(feedback.correct_answer[clue.id])">
              <ion-label class="ion-text-wrap">
                <strong style="font-size:11px">{{ state.language_set.quiz_correct_answer }}:</strong>
                <br/>
                <html-content class="user-answer-content overflow-x-auto" :content="getMatchContent(feedback.correct_answer[clue.id])"
                              :allow-magnifying-image="true"/>
              </ion-label>
            </div>
          </ion-col>


        </ion-row>
      </ion-item>
    </ion-grid>
  </ion-list>

</template>

<script>
import {IonButton, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonPopover, IonRow, IonText} from '@ionic/vue';
import {computed, defineComponent, reactive} from 'vue';
import HtmlContent from "../../html/HtmlContent";
import store from "@/store";

export default defineComponent({
  name: 'MatchingMedia',
  components: {
    HtmlContent,
    IonList,
    IonItem,
    IonButton,
    IonText,
    IonLabel,
    IonPopover,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,

  },
  props: {
    question: Object,
    userAnswer: Object,
    feedback: Object,
  },

  setup(props, ctx) {
    const state = reactive({
      language_set: store.getters.languageSet,
    });

    /**
     * Set user selected contents for display contents in UI
     * @param clue_id
     * @private
     */

    function getMatchContent(match_id) {
      let result = '';
      props.question.options.matches.forEach((match) => {
        if (match.id == match_id) {
          result = match.content;
        }
      });
      return result;
    }

    function isCorrect(clue_id, answer) {
      if (props.feedback.correct_answer != null
          && clue_id in props.feedback.correct_answer
          && props.feedback.correct_answer[clue_id] === answer) {
        return true;
      }
      return false;
    }

    function isAnswered(clue_id) {
      if (clue_id in props.userAnswer) {
        return true;
      }
      return false;
    }

    function getCorrectIconClassName(clue_id, answer) {
      let is_answered = isAnswered(clue_id);
      let is_correct = isCorrect(clue_id, answer);
      if (props.feedback.correct_answer == null) {
        return '';
      }

      let icon = '';

      if (is_answered && is_correct) {//selected
        icon = 'circular-tick';
      } else {
        icon = 'circular-x';
      }
      let reverse_text = store.getters.reverseText;
      if (reverse_text) {
        icon += ' rtl';
      }
      return icon;
    }

    function getCorrectAnswer(clue_id) {
      let match_id = props.feedback.correct_answer[clue_id];
      return getMatchContent(match_id);
    }

    const correctAnswerLength = computed(() => {
      let length = 0;
      if (props.feedback != null) {
        length = Object.keys(props.feedback.correct_answer).length;
      }
      return length;
    })

    return {
      state,
      getMatchContent,
      isCorrect,
      isAnswered,
      getCorrectIconClassName,

      correctAnswerLength
    };
  },
});
</script>

<style lang="scss" scoped>
@import '../../../theme/classmarker_theme';

.question-text-label {
  margin-bottom: $medium-space;
}

.match-clue-list-item {
  width: 100%;
  min-height: 188px;

  padding-left: $medium-space;
  padding-right: $medium-space;
  padding-top: $medium-space;
  padding-bottom: $medium-space;

  line-height: 22px;
  letter-spacing: 0.02em;

  /* Text primary */
  font-family: $default-font-family;
  font-size: $default-font-size;
  color: $text-color-primary;

  ion-col {
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;

    @include responsive(size-small) {
      padding: 0px;
    }

  }

  ion-col:first-of-type {
    padding-left: 0px;
    @include responsive(size-small) {
      padding-right: 20px;
    }
  }

  ion-col:last-of-type {
    padding-right: 0px;

    @include responsive(size-small) {
      margin-top: 30px;
      padding-left: 0px;
    }
  }

}

.user-answer-area {
  position: relative;
  min-height: 188px;
  background: $color-gray-01;
  padding: 7px;

  .user-answer-content {
    width: 70%;

    @include responsive(size-small) {
      width: 70%;
    }
  }
}

ion-icon {
  top: 0px !important;
}

.no-answer-area {
  width: 80%;
}

.icon-correct {
  right: 8px !important;
}

.icon-correct.rtl {
  right: calc(100% - 27px) !important;
}
</style>