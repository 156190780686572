<template>
  <div style="margin:20px auto 40px auto; width:468px">

    <ins v-if="dev_mode_node" style="display:block; width:468px; height:60px; border:solid 1px #E9ECEF">
      Google ads here
    </ins>

    <ins v-else class="adsbygoogle"
         style="display:block; width:200px; height:60px;"
         :data-ad-client="google_ad_client"
         :data-ad-slot="googleAdSlot"
         :data-adtest="googleAdTest"
         width="200"
         height="60"
    >
    </ins>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import store from '@/store';

export default defineComponent({
  name: 'GoogleAds',
  components: {},
  props: {
    googleAdSlot: {
      required: true
    },
  },
  mounted: () => {

  },
  beforeMount: () => {
    function mountGoogleScript() {
      if (store.state.google_ads_script_mount == false) {

        // mount google ads Script
        const google_script_src = '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
        let script = document.createElement('script');
        script.setAttribute('src', google_script_src);
        document.body.appendChild(script);
        script.onload = () => {

          //Store google ads object into vuex storage.
          let adsbygoogle = window.adsbygoogle || [];
          // set google ads script is mounted to the VueJs
          store.dispatch('setGoogleAdsScriptMounted');
          store.dispatch('setGoogleAdsObject', {google_ads_obj: adsbygoogle})
        }

      }
    }

    mountGoogleScript();
  },
  setup(props, ctx) {
    let google_ad_client = process.env.VUE_APP_GOOGLE_ADS_CLIENT_ID;
    let ad_test = process.env.VUE_APP_GOOGLE_ADS_TEST;

    let dev_mode_node = false;
    let googleAdTest = 'off';

    if (process.env.VUE_APP_MODE == 'development_node') {
      dev_mode_node = true;
    }
    if (process.env.VUE_APP_MODE == 'development') {
      googleAdTest = "on"
    }

    if (!dev_mode_node) {
      let interval_id = setInterval(() => {
        //ensure google ads is loaded properly
        if (store.state.google_ads_script_mount) {

          // request advertisement to google
          (store.state.google_ads_object).push({});
          clearInterval(interval_id);
        }
      }, 50)
    }

    return {google_ad_client, ad_test, dev_mode_node, googleAdTest}
  },


});
</script>

<style lang="scss" scoped>
</style>