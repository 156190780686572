<template>
  <ion-list class="question-content-area ion-no-padding" lines="none" style="padding-top: 0;">
    <ion-list-header class="check-list-header ion-no-padding">
      <div class="input">
        <ion-label class="ion-text-wrap question-text">
          <html-content :content="question.question" :allow-magnifying-image="true"/>
        </ion-label>
      </div>
    </ion-list-header>

    <ion-item class="answer-area">
      <div class="user-answer">
        <strong>{{ state.language_set.answer_given }}:</strong> <br/>
        <div id="user-answer-text-area">
          <text id="user-answer-text">{{ userAnswer }}</text>
          <ion-icon class="icon icon-correct" v-bind:class="getCorrectIcon()"
                    :aria-label="$getCorrectAriaText(getCorrectIcon())"
                    :aria-hidden="$getCorrectAriaText(getCorrectIcon())=='' ? true : false"
          ></ion-icon>
        </div>
      </div>
    </ion-item>

    <ion-item class="answer-area" v-if="feedback.full_mark != true && feedback.correct_answer">
      <div class="user-answer">
        <strong>{{ state.language_set.quiz_correct_answer }}:</strong> <br/>
        {{ feedback.correct_answer }}
      </div>
    </ion-item>
  </ion-list>
</template>

<script>
import {IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader} from '@ionic/vue';
import {defineComponent, reactive} from 'vue';
import HtmlContent from "../../html/HtmlContent";
import store from "@/store";

export default defineComponent({
  name: 'Grammar',
  components: {
    IonInput,
    IonItem,
    IonLabel,
    IonListHeader,
    IonList,
    HtmlContent,
    IonIcon
  },
  props: {
    question: Object,
    userAnswer: String,
    feedback: Object,
  },
  setup(props, ctx) {
    const state = reactive({
      register: true,
      user_answer: props.userAnswer,
      language_set: store.getters.languageSet
    });


    function getCorrectIcon() {
      let icon = '';
      if (props.feedback.full_mark == true) {
        icon = 'circular-tick'
      } else {
        icon = 'circular-x';
      }
      let reverse_text = store.getters.reverseText;
      if(reverse_text){
        icon += ' rtl';
      }
      return icon;
    }

    return {state, getCorrectIcon};
  },
});
</script>

<style lang="scss" scoped>
@import '../../../theme/classmarker_theme';

.answer-area {
  margin-top: $between-space;
  margin-bottom: $between-space;
  margin-left: $medium-space;

  margin-right: 4%;
  min-height: $input-feedback-height;


}

#user-answer-text-area {
  background: $color-gray-01;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
  padding-right: 18px;
  margin-top:2px;
  position: relative;
  border-radius:5px;
  min-height:$input-feedback-height;
}

#user-answer-text {
  width: 90%;
  display: inline-block;
}

.user-answer {
  width: 100%;
}

.question-text {
  padding-bottom: 20px;
  border-bottom: 1px solid $border-color-gray;
}

.icon-correct{
  right:19px;
}


</style>