<template>
  <ion-page>
    <ion-content :fullscreen="true">
<!--      <Header></Header>-->

      <div class="template">
        <test-title :display-price="false"></test-title>
        <transition name="fade">
          <ion-card v-if="state.display">
            <GlobalErrorModal button-action="button_clicked" :button-message="refreshButtonMessage"
                              v-on:button_clicked="refreshBrowser()" :bbcode="false"></GlobalErrorModal>
          </ion-card>
        </transition>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonCard, IonCardContent, IonContent, IonPage} from '@ionic/vue';
import {computed, defineComponent, getCurrentInstance, reactive} from 'vue';
import GlobalErrorModal from "@/components/html/GlobalErrorModal";
import Header from "@/components/html/Header";
import TestTitle from "@/components/html/TestTitle";
import testClient from "../../api/testClient";
import store from "@/store";
import constant from "../../constant";


export default defineComponent({
  name: 'OfflineMode',
  components: {
    GlobalErrorModal,
    IonCardContent,
    IonCard,
    IonPage,
    IonContent,
    Header,
    TestTitle,
  },
  beforeMount() {
  },
  mounted() {
    /**
     * Remove before unload event. The APP.vue file is loaded later than this page,
     * unloadEvent setup should be delayed until App.vue file is loaded
     */
    setTimeout(() => {
      window.removeEventListener('beforeunload', this.$popupUnLoadEvent);
    }, 10);
  },
  setup(props, ctx) {

    // get current instance just like this
    const internalInstance = getCurrentInstance();
    let globalEmitter = internalInstance.appContext.config.globalProperties.$globalEmitter


    let quiz_id = new URL(location.href).searchParams.get('quiz') ?? '';
    const state = reactive({
      display: true
    });

    const current_state = computed(() => {
      return store.getters.testState;
    });

    const offline_state = computed(() => {
      return store.getters.offlineStatus;
    });

    const refreshButtonMessage = computed(() => {
      if (offline_state.value == constant.STATUS_OFF_LINE) {
        return 'Refresh'
      } else {
        return 'Resume'
      }
    });


    function refreshBrowser() {
      // hide offline message
      state.display = false;


      //when status is offline => check online status
      if (offline_state.value == constant.STATUS_OFF_LINE) {
        checkOnlineStatus();
        // when status == back online => set offline resume test
      } else if (offline_state.value == constant.STATUS_BACK_ONLINE || offline_state.value == constant.STATUS_OFFLINE_RESUME_TEST) {
        if (current_state.value == constant.STATUS_TEST_IN_PROGRESS) {
          // display loading spinner
          store.dispatch('setOfflineStatus', constant.STATUS_OFFLINE_RESUME_TEST);
          globalEmitter.emit('resume-test-from-offline');
          setTimeout(() => {
            state.display = true;
          }, 1000)
        } else {
          window.location.reload();
        }
      }
      // show offline message


    }

    function checkOnlineStatus() {
      testClient.getOnlineStatus().then((response) => {
        let error = {
          type: 'error',
          error_code: constant.ERROR_OFF_LINE_MODE,
          message: null
        }
        let data = response.data.data.online_status;
        if (data.online_status == true) {
          error.type = 'info';
          error.message = 'ClassMarker is back online now.'
          if (offline_state.value == constant.STATUS_OFF_LINE) {
            store.dispatch('setOfflineStatus', constant.STATUS_BACK_ONLINE);
            store.dispatch('Error/setMessage', {error: error});
          }
        } else {
          error.message = data.offline_message;
          store.dispatch('Error/setMessage', {error: error});
        }
        setTimeout(() => {
          state.display = true;
        }, 1000)
      });
    }


    let interval_id = setInterval(() => {
      //ensure google ads is loaded properly
      checkOnlineStatus();
      if (offline_state.value != constant.STATUS_OFF_LINE) {
        clearInterval(interval_id);
      }
      // }, 60000)
    }, 60000);


    return {state, quiz_id, refreshBrowser, refreshButtonMessage};
  },
});
</script>

<style scoped lang="scss">
.template {
  padding-top: 30px;
}

.icon-flag {
  width: 53px;
  height: 68.96px;
  background-size: 53px;
  position: relative;
  display: block;
  margin: auto;
  margin-top: 93px;
}

ion-card-header {
  color: $text-color-primary;
  font-size: 24px;
  //styleName: H1;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 28px;
}

ion-card-content {
  //styleName: Body / Regular;
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
  width: 62%;
  display: block;
  margin: auto;
  margin-bottom: 74px;

}
</style>