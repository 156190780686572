<template>
  <ion-page>
    <ion-content :fullscreen="true" scrollX="true" scrollY="true">
      <Header/>
      <div class="template">
        <TestTitle :display-price="true" :display-title="true"></TestTitle>
        <ion-grid>
          <ion-row class="template__description" v-if="state.price !== null">
            <ion-col class="ion-no-padding" style="white-space: pre-wrap;">
              <html-content :content="state.price.payment_introduction"></html-content>
            </ion-col>
          </ion-row>
          <ion-row class="template__description company-info" v-if="state.price !== null && state.price.company_info !== null"
                   style="white-space: pre-wrap; padding-top:29px">
            <html-content :content="state.price.company_info"></html-content>
          </ion-row>
          <Banner banner-type="alert" :display-icon="true" :message="preview_mode_message" v-if="state.preview_mode"
                  style="margin-top:20px; margin-bottom:20px;">
          </Banner>
          <ion-row>
            <div class="button-area">
              <ion-button :disabled="state.disable_button" type="submit" @click="loadPayPalPage" data-cy="continue-btn"
                          v-if="state.preview_mode == false">
                {{ state.language_set.link_continue }}
              </ion-button>
              <ion-button :disabled="state.disable_button" type="submit" @click="previewModeNextPage" data-cy="continue-btn" v-else>
                {{ state.language_set.link_continue }}
              </ion-button>
            </div>
          </ion-row>
        </ion-grid>


      </div>
      <ion-loading
          :is-open="state.isLoadingSpinnerOpen"
          message="We are checking the payment"
      >
      </ion-loading>
    </ion-content>
  </ion-page>


</template>

<script>
import {defineComponent, reactive} from 'vue';
import {IonButton, IonCol, IonContent, IonGrid, IonLoading, IonPage, IonRow} from "@ionic/vue";
import Header from "../components/html/Header";
import store from "@/store";
import HtmlContent from "../components/html/HtmlContent";
import RouteHandler from "../router/routeHandler";
import {useRouter} from "vue-router";
import constant from "../constant";
import TestTitle from "../components/html/TestTitle"
import Banner from "../components/html/Banner";
import testClient from "@/api/testClient";

export default defineComponent({
  name: 'Payment',
  components: {
    IonPage,
    IonContent,
    Header,
    IonGrid,
    IonRow,
    IonCol,
    HtmlContent,
    IonButton,
    IonLoading,
    TestTitle,
    Banner
  },
  setup(props, ctx) {
    const state = reactive({
      header_background_class: 'background-gray',
      price: store.state.Test.data.price,
      language_set: store.getters.languageSet,
      disable_button: false,
      isLoadingSpinnerOpen: false,
      preview_mode: store.getters.previewMode
    });

    const routeHandler = new RouteHandler(useRouter());

    function previewModeNextPage() {
      // get Payment status
      store.dispatch('Test/getPaymentStatus').then(() => {

        let current_state = store.getters.testState;

        state.isLoadingSpinnerOpen = false;
        state.disable_button = false;
        if (current_state === constant.STATUS_PAYMENT) {

        } else {
          routeHandler.route();
        }
      }).catch((error) => {
        state.isLoadingSpinnerOpen = false;
        state.disable_button = false;
        store.dispatch('Error/displayFromAPIError', error);
      })
    }

    async function loadPayPalPage() {
      let response = await testClient.getOnlineStatus()
      let online_status = response.data.data.online_status;

      if (online_status.online_status == true) {
        // Get payment token from store directly to apply the new token from API
        let payment_token = store.state.Test.data.price.payment_token;
        state.disable_button = true;
        let paypalURL = state.price.payment_url + '?expType=mini&paykey=' + payment_token;
        var ua = navigator.userAgent;
        var pollingInterval = 0;
        var ppwin;
        // mobile device
        if (ua.match(/iPhone|iPod|Android|Blackberry.*WebKit/i)) {
          //VERY IMPORTANT - You must use '_blank' and NOT name the window if you want it to work with chrome ios on iphone
          //See this bug report from google explaining the issue: https://code.google.com/p/chromium/issues/detail?id=136610
          ppwin = window.open(paypalURL, '_blank');
        } else {
          //Desktop device
          var width = 930,
              height = 550,
              left,
              top;

          if (window.outerWidth) {
            left = Math.round((window.outerWidth - width) / 2) + window.screenX;
            top = Math.round((window.outerHeight - height) / 2) + window.screenY;
          } else if (window.screen.width) {
            left = Math.round((window.screen.width - width) / 2);
            top = Math.round((window.screen.height - height) / 2);
          }

          //VERY IMPORTANT - You must use '_blank' and NOT name the window if you want it to work with chrome ios on iphone
          //See this bug report from google explaining the issue: https://code.google.com/p/chromium/issues/detail?id=136610
          ppwin = window.open(paypalURL, '_blank', 'top=' + top + ', left=' + left + ', width=' + width + ', height=' + height + ', location=0, status=0, toolbar=0, menubar=0, resizable=0, scrollbars=1');
        }
        setTimeout(() => {
          state.disable_button = false;
        }, 20000)

        pollingInterval = setInterval(function () {
          if (ppwin && ppwin.closed) {
            // clear timer to check polling event
            clearInterval(pollingInterval);
            state.isLoadingSpinnerOpen = true;

            // get Payment status
            store.dispatch('Test/getPaymentStatus').then(() => {

              let current_state = store.getters.testState;

              state.isLoadingSpinnerOpen = false;
              state.disable_button = false;
              if (current_state === constant.STATUS_PAYMENT) {

              } else {
                routeHandler.route();
              }
            }).catch((error) => {
              state.isLoadingSpinnerOpen = false;
              state.disable_button = false;
              store.dispatch('Error/displayFromAPIError', error);
            })
          }
        }, 1000);
      } else {
        //display error page
        let error = {
          type: 'error',
          error_code: 'offline',
          message: online_status.offline_message
        };
        store.dispatch('Error/setMessage', {'error':error});
        store.dispatch('setOfflineStatus', constant.STATUS_OFF_LINE);
      }
    }

    const preview_mode_message = "<strong>Preview Mode</strong><br/>" +
        "<br/>You are in preview mode, so you will not be sent to PayPal to make payment. Your Test Takers will continue to PayPal at this point, then be returned to start their test after a successful payment is made.";

    return {state, loadPayPalPage, preview_mode_message, previewModeNextPage};
  },


});
</script>

<style scoped lang="scss">
/*@import '@/theme/classmarker_theme';*/

.background-white {
  background-color: $color-white;
}

.background-gray {
  background-color: $color-gray-01;
}

.authentication {
  &__card {
    width: 100%;
    padding: 0 0 $large-space;
    margin: $large-space 0 0;
    border-radius: $default-border-radius;
    box-shadow: none;
  }


  &__tab {
    font-size: $medium-font-size;
    font-family: $default-font-family;
    text-transform: none;
    font-weight: normal;
    height: 100%;
    padding: $medium-space $large-space $medium-space;
    margin: 0;

    --ripple-color: transparent;
    --background: transparent !important;

    &::part(native) {
      padding: 0;
      color: $color-gray-primary;
    }

    &--selected {
      font-weight: bold;
      background-color: $color-white;
    }
  }

  &__header {
    margin-bottom: $medium-space;
  }

  &__content {
    margin: 0 $large-space;
  }

  pre {
    width: 100%;
  }
}

.company-info :deep(.bbcode){
  line-height: 22px;
}
</style>