<template>
  <ion-row class="banner" v-bind:class="bannerType">

    <ion-col class="description">
      <text id="feedback-text" >{{state.language_set.feedback}}</text>
      <html-content :content="message" data-cy="feedback-banner">

      </html-content>
    </ion-col>
  </ion-row>
</template>

<script>
import {IonCol, IonIcon, IonRow} from '@ionic/vue';
import {defineComponent, reactive} from 'vue';
import HtmlContent from "../../html/HtmlContent";
import store from "@/store";

export default defineComponent({
  name: 'FeedbackBanner',
  components: {
    IonIcon,
    IonCol,
    IonRow,
    HtmlContent
  },
  props: {
    message: {type: String, required: true}, // value: info, alert, warning
    bannerType: {type: String, required: true},
    displayIcon: {
      type: Boolean, default: () => {
        return true;
      }
    }
  },
  setup(props, ctx) {
    const state = reactive({
      language_set: store.getters.languageSet,
    });
    return {state};
  },
});
</script>

<style lang="scss" scoped>
/*@import '@/theme/classmarker_theme';*/

ion-col {
  padding-left: 0px;
  padding-right: 0px;
}

ion-row {
  padding-left: 0px !important;

}

.banner {
  padding: $small-space;
  align-items: center;
  position: relative;
  border-radius: 5px;
  background-color: $color-gray-01;
  overflow-x: auto;


  #feedback-text{
    font-weight: bold;
    font-size: $default-font-size;
  }

  &.info {
    border-left: 5px solid $color-sky-blue;
  }

  &.warning {
    border-left: 5px solid $color-yellow;
  }

  &.alert {
    border-left: 5px solid $color-red;
  }

  &.success{
    border-left: 5px solid $color-success;
  }

  & .description {
    font-family: $default-font-family;
    font-size: $default-font-size;
    color: $color-gray-primary;
    padding-left:24px;
    width:547px;
  }
}


</style>