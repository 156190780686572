import router from "../router";
import store from "@/store";
import constant from "../constant";


function setResponseData(commit, response, update_timeout_timestamp_only=false) {


    let data = response.data;
    if (data.token != null) {
        // set if token is rotated
        commit('Auth/SET_TOKEN', data.token, {root: true});
    }
    //store.dispatch('Test/setGroupType', payload.group_type);
    commit('Test/SET_TEST_STATE', {test_state: data['state'], iframe_mode: store.state.iframe_mode}, {root: true});

    if (data['settings'] != null) {
        //set page settings
        commit('Test/SET_SETTINGS', data['settings'], {root: true});
    }

    if (data['data'] != null) {
        //set page data
        if (data['data']['user']) {
            commit('User/SET_USER_INFO', data['data']['user'], {root: true});
        }
        if(update_timeout_timestamp_only){
            commit('Test/SET_EXPIRED_TIMESTAMP', data['data'], {root: true});
        }else {

            commit('Test/SET_DATA', data['data'], {root: true});
        }
    }

    if (data['language'] != null && data['language'].length != 0) {
        commit('Language/SET_LANGUAGE', data['language'], {root: true});
    }
}

function handleNoInternetError(error, display_modal = false) {
    if (error.message == constant.ERROR_NO_INTERNET) {
        let error = {
            'error_code': constant.ERROR_NO_INTERNET,
            'type': constant.ERROR_NO_INTERNET,
            'error_level': 'error',
            'message': constant.ERROR_MESSAGES[constant.ERROR_NO_INTERNET]
        }
        store.dispatch('Error/setMessage', {'error': error}, {root: true});
        if (display_modal) {
            store.dispatch('Error/openModal');
        }
        throw error;
    }
}


function handleError(error, app_variables=null) {

    // check when CloudFlare challenge status
    if(error.response.headers.hasOwnProperty('cf-mitigated') && error.response.headers['cf-mitigated'] === 'challenge'){

        //Unregister beforeunload event from the application
        if(app_variables !== null) {
            window.removeEventListener('beforeunload', app_variables.$popupUnLoadEvent);
        }

        // reload the browser
        window.location.reload();

    } else {

        let quiz_id = new URL(location.href).searchParams.get('quiz') ?? '';
        handleNoInternetError(error);

        // This error only happening when hit test/init() end point.
        if (error.response.data.hasOwnProperty('test_setting') && error.response.data.test_setting != null) {
            let data = [];
            data['test'] = error.response.data.test_setting;
            store.dispatch('Test/setTestSettingFromErrorPage', data);
        }

        // check and assign language
        if (error.response.data.language != null) {
            store.dispatch('Language/setLanguage', error.response.data.language);
        }
        // check and assign theme
        if (error.response.data.theme != null) {
            store.dispatch('Test/setTheme', error.response.data.theme);
        }
        if (error.response.status == 500) { // System error
            // set error message for Error page

            store.dispatch('Error/setMessageFromAPI', error, {root: true});
            store.dispatch('Test/setState', 'error')
            store.dispatch('Error/displayFullPageError')

            // redirect to error page
            // router.replace({name: 'Error', query: {quiz: quiz_id}});

        } else { // Other errors

            let path_name = window.location.pathname;
            switch (error.response.data.error_code) {
                case constant.ERROR_OFF_LINE_MODE:
                    store.dispatch('Error/setMessageFromAPI', error, {root: true});
                    store.dispatch('setOfflineStatus', constant.STATUS_OFF_LINE);
                    break;
                case constant.ERROR_NO_CREDIT_FREE_USER:
                case constant.ERROR_NO_CREDIT_PAID_USER:
                case constant.ERROR_AUTH_TOKEN_EXPIRED: // auth token expired
                case constant.ERROR_ACCESS_TOKEN_EXPIRED: //access token expired
                case constant.ERROR_MULTIPLE_BROWSER_DETECTED: // multiple browser detected
                case constant.ERROR_NO_CM_USER_ID:
                case constant.ERROR_IP_NOT_ALLOWED:
                case constant.ERROR_INACTIVE_ACCOUNT:
                case constant.ERROR_TEST_UNAVAILABLE:
                case constant.ERROR_NO_QUESTIONS_IN_TEST:
                case constant.ERROR_INVALID_CLIENT_REFERER:
                case constant.ERROR_INVALID_QUIZ_ID:
                case constant.ERROR_INVALID_PREVIEW_TEST:

                    // set error message for Error page
                    store.dispatch('Error/setMessageFromAPI', error, {root: true});
                    store.dispatch('Test/setState', 'error')
                    store.dispatch('Error/displayFullPageError')
                    break;
                case constant.ERROR_TEST_LIMIT_REACHED:

                    if (path_name.includes('/access-code/') === false) {
                        store.dispatch('Error/setMessageFromAPI', error, {root: true});
                        store.dispatch('Test/setState', 'error')
                        store.dispatch('Error/displayFullPageError')
                        break;
                    } else {
                        store.dispatch('Auth/setTokenFromErrorResponse', error, {root: true})
                        throw error;
                        break;
                    }
                case constant.ERROR_INVALID_ACCESS_CODE:
                case constant.ERROR_TEST_LIMIT_REACHED:
                    let field = error.response.data.field ?? null
                    if (field == 'cm_user_id') {

                        // cm_user_id is no longer available.
                        store.dispatch('Error/setMessageFromAPI', error, {root: true});
                        store.dispatch('Test/setState', constant.STATUS_ACCESS_CODE)
                        store.dispatch('Error/displayFullPageError')
                        break;
                    } else {

                        // access code is no longer available.
                        if (path_name.includes('/access-code/') === false) {
                            store.dispatch('Error/setMessageFromAPI', error, {root: true});
                            store.dispatch('Test/setState', constant.STATUS_ACCESS_CODE)
                            router.replace({name: 'AccessCode', query: {quiz: quiz_id}});
                            break;
                        } else {
                            store.dispatch('Auth/setTokenFromErrorResponse', error, {root: true})
                            throw error;
                            break;
                        }
                    }
                case constant.ERROR_TOO_MAY_SIM_USERS:
                    store.dispatch('Error/setMessageFromAPI', error, {root: true});
                    store.dispatch('setOfflineStatus', constant.STATUS_ONLINE_WAITING_SIM_USER_SLOT);
                    store.dispatch('setReachSimUserLimit', true);
                    break;
                default:
                    store.dispatch('Auth/setTokenFromErrorResponse', error, {root: true})
                    throw error;
            }
        }
    }
}

export default {setResponseData, handleError, handleNoInternetError};