<template>
  <div class="modal ion-no-padding" v-bind:class="setupAppDefaultCssClasses()">
    <ion-row class="ion-no-padding">
      <ion-col class="modal__message" :class="errorClass">
        <ion-row class="modal__message__icon">
          <div class="icon" :class="iconClass" :style="iconStyleObject"/>
        </ion-row>
        <ion-row class="modal__message__text">
          <div style="width:80%">
            <html-content :content="error_message" text-align="center" :bbcode="bbcode">
            </html-content>
            <slot name="extra"></slot>
          </div>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row class="modal__buttons" :class="{'no-button-background': hideButton}">
      <slot name="button-text"></slot>
      <ion-button @click="buttonClicked" v-if="!hideButton" data-cy="modal-close-button" :disabled="disableButton">{{
          buttonMessage
        }}
      </ion-button>
    </ion-row>
  </div>
</template>

<script>
import {IonButton, IonCol, IonIcon, IonRow} from '@ionic/vue';
import {defineComponent, reactive} from 'vue';
import HtmlContent from "./HtmlContent";
import store from "@/store";
import {computed} from "@vue/reactivity";
import htmlSetupUtil from "../../utils/htmlSetupUtil";
import constant from "../../constant";

export default defineComponent({
  name: 'GlobalErrorModal',
  components: {
    IonIcon,
    IonCol,
    IonRow,
    HtmlContent,
    IonButton,
  },
  props: {
    hideButton: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    disableButton:{
      type: Boolean,
      default:()=>{
        return false;
      }
    },
    buttonMessage: {
      type: String,
      default: () => {
        let language_set = store.getters.languageSet;
        return language_set['ok'] ?? 'OK';
      }
    },
    buttonAction: {
      type: String,
      default: () => {
        return 'close';
      }
    },
    quizId: {
      type: [String || null],
      default: () => {
        return null;
      }
    },
    bbcode: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  mounted() {
    // when route going to error page, this will not be constructed
    let elem = document.querySelector('.global-error-modal');
    if (elem != null) {
      elem.addEventListener('keypress', this.enterKeyHandler);
    }
  },
  methods: {
    enterKeyHandler: function (event) {
      if (event.key === 'Enter') {
        store.dispatch('Error/close');
      }
    }
  },
  setup(props, ctx) {

    const iconStyleObject = reactive({})

    const error_message = computed(() => {
      return store.getters.errorMessage;
    })

    const error_type = computed(() => {
      return store.getters.errorType;
    })

    function close() {
      store.dispatch('Error/close')
    }

    function buttonClicked() {

      switch (props.buttonAction) {
        case 'close':
          close();
          break;

        case 'login_redirect':
          let redirect_url = store.state.restart_url;
          window.location.href = redirect_url;
          break;

        case 'button_clicked':
          ctx.emit('button_clicked');
          break;
      }
    }

    const iconClass = computed(() => {
      if (error_type.value === 'info') {
        return 'info--unfilled';
      } else if (error_type.value === constant.ERROR_NO_INTERNET) {
        return 'no-internet-icon';
      } else {
        return 'warning--unfilled';
      }
    })

    const errorClass = computed(() => {
      if (error_type.value === 'info') {
        return 'info';
      } else {
        return 'warning';
      }
    })


    const {setupAppDefaultCssClasses} = htmlSetupUtil();

    return {buttonClicked, iconClass, setupAppDefaultCssClasses, error_message, errorClass, iconStyleObject};
  },


});
</script>

<style lang="scss" scoped>

.modal {
  align-items: center;
  position: relative;


  &__message {
    background-color: #FFFDF2;

    &.info {
      background-color: $color-sky-blue-bg !important;

    }

    &.warning {
      background-color: $color-yellow-bg !important;

    }

    .icon {
      border: 0px;
      width: 45px;
      height: 45px;
      margin: auto;
      margin-top: 48.5px;
      background-size: 45px;
    }

    &__text {

      min-width: fit-content;
      max-width: 399px;
      margin: auto;
      margin-top: 36px;
      margin-bottom: 36px;

      & div {
        margin: auto;
      }
    }
  }


  &__buttons {
    background-color: $color-white;
    height: 84px;
    padding-top: 20px;
    padding-bottom: 16px;

    &.no-button-background {
      background-color: #FFFDF2
    }

    & ion-button {
      margin: auto;
      --background: $color-white;
      border: 1px solid #CCD3DD;
      border-radius: 4px;
      --color: #282B30;
      --background-hover: #454F59;
    }
  }
}


</style>