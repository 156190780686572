<template>
  <ion-page>
    <ion-content :fullscreen="true" scrollX="true" scrollY="true">
      <Header></Header>
      <div class="template">
        <test-title :display-price="false"></test-title>

        <form @submit="updateUser($event)" v-if="state.loaded">
          <ion-list lines="none" class="ion-no-padding">
            <ion-item v-if="displayAccessCodeForm">
              <div class="input" :class="{'error':(state.errors.access_code!==null)}">
                <ion-label class="ion-text-wrap label default-font" :class="{'error':(state.errors.access_code!==null)}"
                           for="access_code" text-wrap>
                  {{ state.settings.access_list_text }}
                </ion-label>
                <ion-input class="small" type="text" id="access_code"
                           name="access_code" @ionChange="resetForm('access_code')"
                           v-model="state.user.access_code"
                           :class="{'error':(state.errors.access_code!==null)}"
                ></ion-input>
                <div class="form_error" :class="{'error':(state.errors.access_code!==null)}">
                  {{ state.errors.access_code }}
                </div>
              </div>
            </ion-item>


            <ion-item v-if="state.settings.test_password_required" class="template__description">
              <div class="input">
                <ion-label class="ion-text-wrap label default-font"
                           :class="{'error':(state.errors.test_password!==null)}" text-wrap>
                  {{ state.language_set.ex_form_type_given_password }}
                </ion-label>
                <ion-input class="small" type="password" id="test_password" :clearOnEdit="false"
                           name="test_password" @ionChange="resetForm('test_password')"
                           :class="{'error':(state.errors.test_password!==null)}"
                           :value="state.user.test_password"/>
                <div class="form_error" v-bind:class="{'error':(state.errors.test_password!==null)}">
                  {{ state.errors.test_password }}
                </div>
              </div>
            </ion-item>

            <Banner class="password-required-text" :message="state.language_set.ex_form_not_reg_password"
                    banner-type="info"
                    v-if="state.settings.test_password_required && state.settings.resume_later"></Banner>


          </ion-list>


          <div class="button-area">
            <div v-if="state.errors.api.message!=null" class="button-area__error-banner">
              <banner :message="state.errors.api.message" :banner-type="state.errors.api.type"></banner>
            </div>

            <ion-button :disabled="state.disable_button" type="submit" data-cy="continue-btn">
              {{ state.language_set.link_continue }}
            </ion-button>
          </div>
        </form>
        <CmPowerByBanner></CmPowerByBanner>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonButton, IonContent, IonInput, IonItem, IonLabel, IonList, IonPage} from '@ionic/vue';
import {computed, defineComponent, reactive} from 'vue';
import store from "@/store";
import RouteHandler from "../router/routeHandler";
import {useRouter} from "vue-router";
import Header from "../components/html/Header";
import Banner from "../components/html/Banner";
import CmPowerByBanner from "../components/html/CmPowerByBanner";
import ErrorPage from "./ErrorPage";
import TestTitle from "../components/html/TestTitle";
import constant from "../constant";


export default defineComponent({
  name: 'Login',
  components: {
    ErrorPage,
    IonButton,
    IonItem,
    IonInput,
    IonLabel,
    IonList,
    IonContent,
    Header,
    Banner,
    IonPage,
    CmPowerByBanner,
    TestTitle,

  },

  setup(props, ctx) {
    const routeHandler = new RouteHandler(useRouter());

    const state = reactive({
      settings: store.state.Test.settings['login'],
      login: store.state.Test.data['login'] ?? [],
      user: {
        access_code: store.state.User.user.access_code ?? null,
        test_password: null,
      },
      language_set: store.getters.languageSet,
      errors: {
        access_code: null,
        test_password: null,
        api: {
          message: null,
          type: null
        }
      },
      disable_button: false,
      loaded: false,
    });

    // Initial check once the page is loaded.
    checkInitialError();
    autoSubmitAccessCode();


    function resetForm(id) {
      if (id == 'test_password') {
        state.errors.test_password = null;
      }

      if (id == 'access_code' && state.errors.access_code != null) {
        state.errors.access_code = null;
      }

      state.errors.api.message = null;
      state.errors.api.type = null;

    }


    function validateForm() {
      let valid = true;
      if (state.settings.access_list_required && state.user.access_code == '') {
        state.errors.access_code = state.language_set.ex_error_get_details_access_code_no_match;
        valid = false;
      }
      if (state.settings.test_password_required && state.user.test_password == '') {
        state.errors.test_password = state.language_set.ex_error_get_details_password_no_match;
        valid = false;
      }
      return valid;
    }

    const displayAccessCodeForm = computed(() => {

      if (store.state.User.user.access_code != null) {
        return false;
      } else if (state.settings.access_list_required && state.settings.access_list_text != null) {
        return true;
      }
      return false;
    })

    function updateUser(event) {
      event.preventDefault();

      // V-model with ion-input displays password. This code is to avoid to expose password in the form
      let password = document.querySelector('#test_password');
      if (password != null) {
        state.user.test_password = password.value;
      } else {
        state.user.test_password = null;
      }

      if (typeof state.user.access_code === 'string' || state.user.access_code instanceof String) {
        state.user.access_code = state.user.access_code.trim();
      }

      if (!validateForm()) {
        return;
      }

      state.disable_button = true;
      store.dispatch('User/update', {user: state.user})
          .then(() => {
            routeHandler.route();
            state.disable_button = false;
          }).catch((error) => {
        if (store.state.Error.error.error_code == constant.ERROR_NO_INTERNET) {
          store.dispatch('Error/openModal');
        } else {
          let data = error.response.data;

          let error_code = data.error_code;
          let error_level = data.error_level;
          let error_message = state.language_set[error_code] ?? data.error_message;

          if (data.hasOwnProperty('field') && data.field) {
            let field = data.field;
            if (field == 'password') {
              state.errors.test_password = error_message;
            }
            if (field == 'access_code') {
              state.errors.access_code = error_message;
              if (store.state.User.user.access_code != null) {
                store.dispatch('User/removeUserAccessCode');
                state.user.access_code = null;
              }
            }
          } else {
            state.errors.api.message = error_message;
            state.errors.api.type = error_level;
            state.errors.api.display = true;
          }
        }

        state.disable_button = false;

      });
    }

    /**
     * When access code is required and provided by GET or POST request, this page will check this after mounted
     * and aumatically submit the value
     */
    function autoSubmitAccessCode() {
      if (store.state.User.user.access_code != null && state.settings.access_list_required
          && state.settings.test_password_required == false) {
        store.dispatch('User/update', {user: state.user})
            .then(() => {
              store.dispatch('User/removeUserAccessCode');
              routeHandler.route();
              state.loaded = true;
            }).catch((error) => {
          store.dispatch('User/removeUserAccessCode');
          state.user.access_code = null;
          state.loaded = true;
        });
      } else {
        state.loaded = true;
      }

    }

    /**
     * Check If there is an error from other page.
     * When access code error happening before starting page, the user will browse back to
     * this page and needs to display error messages properly.
     */
    function checkInitialError() {
      let error = store.state.Error.error;
      let field = error.field ?? null
      let error_message = state.language_set[error.error_code] ?? error.message;

      if (error.message !== null) {
        if (field == 'access_code') {
          state.errors.access_code = error_message;
        } else if (field == 'password') {
          state.errors.test_password = error_message;
        } else {
          store.dispatch('Error/display', error);
        }
      }
    }


    return {state, updateUser, resetForm, displayAccessCodeForm};
  },
});
</script>

<style scoped lang="scss">
ion-item {
  margin-bottom: 16px;
}

.password-required-text {
  margin-top: $small-space;
}

.input ion-label.label {
  max-width: fit-content;
}

.button-area {
  height: auto;
  min-height: 30px;

  &__error-banner {
    margin-bottom: $medium-space;
  }

}
</style>