<template>
  <form @submit="registerUser($event)">
    <ion-list class="authentication__content--list" lines="none" aria-hidden="true">
      <ion-list-header class="authentication__content--titles">
        <ion-label class="ion-text-wrap authentication__content--title">
          {{ state.language_set.ex_start_test }}
        </ion-label>

      </ion-list-header>

      <ion-item>
        <div class="input">
          <ion-label class="ion-text-wrap label">{{ state.language_set.ex_login_email_address }}</ion-label>
          <div id="email-area">
            <text id="user-email-text">{{ state.user.login_email }}</text>
          </div>
        </div>
      </ion-item>


      <ion-item>
        <div class="input">
          <ion-label class="ion-text-wrap label" :class="{'error': state.errors.password}">
            {{ state.language_set.title_password }}
          </ion-label>
          <ion-input class="small" type="password" id="password" :value="state.user.password" :placeholder=" state.language_set.title_password"
                     :class="{'error': state.errors.password}" :clearOnEdit="false"
                     @ionChange="resetForm('password')" maxlength="72">

          </ion-input>
          <div class="form_error">{{ state.errors.password }}</div>
        </div>
      </ion-item>


      <ion-item>
        <div class="input">
          <ion-label class="ion-text-wrap label" :class="{'error': state.errors.password_second}">
            {{ state.language_set.reset_password_retype }}
          </ion-label>
          <ion-input class="small" type="password" id="password2" :placeholder=" state.language_set.reset_password_retype "
                     @ionChange="resetForm('password2')" maxlength="72" :clearOnEdit="false"
                     :class="{'error': state.errors.password_second}"
                     :value="state.user.password_second">
          </ion-input>
          <div class="form_error">{{ state.errors.password_second }}</div>
        </div>
      </ion-item>
    </ion-list>


    <div class="authentication__footer">

      <div v-if="state.errors.api.message" class="authentication__footer__banner">
        <banner :message="state.errors.api.message" :banner-type="state.errors.api.type"></banner>
      </div>

      <ion-button class="button" fill="solid" type="submit" :disabled="state.disable_button" data-cy="continue-btn">
        {{ state.language_set.ex_start_test }}
        <arrow-icon class="arrow-right"></arrow-icon>
      </ion-button>

      <a role="button" v-on:click="goBack()" class="go-back-link" data-cy="previous-btn" tabindex="0">
        {{ state.language_set.link_go_back }}
      </a>

    </div>
  </form>
</template>

<script>
import {IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader} from '@ionic/vue';
import {reactive} from "vue";
import store from "@/store";
import RouteHandler from "../../router/routeHandler";
import {useRouter} from "vue-router";
import validateUtil from "../../utils/validateUtil";
import Banner from "../html/Banner";
import constant from "../../constant";
import ArrowIcon from "../icons/ArrowIcon";


export default {
  name: "StartPanel",
  components: {
    IonInput,
    IonLabel,
    IonItem,
    IonList,
    IonListHeader,
    IonIcon,
    IonButton,
    Banner,
    ArrowIcon
  },
  setup(props, ctx) {
    const state = reactive({
      settings: store.state.Test.settings['login'],
      user: {
        login_email: store.state.User.user['login_email'],
        password: null,
        password_second: null
      },
      language_set: store.getters.languageSet,
      errors: {
        email: null,
        password: null,
        password_second: null,
        api: {
          message: null,
          type: null
        }
      },
      disable_button: false
    });

    // setup router handler
    const routeHandler = new RouteHandler(useRouter());
    // setup validator
    const {validatePassword} = new validateUtil()

    // defines functions
    function registerUser(event) {
      event.preventDefault();
      state.disable_button = true;
      // V-model with ion-input displays password. This code is to avoid to expose password in the form
      let password = document.querySelector('#password');
      let password2 = document.querySelector('#password2');
      state.user.password = password.value;
      state.user.password_second = password2.value;

      if (checkForm() == false) {
        state.disable_button = false;
        return;
      }

      let user = {
        login_email: state.user.login_email,
        password: state.user.password
      };


      store.dispatch('User/register', {user: user}).then(() => {
        state.disable_button = false;
        routeHandler.route();
      }).catch((error) => {
        if (store.state.Error.error.error_code == constant.ERROR_NO_INTERNET) {
          store.dispatch('Error/openModal');
        } else {
          let error_code = error.response.data.error_code;
          let error_level = error.response.data.error_level;
          let error_message = state.language_set[error_code] ?? error.response.data.error_message;

          state.errors.api.message = error_message;
          state.errors.api.type = error_level;
        }
        state.disable_button = false;
      });
    }

    function resetForm(id) {
      if (id == 'password' && state.errors.password != null) {
        state.errors.password = null;
      }
      if (id == 'password2' && state.errors.password_second != null) {
        state.errors.password_second = null;
      }

      if (state.errors.api.message != null) {
        state.errors.api.message = null;
        state.errors.api.type = null;
      }
    }


    function checkForm() {
      let valid_password = validatePassword(state.user.password);
      let valid_password_second = validatePassword(state.user.password_second);

      if (valid_password !== true) {
        state.errors.password = state.language_set[valid_password];
        return false;
      }

      if (valid_password_second != true) {
        state.errors.password_second = state.language_set[valid_password_second];
        return false;
      }

      if (state.user.password.trim() != state.user.password_second.trim()) {
        state.errors.password_second = state.language_set['ex_error_get_details_password_no_match'];
        return false;
      }
      return true;

    }

    function goBack() {
      ctx.emit('start-panel', true);
    }

    return {
      state,
      goBack,
      registerUser,
      resetForm
    };
  },
};
</script>

<style lang="scss" scoped>
/*@import '@/theme/classmarker_theme';*/

.label {
  opacity: 10 !important;
}

#email-area {
  background: $color-gray-01;
  padding-top: 7px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  width: 250px;
  min-height: 35px;

  & text {
    max-width: 210px;
  }
}

.authentication {


  &__content {

    &--titles {
      margin-bottom: $small-space;
      padding: 0;
      display: flex;
      flex-direction: column;
      color: $color-gray-primary-2;
    }

    &--title {
      font-family: $default-font-family;
      font-size: $medium-font-size;
      font-weight: bold;
      line-height: 22px;
      width: 100%;
    }

    &--list {
      padding: 0;

      & ion-item {
        &:not(:last-child) {
          margin-bottom: $small-space;
        }
      }
    }
  }

  &__footer {
    display: block;
    flex-direction: row;
    margin-top: $medium-space;
    align-items: center;

    &__banner {
      margin-bottom: $large-space;
    }
  }


}

.icon {
  margin-left: $small-space;
  font-size: $default-font-size;
}

.go-back-link {
  position: relative;
  top: 5px;
  margin-left: $medium-space;

  &:hover {
    cursor: pointer;
  }
}

.arrow-right {
  margin-left: 11px;
  height: 16px;
  width: 16px;
}

</style>