<template>
  <div class="instructions">
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col class="instructions__list">
          <div>
            <html-content :content="data.custom_instruction" :allow-magnifying-image="true"/>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>


<script>
import {IonCol, IonGrid, IonRow} from '@ionic/vue';
import HtmlContent from "../html/HtmlContent";

export default {
  name: "Instruction",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    HtmlContent

  },
  props: {
    data: Object,
  },
  setup(props, ctx) {
    return {};
  },
};
</script>

<style lang="scss" scoped>
//@import '../../theme/variables';


.instructions {

  &__list {

    margin: 0;
    #padding: $medium-space $large-space $large-space;
    overflow-x:auto;
    background-color: $color-white;
    border-radius: $default-border-radius;
    margin-bottom:32px;
  }
}
</style>