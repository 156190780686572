<template>
  <ion-page id="page">
    <ion-content :fullscreen="true" scrollX="true" scrollY="true" v-bind:class="setupAppDefaultCssClasses()">

      <Header/>

      <div id="contents" class="template">
        <div style="margin-left:16px;margin-bottom:32px;" v-if="state.theme.show_ads">
          <google-ads :google-ad-slot="googleAdSlot"></google-ads>
        </div>

        <test-title :display-price="false" :display-title="false"></test-title>

        <div class="padding-top-medium margin-bottom-medium"
             v-if="state.settings.time_limits>0  && state.result.time_out">
          <banner :message="'<strong>'+state.language_set.note+'</strong>: '+state.language_set.time_limit_reached"
                  banner-type="warning">
          </banner>
        </div>


        <SurveyResultContent v-if="state.settings.survey_test"></SurveyResultContent>
        <TestResultContent v-else></TestResultContent>


        <div style="margin-top:50px; margin-left:16px;margin-bottom:32px;" v-if="state.theme.show_ads">
          <google-ads :google-ad-slot="googleAdSlot"></google-ads>
        </div>

        <CmPowerByBanner></CmPowerByBanner>

      </div>


    </ion-content>
  </ion-page>
</template>

<script>
import {IonButton, IonCard, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow} from '@ionic/vue';
import {defineComponent, reactive} from 'vue';
import store from "@/store";
import Header from "../components/html/Header";
import Banner from "../components/html/Banner";
import authHandler from "../router/authHandler";
import CmPowerByBanner from "../components/html/CmPowerByBanner";
import GoogleAds from "../components/html/GoogleAds";
import htmlSetupUtil from "../utils/htmlSetupUtil";
import googleTagManagerUtils from "@/utils/googleTagManagerUtils";
import TestResultContent from "@/components/result/TestResultContent.vue";
import TestTitle from "@/components/html/TestTitle.vue";
import SurveyResultContent from "@/components/result/SurveyResultContent.vue";

export default defineComponent({
  name: 'Test',
  components: {
    IonContent,
    IonPage,
    IonButton,
    Header,
    IonGrid,
    IonRow,
    IonCol,
    Banner,
    IonCard,
    IonIcon,
    CmPowerByBanner,
    GoogleAds,
    TestResultContent,
    TestTitle,
    SurveyResultContent
  },
  mounted() {
    /**
     * Dislable copy & paste depedning on the setup
     */
    const {disablePaste, disableCopy} = htmlSetupUtil();
    // disable copy & paste if it is required.
    disablePaste();
    disableCopy();

    googleTagManagerUtils().pushEventData("Test Finished", "");

    /**
     * Remove before unload event. The APP.vue file is loaded later than this page,
     * unloadEvent setup should be delayed until App.vue file is loaded
     *
     * Mount order : result.vue => app.vue.
     * We had put very minimal amount of delay to ensure, popupUnload Event is removed from result page
     * when refresh the result page.
     */
    setTimeout(() => {
      window.removeEventListener('beforeunload', this.$popupUnLoadEvent);
    }, 1500);
  },

  setup(props, ctx) {
    const state = reactive({
      result: store.state.Test.data.result,
      theme: store.state.Test.settings.theme,

      language_set: store.getters.languageSet,
      settings: store.getters.testSettings,

    });

    // ensure clear auto submit timer from the test page
    store.dispatch('clearTestAutoSubmitTimer');


    const googleAdSlot = process.env.VUE_APP_GOOGLE_ADS_SLOT_RESULT;
    const quiz_id = new URL(location.href).searchParams.get('quiz');

    authHandler.setExpireTokenIn15m(quiz_id);
    authHandler.deleteIframeToken(quiz_id);


    // default html setup method
    const {setupAppDefaultCssClasses} = htmlSetupUtil();

    return {
      state, googleAdSlot, setupAppDefaultCssClasses
    };

  },


});
</script>

<style lang="scss" scoped>

:deep(.header.default-header) {
  min-height: 58px;
}

.template {

  &__continue {
    padding: 24px 24px 24px 32px;
    margin: 0px;
    min-height: 138px;
    width: 100%;
    border-radius: 5px;
    display: block;


    &__text {
      vertical-align: middle;
      font-family: Arial;
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.02em;
    }


    &__button {
      position: relative;
      margin-top: $medium-space;
      max-width: 100%;

    }
  }
}

.button-row {
  margin-top: $medium-space;
  margin-bottom: $medium-space;
}

.word-wrap {
  white-space: normal;
  height: auto;
  min-height: 38px;
  word-break: break-all;
}

.continue-button-text {
  min-height: 38px;
  line-height: 38px;
}


</style>