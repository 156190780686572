import httpClient from "./httpClient";
import store from "@/store";

const PAYMENT_PATH = '/shopping-cart'
/**
 *
 * @param token : API Access Token
 * @param current_page: target page for submit answers
 * @param answers: List of UserAnswer Object
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function getPaymentStatus(token) {

    // construct payload to submit an answer to api end points
    let quiz_id = store.getters.quizId;

    return httpClient.get(token, PAYMENT_PATH + '/status?quiz='+quiz_id);
}



export default {getPaymentStatus};