<template>
  <div>


    <test-result-card :title="state.theme.test_title"
                      :points-available="state.result.points_available"
                      :points-scored="state.result.points_scored"
                      :points-percentage="state.result.points_percentage"
                      :duration="state.result.duration"
                      :passed="state.result.pass"
                      :date-started="state.result.date_started"
                      :date-finished="state.result.date_finished"
                      :feedback="state.result.test_feedback"
                      :display-score="state.settings.display_result_score"
                      :settings="state.settings"
                      :certificate-url="state.result.certificate_url"
                      :disable-print-button="state.disable_print_button"
                      :require-grading="state.correct_stats.require_grading > 0"
                      :receipt-url="state.result.receipt_url"
                      v-on:print="print()"
    ></test-result-card>

    <div class="margin-top-medium margin-bottom-medium" v-if="state.result.test_feedback == null || state.result.test_feedback == '' ">
      <Banner :message="state.language_set.results_view_hidden"
              banner-type="warning">
      </Banner>
    </div>

    <ion-card class="template__continue margin-top-large"
              v-if="state.result.return_url!=null && state.result.return_url!=''">
      <div class="template__continue__text">
        {{ state.language_set.quiz_continue_here_title }}
      </div>
      <ion-button class="template__continue__button continue-button word-wrap" @click="openReturnUrl()" text-wrap>
        <span class="continue-button-text">{{ returnUrlText }}</span>
      </ion-button>

    </ion-card>

    <div v-if="state.result.category_statistics">
      <category-stats-card :category-statistics="state.result.category_statistics"
                           :display-points="state.settings.display_result_score"/>
    </div>


    <div class="margin-top-large" v-if="state.result.question_feedbacks">

      <question-feedback-list
          :question-feedbacks="state.result.question_feedbacks"
          :test-sections="state.result.test_sections"
          :question-correct-stats="state.correct_stats"
          :incorrect-only="state.settings.display_results_incorrect_questions_only"
          :display-bookmarks="state.settings.allow_click_previous"
      />
    </div>
  </div>
</template>

<script>
import {IonButton, IonCard, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow} from '@ionic/vue';
import {computed, defineComponent, reactive} from 'vue';
import store from "@/store";
import QuestionFeedbackList from "@/components/result/QuestionFeedbackList.vue";
import TestResultCard from "@/components/result/TestResultCard.vue";
import CategoryStatsCard from "@/components/result/CategoryStatsCard.vue";
import Banner from "@/components/html/Banner.vue";

export default defineComponent({
  name: 'TestResultContent',
  components: {
    Banner,
    QuestionFeedbackList,
    IonContent,
    IonPage,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    TestResultCard,
    CategoryStatsCard,
    IonCard,
    IonIcon,
  },
  mounted() {

  },

  setup(props, ctx) {
    const state = reactive({
      result: store.state.Test.data.result,
      theme: store.state.Test.settings.theme,
      correct_stats: {
        all: 0,
        correct: 0,
        partial: 0,
        incorrect: 0,
        require_grading: 0,
        has_bookmarked: 0
      },
      language_set: store.getters.languageSet,
      settings: store.getters.testSettings,
      disable_print_button: false,
    });


    __setCorrectStats();

    function __setCorrectStats() {
      for (let question_id in state.result.question_feedbacks) {

        let feedback = state.result.question_feedbacks[question_id];

        if (feedback) {
          if (feedback.full_mark && feedback.is_survey_question === false) {
            state.correct_stats.correct += 1;

          } else if (feedback.grade_required) {
            state.correct_stats.require_grading += 1;

          } else if (Number(feedback.point_scored) > 0) {
            state.correct_stats.partial += 1;

          } else if (Number(feedback.point_scored) === 0 && feedback.is_survey_question === false) {
            state.correct_stats.incorrect += 1;
          }

          if (feedback.has_bookmarked) {
            state.correct_stats.has_bookmarked += 1;
          }
        }

        state.correct_stats.all += 1;
      }
    }


    function openReturnUrl() {
      let return_url = state.result.return_url.replace(/&amp;/g, '&');
      window.open(return_url, '_blank').focus();
    }


    /**
     * Get Return URL Text.
     * If Return URL text exists, then return it.
     * Otherwise get the origin of the return URL.
     * @type {ComputedRef<unknown>}
     */

    const returnUrlText = computed(() => {
      if (state.result.return_url_text != null && state.result.return_url_text != '') {
        return state.result.return_url_text
      } else {
        let return_url = state.result.return_url.replace(/&amp;/g, '&');
        let domain = (new URL(return_url));
        return domain.origin;
      }
    });

    function print() {

      let ion_content = document.querySelector('ion-content');
      let print_content = document.querySelector('#print-content');
      print_content.innerHTML = ion_content.innerHTML;

      store.dispatch('setPrintButtonClicked');
      setTimeout(() => {
        window.print();
        store.dispatch('unsetPrintButtonClicked');
      }, 50)
    }

    // default html setup method
    return {
      state, openReturnUrl, returnUrlText, print
    };

  },


});
</script>

<style lang="scss" scoped>

:deep(.header.default-header) {
  min-height: 58px;
}

.template {

  &__continue {
    padding: 24px 24px 24px 32px;
    margin: 0px;
    min-height: 138px;
    width: 100%;
    border-radius: 5px;
    display: block;


    &__text {
      vertical-align: middle;
      font-family: Arial;
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.02em;
    }


    &__button {
      position: relative;
      margin-top: $medium-space;
      max-width: 100%;

    }
  }
}

.button-row {
  margin-top: $medium-space;
  margin-bottom: $medium-space;
}

.word-wrap {
  white-space: normal;
  height: auto;
  min-height: 38px;
  word-break: break-all;
}

.continue-button-text {
  min-height: 38px;
  line-height: 38px;
}


</style>