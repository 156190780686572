import store from '@/store';

export default function htmlSetupUtil() {

    /**
     * Setup classes for required settings.
     * This is only used in the top of the page or modal
     * @returns {string}
     */
    function setupAppDefaultCssClasses() {
        let disable_printer = disablePrinterClass();
        let disable_select = disableSelectClass();

        let result = [disable_select, disable_printer].filter(Boolean).join(' ');
        return result;
    }

    // return disable printer class
    function disablePrinterClass() {

        let settings = store.getters.testSettings;
        if (settings == null || !settings.print_allowed) {
            return 'disable-printer';
        }
    }

    function disableSelectClass() {

        let settings = store.getters.testSettings;
        if (settings == null || !settings.text_copy_allowed) {
            return 'disable-select';
        }
    }

    function disablePaste() {
        document.addEventListener('paste', (event) => {
            let settings = store.getters.testSettings;
            if (!settings.text_paste_allowed) {
                event.preventDefault();
            }
        });
    }


    function disableCopy() {
        document.addEventListener('copy', (event) => {
            let settings = store.getters.testSettings;

            if (!settings.text_copy_allowed) {
                event.preventDefault();
            }
        });

        document.addEventListener('cut', (event) => {
            let settings = store.getters.testSettings;

            if (!settings.text_copy_allowed) {
                event.preventDefault();
            }
        });
    }

    function setupLanguage() {
        let settings = store.getters.testSettings;
        let language_set = store.getters.languageSet;
        let lang_code = language_set['code'];
        // setup html attribute for language and translation
        let html = document.querySelector("html");
        html.setAttribute('lang', lang_code);
        if (settings == null || !settings.browser_translation_allowed) {
            html.setAttribute("translate", "no")
        }
        if (lang_code == 'ar') {
            html.setAttribute("dir", "rtl");
        }

    }


    return {disablePaste, disableCopy, setupAppDefaultCssClasses, setupLanguage}
}


