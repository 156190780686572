<template>
  <ion-page>
    <ion-content :fullscreen="true" scrollX="true" scrollY="true">
      <Header></Header>
      <div class="template" style="padding-top:1px">

        <TestTitle :display-price="false" :display-title="false"></TestTitle>
        <ion-card>
          <ion-icon class="icon icon-flag"></ion-icon>
          <IonCardHeader class="ion-no-padding">{{ state.language_set.test_saved_title }}</IonCardHeader>
          <IonCardContent>
            {{ progress_save_text }}
          </IonCardContent>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonCard, IonCardContent, IonCardHeader, IonContent, IonIcon, IonPage} from '@ionic/vue';
import {computed, defineComponent, reactive} from 'vue';
import authHandler from "../router/authHandler";
import Header from "../components/html/Header";
import TestTitle from "../components/html/TestTitle";
import store from '@/store';
import constant from "../constant";


export default defineComponent({
  name: 'Logout',
  components: {
    Header,
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonIcon,
    TestTitle,
  },
  mounted() {
    /**
     * Remove before unload event. The APP.vue file is loaded later than this page,
     * unloadEvent setup should be delayed until App.vue file is loaded
     */
    setTimeout(() => {
      window.removeEventListener('beforeunload', this.$popupUnLoadEvent);
    }, 1000);
  },
  setup(props, ctx) {
    const state = reactive({
      language_set: store.getters.languageSet,
    });

    //Delete auth token
    let urlParams = new URLSearchParams(window.location.search);
    let quiz_id = urlParams.get('quiz');
    authHandler.deleteToken(quiz_id);

    const progress_save_text = computed(() => {
      if (store.getters.groupType == constant.GROUP_TYPE_LINK) {
        return state.language_set.test_saved_text;
      } else {
        // Group text contains <br/> tag. Removing the tag in the F.E as it need to support both legacy and new UI for now.
        // TODO: remove <br/> tag from the B.E
        let text = state.language_set.progress_save_text;
        text = text.replace(/\<br\s*\/\>/g, ' ');
        return text;
      }
    })

    return {state, progress_save_text};
  },
});
</script>

<style scoped lang="scss">
.icon-flag {
  width: 53px;
  height: 68.96px;
  background-size: 53px;
  position: relative;
  display: block;
  margin: auto;
  margin-top: 93px;
}

ion-card-header {
  color: $text-color-primary;
  font-size: 24px;
  //styleName: H1;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 28px;
}

ion-card-content {
  //styleName: Body / Regular;
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
  width: 62%;
  display: block;
  margin: auto;
  margin-bottom: 74px;

}
</style>