import httpClient from "./httpClient";
import store from "@/store";
import constant from "../constant";

const TEST_PATH = '/test'

/**
 *
 * @param token : API Access Token
 * @param current_page: target page for submit answers
 * @param answers: List of UserAnswer Object
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function init(token, payload) {
    let quiz_id = store.getters.quizId;
    let group_type = payload['group_type'];

    let data = {
        "cm_return_url_params": payload.cm_return_url_params,
        "cm_user_id": payload.cm_user_id,
        "resume_params": payload.resume_params
    }

    if (payload.hasOwnProperty('first_name')) {
        data.first_name = payload['first_name'];
    }
    if (payload.hasOwnProperty('last_name')) {
        data.last_name = payload['last_name'];
    }
    if (payload.hasOwnProperty('email')) {
        data.email = payload['email'];
    }


    if (group_type == constant.GROUP_TYPE_GROUP) {
        return httpClient.post(token, '/test/init?group_id=' + quiz_id, data)
    } else {
        return httpClient.post(token, '/test/init?quiz=' + quiz_id, data)

    }
}

function submitPageAnswer(token, page, answers, next_page, status, auto_save_answers) {

    let quiz_id = store.getters.quizId;
    let score_id = store.getters.scoreId;
    let group_type = store.getters.groupType;


    // construct payload to submit an answer to api end points
    let payload = {
        page: page,
        next_page: next_page,
        status: status,
        auto_save_answers: auto_save_answers,
        answers: []
    };

    for (const [question_id, answer] of Object.entries(answers)) {

        let single_answer_json = '';

        single_answer_json = {
            'question_id': question_id,
            'flagged': answer.flagged,
            'type': answer.type,
            'answer': answer.answer,
        }

        payload.answers.push(single_answer_json);
    }
    let path = TEST_PATH;

    if (group_type == constant.GROUP_TYPE_GROUP) {

        // let student_id = store.getters.studentId;
        let group_id = store.getters.groupId;

        path += '/continue?test_id=' + quiz_id + '&group_id=' + group_id + '&student_id=' + score_id;

    } else {
        path += '/continue?quiz=' + quiz_id + '&score_id=' + score_id;

    }

    if (auto_save_answers) {
        path = path + '&auto_submit=1';
    } else {
        path = path + '&auto_submit=0';
    }

    return httpClient.post(token, path, payload);
}

function getOnlineStatus() {
    let path = TEST_PATH + '/online-status';
    return httpClient.get(null, path);
}

function nextPage(token, page, answers) {
    return submitPageAnswer(token, page, answers, true, 'continue', false);
}

function previousPage(token, page, answers) {
    return submitPageAnswer(token, page, answers, false, 'continue', false);
}

function autoCommit(token, page, answers) {
    return submitPageAnswer(token, page, answers, false, 'continue', true);
}

function autoComplete(token, page, answers) {
    return submitPageAnswer(token, page, answers, false, 'complete', true);
}

function complete(token, page, answers) {
    return submitPageAnswer(token, page, answers, false, 'complete', false);
}

function saveExit(token, page, answers) {
    // set auto_save_answer as true to ensure when save and exists not increase page number
    // set next_page as true if page questions are all answered then goto next page
    return submitPageAnswer(token, page, answers, true, 'save_exit', true);
}

async function checkPDFDownload(token) {
    let quiz_id = store.getters.quizId;
    let score_id = store.getters.scoreId;
    let response = await httpClient.get(token, TEST_PATH + '/check-pdf?quiz=' + quiz_id + '&score_id=' + score_id);
    return response.data.data.result;
}

async function reportCheating(token, cheat_type, cheat_details) {
    let quiz_id = store.getters.quizId;
    let url = TEST_PATH + '/log-cheating?quiz=' + quiz_id;
    let payload = {
        type: cheat_type,
        details: cheat_details
    }

    let response = await httpClient.post(token, url, payload);
    return response.data.data.result;
}


async function canStartTest(token) {

    let quiz_id = store.getters.quizId;
    let url = TEST_PATH + '/check-can-test-start?quiz=' + quiz_id;
    let response = await httpClient.post(token, url);
    return response.data.data.result;
}

export default {
    init,
    nextPage,
    previousPage,
    autoCommit,
    autoComplete,
    saveExit,
    complete,
    checkPDFDownload,
    getOnlineStatus,
    reportCheating,
    canStartTest
};