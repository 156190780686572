<template>
  <ion-grid v-bind:class="setupAppDefaultCssClasses()">
    <ion-row>
      <!-- left border arrow shape -->
      <div id="arrow-border-area">
        <div id="border-arrow" :class="rightToLeft" v-bind:style="arrowPositionStyle">
        </div>
      </div>

      <!-- match list area -->
      <div id="match-list-area" v-bind:style="'max-height: '+height+'px'">
        <div class="match-list">
          <ion-item v-for="match in matches" :key="match.id" lines="none">
            <ion-grid style="width:100%">
              <ion-row>
                <ion-label class="ion-text-wrap overflow-x-auto" style="width:80%">
                  <HtmlContent :content="match.content" :allow-magnifying-image="true"></HtmlContent>
                </ion-label>
              </ion-row>
              <ion-row class="select-button-area">
                <ion-button color="light" v-on:click="updateAnswer(clue_id, match.id)">
                  {{ state.language_set.matching_select }}
                </ion-button>
              </ion-row>
            </ion-grid>
          </ion-item>
        </div>
      </div>
    </ion-row>
  </ion-grid>


</template>

<script>
import {IonButton, IonCol, IonGrid, IonItem, IonLabel, IonList, IonRow} from '@ionic/vue';
import {computed, defineComponent, reactive} from 'vue';
import HtmlContent from "../../html/HtmlContent";
import store from '@/store';
import htmlSetupUtil from "../../../utils/htmlSetupUtil";

export default defineComponent({
  name: 'MatchingMediaModal',
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    HtmlContent,
  },
  props: {
    clue_id: {type: String, required: false},
    matches: {
      type: Array, required: false,
      default: () => {
        return []
      }
    },
    user_answer: {type: String, required: false},
    height: {type: Number},
    arrowPosition: {
      type: Number
    },
    arrowFromTop: {
      type: Boolean
    }
  },
  emits: ['select-match'],
  setup(props, ctx) {
    const state = reactive({
      user_answer: props.userAnswer ?? {},
      area_height: props.height,
      language_set: store.getters.languageSet,
    });

    function updateAnswer(clue_id, match_id) {

      state.user_answer = match_id
      let data = {
        clue_id: clue_id,
        match_id: state.user_answer
      }
      ctx.emit('select-match', data);
    }

    const isSelected = (function (clue_id, match_id) {
      if (props.userAnswer != null) {
        if (clue_id in props.userAnswer && match_id === props.userAnswer[clue_id]) {
          return true;
        }
      }
      return false;
    });

    const heightStyle = computed(() => {
      let style = `{max-height: ${state.area_height}px}`;
      return style;
    });

    const arrowPositionStyle = computed(() => {
      let style = {};
      if (props.arrowFromTop) {
        style = {top: props.arrowPosition + 'px'};
      } else {
        style = {bottom: props.arrowPosition + 'px'};
      }
      return style;
    })

    const {setupAppDefaultCssClasses} = htmlSetupUtil();

    /**
     * Set constant that text should render right to left
     */
    const reverse_text = store.getters.reverseText;
    const rightToLeft = computed(() => {
      if (reverse_text) {
        return 'rtl';
      }
    });

    return {state, isSelected, updateAnswer, heightStyle, setupAppDefaultCssClasses, rightToLeft, arrowPositionStyle};
  },


});
</script>

<style lang="scss" scoped>
@import '../../../theme/classmarker_theme';


.question-text {
  margin-top: $medium-space;
  margin-bottom: $medium-space;
}

#arrow-border-area {
  max-height: 50%;
  width: 24px;

  & #border-arrow {
    position: absolute;

    left: 15px;
    border-style: solid;
    border-width: 21px 11px 21px 0;
    border-color: transparent #F9FAFB;
    position: absolute;
    z-index: 1;

    &.rtl {
      border-width: 21px 0px 21px 11px;
      left: -1px;
    }

  }
}

#match-list-area {
  border: 1px solid #E9ECEF;
  box-shadow: 0px 5px 30px 2px rgb(0 0 0 / 50%);
  border-radius: 10px;
  margin: 0px;
  width: calc(100% - 24px);
  background: #F9FAFB;
  overflow: auto;

  & .match-list {
    background: transparent;
  }

  & ion-item {
    margin-left: 21px;
    margin-right: 21px;
    margin-bottom: 15px;
    margin-top: 15px;

    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 17px;
  }

  .select-button-area {
    margin-top: $medium-space;
  }
}

:deep(img) {
  max-width: 100% !important;
  max-height: 100%;
}

ion-row {
  width: 100%;
}


</style>