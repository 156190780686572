<template>
  <div class="banner_template">
    <ion-row class="preview-banner">
      <ion-col class="preview-title">
        PREVIEW MODE
      </ion-col>
      <ion-col class="limited-feature-text">
        Limited features. <span class="learn_more_text" @click="openPopOver($event)">Learn more</span>
      </ion-col>
      <ion-col>
        <ion-button class="back-button" @click="closePreviewMode">Close preview</ion-button>
      </ion-col>
    </ion-row>

  </div>
</template>

<script>
import {IonButton, IonCol, IonIcon, IonRow, popoverController} from '@ionic/vue';
import {defineComponent, reactive} from 'vue';
import store from "@/store";
import authHandler from "../../router/authHandler";
import cookie from "../../utils/cookie";
import PreviewLimitedFeaturePopover from "./PreviewLimitedFeaturePopover";


export default defineComponent({
  name: 'PreviewModeBanner',
  components: {
    IonIcon,
    IonCol,
    IonRow,
    IonButton,

  },
  props: {},


  setup(props, ctx) {
    const state = reactive({});
    const test_settings = store.getters.testSettings;
    const group_id = test_settings.group_id;
    const group_type = test_settings.group_type;
    const test_id = test_settings.test_id;
    const track_from_admin_app = store.getters.trackFromAdminApp;

    function closePreviewMode() {

      let url = process.env.VUE_APP_CM_DOMAIN;
      if (group_type == 'link') {
        let quiz_id = store.getters.quizId
        let cookie_name = authHandler.getTokenCookieName(quiz_id);
        cookie.remove(cookie_name);
        url = url + '/a/assign/nrgmanage/?show=1&test_id=' + test_id + '&nrg_id=' + group_id + '&return=listresults';
      } else {
        // TODO: delete cookie for group test

        if (group_id == 0) {

            if(track_from_admin_app == 'test_dashboard'){
                url = url + '/a/tests/test/dashboard/?test_id=' + test_id;
            }else{
                url = url + '/a/tests/test/?test_id=' + test_id + '&trk=edittest_inrow_edit';
            }

        } else {
          url = url + '/a/results/tests/test/rgusers/?test_id=' + test_id + '&rg_id=' + group_id+'&active_panel=settings';
        }
      }

      location.href = url;
    }


    async function openPopOver(event) {

      let css_class = 'preview-mode-popup';
      const popover = await popoverController.create({
        component: PreviewLimitedFeaturePopover,
        cssClass: css_class,
        event: event,
        arrow: true,
        mode: "md",
        translucent: true,
        showBackdrop: false,
        alignment: "alignment"
      });

      let popover_content_div = popover.shadowRoot.querySelector('.popover-wrapper');
      popover_content_div.style.opacity = '0%';
      await popover.present();
      popover_content_div.style.top = (event.y - 150) + 'px';
      popover_content_div.style.opacity = '100%';
      popover_content_div.style.transition = 'opacity linear 0.3s'

      const {role} = await popover.onDidDismiss();

    }

    return {state, closePreviewMode, openPopOver};
  },


});
</script>

<style lang="scss" scoped>

.banner_template {
  position: fixed;
  bottom: 20px;
  left: 0px;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
}

.preview-banner {

  max-width: 520px;
  margin-left: auto;
  margin-right: auto;

  position: relative;
  border-radius: 5px;
  min-height: 40px;

  background-color: #2D2F3F;
  display: flex;
  align-items: center;
  font-family: Helvetica;
  font-size: 10px;
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.28);

  .preview-title {
    font-style: normal;
    font-weight: bold;
    line-height: 22px;
    align-items: center;
    letter-spacing: 0.08em;
    padding-left: 26px;
    padding-right: 19px;
    border-right: 1px solid #52556D;
    color: #FF6146;
    max-width: 135px;
  }

  .limited-feature-text {
    padding-left: 18px;
    font-size: 11px;
    font-weight: normal;
    color: $color-white;

    a {
      color: $color-white !important;
    }
  }

  .back-button {
    --color: #FFFFFF;
    --background: #52556D;
    font-size: 11px;
    line-height: 22px;
    margin-right: 8px;
    width: 107px;
    height: 26px;
    position: relative;
    float: right;
    display: block;
    font-weight: 400;
  }

  .learn_more_text {
    text-decoration: underline;
    cursor: pointer;
  }
}


</style>
