<template>
  <ion-grid style="width:100%">
    <ion-row>
      <ion-item lines="none" class="ion-no-margin" style="width:100%">
        <div class="input">
          <ion-label class="ion-text-wrap label default-font overflow-x-auto" v-bind:class="{'required':mustAnswer, 'error':error}">
            <html-content :content="extraInfoQuestion.question" :allow-magnifying-image="true"></html-content>
          </ion-label>
          <ion-textarea v-model="state.answer" class="textarea" maxlength="2500" rows="5" cols="20"
                        :class="displayErrorClass" @ionFocus="focused()"
                        @ionBlur="emitAnswers(id, state.answer)"></ion-textarea>
        </div>
      </ion-item>
    </ion-row>
    <ion-row>
      <div class="form_error">{{ error }}</div>
    </ion-row>
  </ion-grid>

</template>

<script>
import {IonGrid, IonItem, IonLabel, IonRow, IonTextarea} from '@ionic/vue';
import {computed, reactive} from "vue";

import UpdateAnswerMessageEmitter from "./UpdateAnswerMessageEmitter";
import HtmlContent from "../html/HtmlContent";

export default {
  name: "ExtraQuestionTextBox",
  components: {
    IonTextarea,
    IonLabel,
    IonItem,
    IonGrid,
    IonRow,
    HtmlContent
  },
  emits: ['answer_change', 'input_focused'],
  props: {
    extraInfoQuestion: Object,
    id: Number,
    error: {
      type: String,
      required: false,
      default: null,
    },
    mustAnswer:{
      type:Boolean
    },
  },
  setup(props, ctx) {
    const state = reactive({
      answer: props.extraInfoQuestion.user_answer
    });

    const {emitAnswers} = UpdateAnswerMessageEmitter(ctx);

    const displayErrorClass = computed(() => {
      if (props.error != null && props.error.trim() != '') {
        return 'error';
      }
    });

    function focused(){
      ctx.emit('input_focused')
    }

    return {
      state, emitAnswers, displayErrorClass, focused
    };
  },
};
</script>


<style scoped lang="scss">
/*@import '@/theme/classmarker_theme';*/
.ion-no-margin {
  margin-top: 0px !important;
}

ion-textarea.error {
  border:solid 2px $color-red;
}

.label{
  max-width: 100%;
}

/**
Ensure items in ion-label's are clickable
 */
ion-label{
  pointer-events: all!important;
}

.input ion-label.label {
  max-width: 100%;
}
</style>