<template>
  <span class="timer__text" data-cy="test-time-left-header">
            <ion-icon class="icon clock" aria-hidden="true"/>
            <strong >{{ state.language_set.test_time_left }}:</strong> {{ convertDurationString(timeoutRemainingTime) }}
  </span>
  <div class="timer__progress">
    <ion-progress-bar class="timer_progress_bar" :value="progressRatio"></ion-progress-bar>
  </div>
</template>

<script>
import {IonIcon, IonProgressBar} from '@ionic/vue';
import {defineComponent, reactive} from 'vue';
import store from "@/store";
import timeUtil from "../../utils/timeUtil";

export default defineComponent({
  name: 'HeaderTimerBar',
  components: {IonIcon, IonProgressBar},
  props:{
    progressRatio:Number,
    timeoutRemainingTime: Number
  },
  setup(props, ctx) {
    const state = reactive({
      language_set: store.getters.languageSet,
    });
    const {convertDurationString} = new timeUtil();
    return {state, convertDurationString}
  }
});
</script>

<style lang="scss" scoped>
@import '../../theme/classmarker_theme';
.timer {

  &__text {
    position: relative;
    //font-size: $default-font-size;

    height: 22px;
    line-height: 22px;
    margin-top: 12px;
    margin-bottom: 12px;

    left: 17px;
    float: left;

    & .clock {
      position: relative;
      top: 2px;
      margin-right: $small-space;
    }

  }

  &__progress {
    position: absolute;
    top: 46px;
    z-index: -1;
    width: 100%;

    & ion-progress-bar {
      height: 3px;
      border-radius: 10px;
      --background: #E9ECEF;
    }
  }
}


html[dir=rtl] {
  .timer__text {
    .clock{
      margin-right: auto;
      margin-left: $small-space;
    }

  }
}
</style>