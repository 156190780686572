<template>
  <ion-list class="question-content-area ion-no-padding" lines="none" style="padding-top: 0;">
    <ion-list-header class="check-list-header ion-no-padding">
      <div class="input">
        <div class="question-text" :id="'question-text'+questionIndex">
          <div id="instruction">{{ state.language_set.quiz_punctuation_instructions }}</div>
          <html-content :content="question.question" :allow-magnifying-image="true"/>
        </div>
      </div>
    </ion-list-header>

    <ion-item class="ion-no-margin answer-area">
      <div class="input question-option-list">
        <ion-input type="text" v-model="state.user_answer" @ionChange="updateAnswer" @ionBlur="updateAnswer"
                   :spellcheck="spellcheck"
                   :disabled="disabled" :aria-labelledby="'question-text'+questionIndex"></ion-input>
      </div>
    </ion-item>
  </ion-list>
</template>

<script>
import {IonInput, IonItem, IonLabel, IonList, IonListHeader} from '@ionic/vue';
import {defineComponent, reactive} from 'vue';
import HtmlContent from "../../html/HtmlContent";
import store from "@/store";

export default defineComponent({
  name: 'Grammar',
  components: {
    IonInput,
    IonItem,
    IonLabel,
    IonListHeader,
    IonList,
    HtmlContent
  },
  props: {
    question: Object,
    userAnswer: String,
    spellcheck: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    disabled: {
      type: Boolean,

    },
    questionIndex: {
      type: Number
    }
  },
  setup(props, ctx) {
    const state = reactive({
      register: true,
      user_answer: '',
      language_set: store.getters.languageSet,
    });

    // Set default answer from the question
    (() => {
      if (props.userAnswer != null) {
        state.user_answer = props.userAnswer;
      } else {
        state.user_answer = props.question.question
        updateAnswer();
      }


    })();


    function updateAnswer(event) {
      ctx.emit('update:userAnswers', state.user_answer);
    }

    return {state, updateAnswer};
  },


});
</script>

<style lang="scss" scoped>
@import '../../../theme/classmarker_theme';

#instruction {
  margin-bottom: 20px;
  color: #68747E;
  font-style: italic;
}

.answer-area {
  margin-top: $between-space;
}

</style>