<template>
  <ion-list class="radio-list question-content-area" lines="none" style="padding-top: 0;">
    <ion-list-header class="radio-list-header input ion-no-padding">
      <div class="question-text">
        <html-content :content="question.question" :allow-magnifying-image="true"/>
      </div>
    </ion-list-header>

    <ion-radio-group :value="state.user_answer" allow-empty-selection="false" @ionChange="updateAnswer($event)"
                     ref="question_ref">

      <ion-item class="radio-options-area " v-for="(option, index) in question.options" :key="index"
      >
        <div class="button-area">
          <ion-radio color="secondary" mode="md" v-bind:value="option.id" :disabled="disabled"
                     :id="generateOptionId(question, option)"
                     v-bind:aria-label="convertIntToAlphabetIndex(index, state.language_set)+'.'+option.content"></ion-radio>
        </div>

        <ion-label class="option-area ion-text-wrap" role="button"
                   @mouseover="labelHover(generateOptionId(question, option))"
                   @mouseleave="labelLeave(generateOptionId(question, option))"
                   @click="$multipleChoiceLabelClicked($event, generateOptionId(question, option))">
          <div class="question-option">
            <div class="question-index" v-if="displayOptionIndex" :class="isReverseClass"> {{ convertIntToAlphabetIndex(index, state.language_set) }}.</div>
            <div class="question-content overflow-x-auto" v-bind:class="{'with-index-text': displayOptionIndex}">
              <html-content :content="option.content" :allow-magnifying-image="true" css-class="cursor-pointer"
                            data-cy="question-option-text"/>
            </div>
          </div>

        </ion-label>

      </ion-item>
    </ion-radio-group>
    <p class="clearselection" v-if="!state.test_settings.must_answer_question">
      <a href="#" @click="resetRadioGroup($event)" class="custom_color">{{ state.language_set['clear_selection'] }}</a>
    </p>
  </ion-list>
</template>

<script>
import {IonButton, IonItem, IonLabel, IonList, IonListHeader, IonRadio, IonRadioGroup} from '@ionic/vue';
import {computed, defineComponent, onMounted, onUnmounted, reactive, ref} from 'vue';
import stringUtils from '../../../utils/stringUtils';
import HtmlContent from "../../html/HtmlContent";
import store from "@/store";

export default defineComponent({
  name: 'MultipleChoice',
  components: {
    IonButton,
    IonList,
    IonListHeader,
    IonRadioGroup,
    IonItem,
    IonLabel,
    IonRadio,
    HtmlContent
  },
  props: {
    question: Object,
    userAnswer: {
      type: String,
      default: () => {
        return ""
      }
    },
    disabled: {
      type: Boolean
    },
    displayOptionIndex: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    questionIndex: {
      type: Number
    }
  },
  setup(props, ctx) {
    const state = reactive({
      user_answer: props.userAnswer,
      language_set: store.getters.languageSet,
      test_settings: store.getters.testSettings
    });

    const {convertIntToAlphabetIndex} = stringUtils();
    const question_ref = ref();

    function updateAnswer(event) {
      let option_id = event.detail.value;
      state.user_answer = option_id;
      ctx.emit('update:userAnswers', state.user_answer);
    }

    function generateOptionId(question, option) {
      return 'question-' + question.id + '-' + option.id;
    }

    function labelHover(radio_button_id) {
      let element = document.getElementById(radio_button_id);
      if (element != null) {
        element.classList.add('radio-checkbox-hover');
      }
    }

    function labelLeave(radio_button_id) {
      let element = document.getElementById(radio_button_id);
      if (element != null) {
        element.classList.remove('radio-checkbox-hover');
      }
    }

    const resetRadioGroup = (event) => {
      event.preventDefault();
      state.user_answer = null;
    }

    let interval_id = null;



    onMounted(() => {

      /**
       * Hack remove label from ion checkbox shadow root
       * Ionic Framework generate shadowRoot that overwrapping original HTML tags
       * So, users are not able to click label contents such as image and links also not able to play
       * video and audio in the options.
       * Below code will remove shadow root from Dom element.
       */

          // wait for 2 second to create shadow root and label area
      let loop_count = 0;
      interval_id = setInterval(() => {


        // Get Radio reference element
        const radio_dom = question_ref.value.$el;
        if (radio_dom != null) {

          let labels = radio_dom.querySelectorAll('ion-radio');

          if (labels != null) {

            labels.forEach((label) => {
              if (label != null && label.shadowRoot != null) {

                let inner_label = label.shadowRoot.querySelector('label');
                if (inner_label != null) {

                  // Ensure label in shadow root is hidden, so users can click link and media in the options area
                  inner_label.style.display = 'none';

                  // Ensure radio button in shadow root is hidden
                  let inner_radio = label.shadowRoot.querySelector('input[type=radio]');
                  inner_radio.setAttribute('aria-hidden', 'true');
                  clearInterval(interval_id);
                  interval_id = null;
                }
              }

            });

          }
        }

        // if loop count reaches 50, kill the loop;
        loop_count++;
        if (loop_count == 100) {
          clearInterval(interval_id);
        }
      }, 200);
    })

    onUnmounted(() => {
      if (interval_id != null) {
        clearInterval(interval_id);
      }
    })

    let reverseText = store.getters.reverseText;
    const isReverseClass = computed(() => {
      if (reverseText) {
        return 'rtl'
      }
    })

    return {
      state,
      convertIntToAlphabetIndex,
      updateAnswer,
      generateOptionId,
      labelHover,
      labelLeave,
      question_ref,
      resetRadioGroup,
      isReverseClass
    };
  },


});
</script>

<style lang="scss" scoped>
@import '../../../theme/classmarker_theme';

ion-item {
  margin-top: 8px;
  margin-bottom: 8px;

  &:first-of-type {
    margin-top: $between-space;
  }

  ion-radio {
    cursor: pointer;
    margin-right: 0px !important;
  }

  ion-label {
    pointer-events: all !important;
    cursor: pointer;
    padding-left: 16px;


    min-height: 36px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    //line-height: 36px !important;

  }
}


.button-area {
  position: relative;
  width: 29px;
  height: 100%;

}

/**
Ensure items in ion-label's are clickable
 */
.option-area{
  line-height:22px !important;
}

.question-content {

  &.with-index-text {

    margin-left: 20px;
  }
}

.radio-list ion-item ion-label{
  margin-top: 12px !important
}

.clearselection {
  margin-left: 26px;
  margin-top: 0px;

  a {
    font-size: 13px;
    text-decoration: none;
  }
}

</style>
