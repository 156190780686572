<template>
  <ion-page>
    <ion-content :fullscreen="true" scrollX="true" scrollY="true">
      <Header></Header>
      <transition name="fade">
        <div class="template" v-if="state.display_contents">

          <TestTitle :display-price="false"></TestTitle>

          <Instruction v-if="state.state==STATE_INTRO" :settings="state.settings" :data="state.data"/>
          <CustomInstruction v-if="state.state==STATE_CUSTOM_INTRO" :data="state.data"/>
          <div class="button-area">
            <ion-button @click="goBack" v-if="isGoBackAvailable" class="ion-float-left" data-cy="previous-btn"
                        :disabled="state.button_disabled">
              {{ state.language_set.link_previous }}
            </ion-button>

            <ion-button :disabled="state.button_disabled" @click="next" class="ion-float-right" data-cy="continue-btn">
              {{ state.language_set.link_continue }}
            </ion-button>
          </div>
          <CmPowerByBanner style="margin-top:57px"></CmPowerByBanner>
        </div>
      </transition>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonButton, IonContent, IonPage} from '@ionic/vue';
import {computed, defineComponent, reactive} from 'vue';
import RouteHandler from "@/router/routeHandler";
import {useRouter} from "vue-router";
import store from "@/store";
import Instruction from "@/components/test-intro/Instruction";
import CustomInstruction from "@/components/test-intro/CustomInstruction";
import Header from "../components/html/Header";
import CmPowerByBanner from "../components/html/CmPowerByBanner";
import TestTitle from "../components/html/TestTitle";
import constant from "../constant";

export default defineComponent({
  name: 'TestIntro',
  components: {
    IonContent,
    IonPage,
    IonButton,
    Instruction,
    CustomInstruction,
    Header,
    CmPowerByBanner,
    TestTitle,
  },

  setup(props, ctx) {
    const STATE_INTRO = 'intro';
    const STATE_CUSTOM_INTRO = 'custom_intro';
    const route_handler = new RouteHandler(useRouter());

    const state = reactive({
      settings: store.state.Test.settings['test'],
      data: store.state.Test.data['test_intro'],
      state: "",
      language_set: store.getters.languageSet,
      button_disabled: false,
      display_contents: true,
      test_start_api_call_triggered: false,

    });

    state.state = __getInitialState();


    // this GoBack
    function goBack() {

      if(state.button_disabled){
        return;
      }

      state.display_contents = false;
      state.button_disabled = true;
      if (state.state === STATE_CUSTOM_INTRO && (state.settings.display_pretest_instruction == true || state.data.test_instruction != null)) {

        state.state = STATE_INTRO;
        state.button_disabled = false;
        setTimeout(() => {
          state.display_contents = true;
        }, 500)

      }
      // go Back to the last step - User intro
      else if (
          // case state: intro page
          state.state === STATE_INTRO
          //case state: custom intro page
          || state.state === STATE_CUSTOM_INTRO && (state.settings.display_pretest_instruction == false
              && state.data.test_instruction == null)
      ) {
        state.button_disabled = false;
        route_handler.goBack();
      }
    }

    function next() {
      if(state.button_disabled){
        return;
      }

      state.display_contents = false;
      state.button_disabled = true;

      if (state.state === STATE_INTRO && state.data.display_pretest_custom_instruction == true) {
        state.state = STATE_CUSTOM_INTRO;
        setTimeout(() => {
          state.button_disabled = false;
          state.display_contents = true;
        }, 500)
      } else {
        /**
         * Check Test Start API call is alreayd triggered.
         * This should be done by button disabled, but it is not working somehow.
         * To fix, use state.test_start_api_call_triggered with state.button_disabled flag.
         */
        if (state.test_start_api_call_triggered === false) {
          state.test_start_api_call_triggered = true;
          store.dispatch('Test/startTest').then(() => {
            route_handler.route();
            state.button_disabled = false;
          }).catch((error) => {
            if (store.state.Error.error.error_code == constant.ERROR_NO_INTERNET) {
              store.dispatch('Error/openModal');
              state.display_contents = true;
            } else {
              store.dispatch('Error/displayFromAPIError', error);
            }
            state.test_start_api_call_triggered = false;
            state.button_disabled = false;
          })
        }
      }
    }

    const isGoBackAvailable = computed(() => {
      if (state.state === STATE_INTRO) {
        return __canGoBackToUserIntro()
      } else if (state.state === STATE_CUSTOM_INTRO) {
        if (state.settings.display_pretest_instruction == true
            || (state.data.test_instruction!=null && state.data.test_instruction.trim() !== '' )) {
          return true;
        } else {
          return __canGoBackToUserIntro();
        }
      }
      return false;
    });


    // get initial state
    function __getInitialState() {
      if (state.settings.display_pretest_instruction == true || (state.data.test_instruction != null && state.data.test_instruction != '')) {
        return STATE_INTRO;
      } else if (state.data.display_pretest_custom_instruction == true) {
        return STATE_CUSTOM_INTRO;
      }
    }


    // check if settings allow to goback to user intro
    function __canGoBackToUserIntro() {
      let login_settings = store.getters.loginSetting;
      if (login_settings.first_name_required || login_settings.last_name_required || login_settings.email_required
          || login_settings.user_extra_info_required) {
        return true;
      }
      return false;
    }


    return {state, goBack, next, isGoBackAvailable, STATE_INTRO, STATE_CUSTOM_INTRO};
  },


});
</script>

<style lang="scss" scoped>
@import '../theme/classmarker_theme';

:deep(.test-title-area) {
  padding-bottom: 32px !important;
}

:deep(.button-area) {
  margin-top: 0px;
}
</style>