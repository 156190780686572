<template>
  <ion-list class="radio-list question-content-area" lines="none" style="padding-top: 0;">

    <ion-list-header class="radio-list-header input ion-no-padding">
      <div class="ion-text-wrap question-text">
        <html-content :content="question.question" :allow-magnifying-image="true"/>
      </div>
    </ion-list-header>

    <ion-radio-group :value="userAnswer">


      <div id="option-area-wrapper" :class="{'empty-answer':isEmptyAnswer()}" ref="options_area_ref">
        <ion-item class="radio-options-area " v-for="(option, index) in question.options" :key="index"
                  @click="labelClicked($event)">
          <div class="button-area">
            <ion-radio mode="md" disabled="true" slot="start" v-bind:value="option.id"></ion-radio>
          </div>
          <ion-label class="ion-text-wrap">
            <div class="question-option">
              <div class="question-index" :class="isReverseClass" v-if="displayOptionIndex"> {{ convertIntToAlphabetIndex(index, state.language_set) }}.</div>
              <div class="question-content overflow-x-auto" v-bind:class="{'with-index-text': displayOptionIndex}">
                <html-content :content="option.content" :allow-magnifying-image="true" data-cy="question-option-text"/>
              </div>
            </div>
          </ion-label>


        </ion-item>
      </div>
    </ion-radio-group>
  </ion-list>

  <banner id="info-banner" :bannerType="error.type" :message="error.message"
          v-if="isEmptyAnswer()"
  ></banner>
</template>

<script>
import {IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonRadio, IonRadioGroup} from '@ionic/vue';
import {computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref} from 'vue';
import stringUtils from '../../../utils/stringUtils';
import HtmlContent from "../../html/HtmlContent";
import store from '@/store';
import Banner from "../../html/Banner";


export default defineComponent({
  name: 'SurveyMultipleChoice',
  components: {
    IonList,
    IonListHeader,
    IonRadioGroup,
    IonItem,
    IonLabel,
    IonRadio,
    HtmlContent,
    IonIcon,
    Banner,
  },
  props: {
    question: Object,
    userAnswer: {
      type: String,
      default: () => {
        return ""
      }
    },
    displayOptionIndex: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  setup(props, ctx) {
    const state = reactive({
      language_set: store.getters.languageSet
    });

    const {convertIntToAlphabetIndex} = stringUtils();
    const options_area_ref = ref();

    function labelClicked(event) {
      if (event.target.nodeName == 'A') {
        event.preventDefault();

        //ensure block the event to progress
        let targetUrl = event.target.href;
        window.open(targetUrl);
      }
    }

    // initialise aria attributes
    let option_interval_id;
    onMounted(() => {
      option_interval_id = setInterval(() => {

        if (options_area_ref.value != null) {
          let option_area_dom = options_area_ref.value;
          let ion_labels = option_area_dom.querySelectorAll('ion-label');
          if (ion_labels.length > 0) {

            let hidden = ion_labels[0].getAttribute('aria-hidden');
            if (hidden != null && hidden == "true") {

              ion_labels.forEach((ion_label) => {
                clearInterval(option_interval_id);
                ion_label.setAttribute('aria-hidden', 'false');
              })
            }

          }
        }
      }, 100)
    })
    onBeforeUnmount(() => {
      clearInterval(option_interval_id);
    })

    setTimeout(() => {
      let ion_item_list = document.querySelectorAll('ion-item');
      for (let ion_item of ion_item_list) {
        ion_item.classList.remove('item-interactive-disabled');
      }
    }, 1000);


    function isEmptyAnswer() {
      if (props.userAnswer) {
        return false;
      }
      return true;
    }


    const error = computed(() => {
      let data = {
        message: '',
        type: '',
      }

      if (isEmptyAnswer()) {
        data['message'] = state.language_set.no_answer_given;
        data['type'] = 'warning';
      } else {
        data['message'] = null;
        data['type'] = null;
      }

      return data;
    })

    let reverseText = store.getters.reverseText;
    const isReverseClass = computed(() => {
      if (reverseText) {
        return 'rtl'
      }
    })

    return {state, convertIntToAlphabetIndex,  isEmptyAnswer, error, options_area_ref, labelClicked, isReverseClass};
  },
});
</script>

<style lang="scss" scoped>
@import '../../../theme/classmarker_theme';

ion-radio {
  --color-checked: #BEC8D1;
  --color: #BEC8D1;
}

#option-area-wrapper {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  margin-top: $medium-space;

  &.empty-answer {
    border-left: $color-red 2px solid;
  }
}

.radio-options-area {
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100% !important;
  min-height: $input-feedback-height;
}


.item-interactive-disabled.sc-ion-label-md-h:not(.item-multiple-inputs), .item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-md-h {
  /* cursor: default; */
  opacity: 1;
  /* pointer-events: none; */
}

.item-interactive-disabled.sc-ion-label-ios-h:not(.item-multiple-inputs), .item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-ios-h {
  opacity: 1;
}

ion-item {
  --min-height: $input-feedback-height !important;

  &.radio-options-area.item-radio-checked {
    border-radius: 5px;
    --background: #F9FAFB;
  }
}

.question-content {
  //width: calc(100% - 20px);

  &.with-index-text {
    width: calc(100% - 20px);
    margin-left: 20px;
  }
}


.button-area {
  position: relative;
  width: 45px;
  height: 100%;
}

/**
Ensure items in ion-label's are clickable
 */
ion-label {
  pointer-events: all !important;
}


</style>
