export default function validateUtil() {


    const validateEmail = function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(email)) {
            return true;
        } else {
            return 'ex_invalidemail';
        }
    }

    const validatePassword = ((password) => {

        if(password === null  ){
            return 'password_too_short';
        }

        if ( password.trim() == '' || password.length < 8) {
            return 'password_too_short';
        }
        if (password.length > 72) {
            return 'password_too_long';
        }
        //check password contains space or not
        var re = /\s/;
        if (re.test(password)) {

            return 'password_has_space';
        }
        return true;
    })

    const validateName = ((name, is_first_name)=>{
        if(name==null || name.trim() == ''){
            if(is_first_name){
                return 'ex_error_first_name';
            }else{
                return 'ex_error_last_name';
            }

        }else if(name.length>50){
            return 'name_invalid';
        }
        return true;
    })


    return {validateEmail, validatePassword, validateName}
}



