<template>
  <form @submit="registerUser($event)">
    <ion-list class="ion-text-wrap authentication__content--list" lines="none" aria-hidden="true">
      <ion-list-header class="authentication__content--titles">
        <ion-label class="ion-text-wrap authentication__content--title">
          {{ state.language_set.ex_start_test }} / {{ state.language_set.ex_resume_test }}
        </ion-label>
      </ion-list-header>

      <ion-item>
        <div class="input">
          <ion-label class="ion-text-wrap label" :class="{'error': state.errors.email}">{{ state.language_set.title_email }}</ion-label>
          <ion-input class="small" type="email" v-model="state.user.login_email" :placeholder="state.language_set.title_email"
                     :class="{'error': state.errors.email}" mode="md" :aria-label="state.language_set.title_email"
                     @ionFocus="resetForm"></ion-input>
          <div class="form_error">{{ state.errors.email }}</div>
        </div>
      </ion-item>
    </ion-list>


    <div class="authentication__footer">

      <div v-if="state.errors.api.message" class="authentication__footer__banner">
        <banner :message="state.errors.api.message" :banner-type="state.errors.api.type"></banner>
      </div>

      <ion-button class="button" fill="solid" data-cy="continue-btn" type="submit" :disabled="state.disable_button">
        {{ state.language_set.ex_start_test }}

        <arrow-icon class="arrow-right"></arrow-icon>
      </ion-button>
    </div>
  </form>
</template>

<script>
import {IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader} from '@ionic/vue';
import {reactive} from "vue";
import store from "@/store";
import validateUtil from "../../utils/validateUtil";
import Banner from "../html/Banner";
import constant from "../../constant";
import ArrowIcon from "../icons/ArrowIcon";


export default {
  name: "LoginPanel",
  components: {
    ArrowIcon,
    IonInput,
    IonLabel,
    IonItem,
    IonList,
    IonListHeader,
    IonIcon,
    IonButton,
    Banner
  },
  emits:['email-used'],
  setup(props, ctx) {
    const state = reactive({
      settings: store.state.Test.settings['login'],
      user: {
        login_email: store.state.User.user['login_email'],
      },
      language_set: store.getters.languageSet,
      errors: {
        email: null,
        api: {
          message: null,
          type: null
        }
      },
      disable_button: false
    });

    // setup validator
    const {validateEmail} = new validateUtil()

    // defines functions
    function registerUser(event) {
      event.preventDefault();
      state.disable_button = true;

      if (checkForm() == false) {
        state.disable_button = false;
        return;
      }

      store.dispatch('User/checkEmailInUse', {email: state.user.login_email})
          .then((data) => {
            state.disable_button = false;
            store.dispatch('User/setUserInfo', {login_email: state.user.login_email});
            ctx.emit('email-used', data.result);
          })
          .catch((error) => {
            if(store.state.Error.error.error_code==constant.ERROR_NO_INTERNET){
              store.dispatch('Error/openModal');
            }else{
              let error_code = error.response.data.error_code;
              let error_level = error.response.data.error_level;
              let error_message = state.language_set[error_code] ?? error.response.data.error_message;

              state.errors.api.message = error_message;
              state.errors.api.type = error_level;
            }
            state.disable_button = false;
          }
          );
    }

    function resetForm() {
      if (state.errors.email != null || state.errors.password != null
          || state.errors.password_second != null || state.errors.api.message != null) {
        state.errors.email = null;
        state.errors.password = null;
        state.errors.password_second = null;
        state.errors.api.message = null;
        state.errors.api.type = null;
      }
    }


    function checkForm() {
      let valid = true;
      let valid_email = validateEmail(state.user.login_email);

      if (valid_email !== true) {
        state.errors.email = state.language_set[valid_email];
        valid = false;
      }


      return valid;
    }

    return {
      state,
      registerUser,
      resetForm
    };
  },
};
</script>

<style lang="scss" scoped>
/*@import '@/theme/classmarker_theme';*/

.authentication {
  &__content {

    &--titles {
      margin-bottom: $small-space;
      padding: 0;
      display: flex;
      flex-direction: column;
      color: $color-gray-primary-2;
    }

    &--title {
      font-family: $default-font-family;
      font-size: $medium-font-size;
      font-weight: bold;
      line-height: 22px;
      width: 100%;
    }

    &--list {
      padding: 0;

      & ion-item {
        &:not(:last-child) {
          margin-bottom: $small-space;
        }
      }
    }
  }

  &__footer {
    display: block;
    flex-direction: row;
    margin-top: $medium-space;
    align-items: center;

    &__banner {
      margin-bottom: $large-space;
    }
  }


}

.arrow-right {
  margin-left: 11px;
  height: 16px;
  width: 16px;
}

</style>