import store from '@/store';
import httpClient from "@/api/httpClient";
import cookie from "@/utils/cookie";
import iframeMessageUtils from "../utils/iframeMessageUtils";

/**
 * Get Cookie name for storing token
 * @param quiz_id
 * @returns {string}
 */
function getTokenCookieName(quiz_id) {
    return '_cmqzremme__' + quiz_id;
}

function deleteToken(quiz_id) {

    let token_cookie_name = getTokenCookieName(quiz_id);
    cookie.remove(token_cookie_name);

    // delete Iframe token
    deleteIframeToken(quiz_id);
}

function deleteIframeToken(quiz_id) {

    const iframe_mode = store.state.iframe_mode;

    if (iframe_mode) {
        const {deleteIframeCookie} = iframeMessageUtils()
        deleteIframeCookie(quiz_id);
    }
}

/**
 * Set Token to be expired in 15 minutes
 * @param quiz_id
 */
function setExpireTokenIn15m(quiz_id) {
    let token_cookie_name = getTokenCookieName(quiz_id);
    cookie.setExpireTime(token_cookie_name, 15);
}

async function grantAuth(payload, token) {
    let quiz_id = payload.quiz_id;
    let group_type = payload.group_type;
    let token_cookie_name = getTokenCookieName(quiz_id);


    // token not provided, then get it from cookie
    if (token == undefined) {
        token = cookie.getCookie(token_cookie_name);
    }

    await httpClient.grantAuth(quiz_id, token, payload)
        .then((token) => {
            cookie.setCookie(token_cookie_name, token, 1);
            store.dispatch('Auth/setToken', token);
        })
}


export default {getTokenCookieName, grantAuth, deleteToken, setExpireTokenIn15m, deleteIframeToken};

