<template>
  <ion-header style="height:68px">
    <ion-toolbar>

      <ion-button type="button" fill="clear" slot="end" class="icon-button icon close-button" @click="closeModal()"></ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content  scrollX="true" scrollY="true">
    <img :src="url" class="modal-image">
  </ion-content>
</template>

<script>

import {defineComponent} from "vue";
import {IonButton, IonContent, IonHeader, IonToolbar, IonPage} from "@ionic/vue";

export default defineComponent({
  name: 'ImageModal',
  components: {
    IonPage,
    IonHeader,
    IonButton,
    IonContent,
    IonToolbar

  },
  props: {
    url: String,
    obj: Object
  },
  setup(props, ctx) {
    function closeModal() {
      props.obj.dismiss();
    }

    return {closeModal}
  },
});
</script>

<style scoped>
ion-content {
  --background: none !important;
}

.modal-image {

  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;
}

.close-button{
  position: absolute;
  right: 32px;
  cursor: pointer;
}

/* All required styles for Cm BBcode elements */
</style>