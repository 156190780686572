export default function stringUtils() {

    const convertIntToAlphabetIndex = ((index, language_set=null) => {
        index = (index + 1) % 26; // enusre index exceed 36 become rotated.
        let alphabet = (index + 9).toString(36);

        if(language_set == null){
            return alphabet.toUpperCase();
        }else{
            // If language set doesn't have alphabet translation, then return alphabet index
            if(language_set[alphabet] == null){
                return alphabet.toUpperCase();
            }else {
                // Otherwise return translated version of alphabet index
                return language_set[alphabet];
            }
        }

    });

    const countWord = ((in_string) => {
        if (in_string == null) {
            return 0;
        } else {
            let str = JSON.parse(JSON.stringify(in_string)) ?? '';

            str = str.trim();

            if (str == '') {
                return 0;
            } else {
                return str.split(/\s+/).length;
            }
        }
    })

    /**
     * Format Number for Score. If the number (string) is integer, remove floating number,
     * If number is float (eg. 3.14) make it as 1 decimal point nubmer.
     * @type {function(*=): number}
     */
    const formatNumber = ((num) => {
        if (typeof num === 'string') {
            num = parseFloat(num);
        }
        let remain = num % 1;
        if (remain == 0) {
            num.toFixed(0);
        } else {
            num.toFixed(1);
        }
        return num
    });

    const uuid4 = (() => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    })

    const escapeHtml = ((html) => {
        let container = document.createElement('div');
        let text = document.createTextNode(html);

        container.appendChild(text);
        let result = container.innerHTML;
        result = result.replace(/"/g, "&quot;"); // escape double quotes
        result = result.replace(/'/g, "&#039;"); // escape single quotes

        // remove container dom.
        container.innerHTML = '';
        container.remove();
        return result;
    });

    const convertCurrencySymbol = function (currency_name) {
        var currency_symbols = {
            'USD': '$', // US Dollar
            'CAD': '$', // CANADA Dollar
            'AUD': '$', // Australian Dollar
            'NZD': '$', // NewZealand Dollar
            'EUR': '€', // Euro
            'JPY': '¥', // Japanese Yen
            'GBP': '£', // British Pound Sterling
            // 'CRC': '₡', // Costa Rican Colón
            // 'ILS': '₪', // Israeli New Sheqel
            // 'INR': '₹', // Indian Rupee
            // 'KRW': '₩', // South Korean Won
            // 'NGN': '₦', // Nigerian Naira
            // 'PHP': '₱', // Philippine Peso
            // 'PLN': 'zł', // Polish Zloty
            // 'PYG': '₲', // Paraguayan Guarani
            // 'THB': '฿', // Thai Baht
            // 'UAH': '₴', // Ukrainian Hryvnia
            // 'VND': '₫', // Vietnamese Dong
        };

        if (currency_symbols[currency_name] !== undefined) {
            return (currency_symbols[currency_name]);
        } else {
            return currency_symbols['USD']
        }
    }

    return {convertIntToAlphabetIndex, countWord, formatNumber, uuid4, escapeHtml, convertCurrencySymbol}
}


