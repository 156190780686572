import store from '@/store';
import constant from "../constant";

/**
 * Register global functions which can called in any place of the app
 * @type {{unLoadEvent: methods.unLoadEvent}}
 */
const global_methods = {
    /**
     * Prevent unload event and display pop up message
     * @param event
     */
    popupUnloadEvent: (event) => {
        event.preventDefault();
        event.returnValue = '';
    },
    /**
     * Set Component orientation by theme settings
     * applicable : ion-modal
     * @param name
     * @returns {string}
     */
    setComponentOrientation: (name) => {
        let theme = store.getters.theme;
        //left align
        if (theme && theme.body_content_align == '0px') {
            name = name + " left-oriented";
        }
        return name;
    },

    getCorrectAriaText: (icon_class) => {
        let language_set = store.getters.languageSet;
        switch (icon_class) {
            case 'circular-tick':
                return language_set['key_correct'];

            case 'circular-x':
                return language_set['key_wrong'];

            case  'circular-tick-holo':
                return language_set['key_missed'];

            default:
                return '';
        }
    },


    /**
     * Hack : Ionic Checkbox/Radio Button Label cannot click inside of contents such as link or buttons.
     * in order to make sure it is clickable, using javascript to do it
     * when clicking element is not A or IMG should allow button to be checked
     * click event
     *
     *
     * [ Notes ] - https://developer.mozilla.org/en-US/docs/Web/API/Event/preventDefault#notes
     * Calling preventDefault() during any stage of event flow cancels the event, meaning that any default action normally taken by the implementation as a result of the event will not occur.
     * You can use Event.cancelable to check if the event is cancelable. Calling preventDefault() for a non-cancelable event has no effect.
     *
     *
     * @param event
     * @param radio_button_id
     */
    multipleChoiceLabelClicked: (event, option_id) => {
        let clickable = event.target.nodeName != 'A' && event.target.nodeName != 'VIDEO'
            && event.target.nodeName != 'AUDIO'
            && (event.target.nodeName != 'IMG' ||
                (event.target.nodeName == 'IMG' && !event.target.classList.contains('magnifying')));

        if (clickable) {
            event.preventDefault();
            let element = document.getElementById(option_id);
            element.click();

        } else if (event.target.nodeName == 'A') {
            event.preventDefault();

            //ensure block the event to progress
            let targetUrl = event.target.href;
            window.open(targetUrl);
        }

    },

    namesInReverseOrder: (lang_code) => {
        let countries_name_in_reverse_order = [constant.LANG_CODE_JAPAN, constant.LANG_CODE_KOREA, constant.LANG_CODE_CHINA, constant.LANG_CODE_CHINA_SIMPLE];
        if (countries_name_in_reverse_order.includes(lang_code)) {
            return true;
        }

        return false;
    }

}

export default global_methods;




