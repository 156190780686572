<template>
  <ion-list class="question-content-area ion-no-padding" lines="none" style="padding-top: 0;">
    <ion-list-header class="check-list-header ion-no-padding">
      <div class="input">
        <div class="question-text" :id="'question-text'+questionIndex">
          <html-content :content="question.question" :allow-magnifying-image="true"/>
        </div>
      </div>
    </ion-list-header>

    <ion-item class="ion-no-margin answer-area">
      <div class="input question-option-list ">
        <ion-textarea v-model="state.user_answer" class="textarea" rows="5" cols="20" auto-grow="true"
                      :spellcheck="spellcheck" :disabled="disabled" 
                      @ionBlur="updateAnswer" 
                      aria-multiline="true"
                      @ionChange="updateAnswer"
                      ref="ion_text_area_ref"
                      @dragover="preventPasteOrDrag"
                      @dragenter="preventPasteOrDrag"
                      @paste="preventPasteOrDrag"
                      @dragend="preventPasteOrDrag" 
                      @drop="preventPasteOrDrag" 
                      >
      </ion-textarea>
      </div>
    </ion-item>


    <ion-grid class="counter-area">
      <ion-row>
        <div class="counter-desc" @click="toggleCounter()" @click.stop>
          <a>{{ state.language_set.show_word_count }}</a>

          <ion-icon id="arrow-icon" aria-hidden="true"
                    :src="require('../../../assets/icons/arrowDown.svg')"></ion-icon>

        </div>
      </ion-row>
      <transition name="slide-down">
        <ion-row class="word-count" v-if="state.show_word_counter">
          {{ state.language_set.words }}: {{ wordCount }}
        </ion-row>
      </transition>

    </ion-grid>


  </ion-list>
</template>

<script>
import {IonGrid, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonRow, IonTextarea} from '@ionic/vue';
import {computed, defineComponent, onMounted, onUnmounted, reactive, ref} from 'vue';
import stringUtils from '../../../utils/stringUtils';
import HtmlContent from "../../html/HtmlContent";
import store from "@/store";

export default defineComponent({
  name: 'TextArea',
  components: {
    IonTextarea,
    IonItem,
    IonLabel,
    IonListHeader,
    IonList,
    IonIcon,
    IonRow,
    IonGrid,
    HtmlContent
  },
  props: {
    question: Object,
    userAnswer: {
      type: String,
      default: () => {
        return '';
      }
    },
    spellcheck: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    disabled: {
      type: Boolean
    },
    questionIndex: {
      type: Number
    }
  },
  setup(props, ctx) {
    const state = reactive({
      show_word_counter: false,
      user_answer: props.userAnswer,
      language_set: store.getters.languageSet

    });

    const test_settings = store.getters.testSettings;


    function updateAnswer() {
      ctx.emit('update:userAnswers', state.user_answer);
    }

    const {countWord} = stringUtils();

    const preventPasteOrDrag = (event) => {
      if (!test_settings.text_paste_allowed) {
        event.preventDefault();
        return false;
      }
      return true;
    }


    const wordCount = computed(() => {
      return countWord(state.user_answer);
    });


    const wordCounterClass = computed(() => {

      if (state.show_word_counter) {
        return "";
      } else {
        return "ion-hide";
      }
    });

    /**
     * Toggle Counter
     * - Display/hide the word count
     * - add animation to the button : Assigning the class to the IonIcon disappear icon. Had to do with JS
     */
    function toggleCounter() {
      state.show_word_counter = !state.show_word_counter
      let icon = document.querySelector('#arrow-icon');
      if (state.show_word_counter) {
        icon.style.transform = 'rotate(180deg)';
        icon.style.transition = 'transform .3s ease';
      } else {
        icon.style.transform = 'rotate(0deg)';
        icon.style.transition = 'transform .3s ease';
      }
    }

    const ion_text_area_ref = ref()
    let interval_id = null;
    onMounted(() => {
      let interval_id = setInterval(() => {
        if (ion_text_area_ref.value != null && ion_text_area_ref.value.$el) {
          const dom = ion_text_area_ref.value.$el
          const text_area_dom = dom.querySelector('textarea');

          if (text_area_dom != null) {
            let label_ref = text_area_dom.getAttribute('aria-labelledby');
            if (label_ref != null && label_ref.includes('ion-textarea')) {
              clearInterval(interval_id);
              interval_id = null;
              text_area_dom.setAttribute('aria-labelledby', 'question-text' + props.questionIndex);
            }
          }
        }

      }, 100);
    })
    onUnmounted(() => {
      if (interval_id != null) {
        clearInterval(interval_id);
      }
    })

    return {state, updateAnswer, wordCount, wordCounterClass, toggleCounter, ion_text_area_ref, preventPasteOrDrag};
  },


})
;
</script>

<style lang="scss" scoped>
@import '../../../theme/classmarker_theme';

.textarea {
  margin-top: 0px;
}

.answer-area {
  margin-top: $between-space;
}

.counter-area {
  align-items: center;
  margin-top: $between-space;
  margin-left: $medium-space;
  margin-right: $medium-space;
  letter-spacing: $letter-spacing;
  font-family: $default-font-family;

  & .counter-desc {
    color: $color-primary;
    cursor: pointer;


    & ion-icon {
      font-size: $very-small-font-size;
      margin-left: $small-space;
    }
  }

  & .word-count {
    padding-top: $between-space;
  }
}
</style>