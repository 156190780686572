import store from '@/store';
import constant from "../constant";

export default class RouteHandler {
    constructor(router) {
        this.router = router;
    }


    getNextRouteName(test_state) {
        let routeName = 'Login';
        switch (test_state) {
            case 'login':
                routeName = 'Start';
                break;
            case 'access_code':
                routeName = 'AccessCode';
                break;
            case 'user_info':
                routeName = 'UserInfo';
                break;
            case 'payment':
                routeName = 'Payment';
                break;
            case 'test_intro':
                routeName = 'TestIntro';
                break;
            case 'test_in_progress':
                routeName = 'Test';
                break;
            case 'test_complete':
                routeName = 'Result';
                break;
            case 'logout':
                routeName = 'Logout';
                break;
            case 'error':
                routeName = 'Error';
                break;
        }
        return routeName;
    }

    getPreviousRouteName(state) {
        let login_setting = store.state.Test.settings.login
        let routeName = this.getNextRouteName(state);

        if (state === 'test_intro') {
            if (login_setting['email_required'] || login_setting['first_name_required'] || login_setting['last_name_required']
                || login_setting['user_extra_info_required']
            ) {
                routeName = 'UserInfo'
            }
        }
        return routeName;
    }

    route() {
        let nextRouteName = this.getNextRouteName(store.state.Test.test_state);

        // quiz_id should be in the URL all the time
        this.__route(nextRouteName);
    }


    goBack() {
        let current_state = store.state.Test.test_state;
        let nextRouteName = this.getPreviousRouteName(current_state);

        this.__route(nextRouteName);
    }

    __route(nextRouteName) {
        let quiz_id = store.getters.quizId;
        let group_type = store.getters.groupType;
        let preview = this.router.currentRoute.value.query.preview ?? null;
        let query = {quiz: quiz_id}

        if (preview !== null && preview == 1) {
            query.preview = 1;
        }

        
        if (group_type == constant.GROUP_TYPE_LINK) {
            this.router.replace({name: nextRouteName, query: query});

        } else {
            query.type = 'rg';
            this.router.replace({name: nextRouteName, query: query});
        }
    }


}


