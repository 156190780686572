/**********  Cookie function - need to move to the utility file later *******************************/
function setCookie(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;Secure;";
}


function updateCookieBase64(cname, cvalue){

    let encoded_value = btoa(cvalue);
    setCookie(cname, encoded_value, 1);

}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return undefined;
}

function setCookieBase64(cname, cvalue, expdays) {
    let encoded_value = btoa(cvalue);
    setCookie(cname, encoded_value, expdays);
}

function getCookieBase64(cname) {
    let encoded_value = getCookie(cname);

    if (encoded_value != undefined) {
        return atob(encoded_value);
    }
    return undefined;
}

function incrementBase64(cname) {
    let value = getCookieBase64(cname);
    if (value !== undefined) {

        value = parseInt(value) + 1;
        updateCookieBase64(cname, value);
    }
}

function getCookieExpTime(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);

    var ca = decodedCookie.split(';');

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return undefined;
}

function remove(cname) {
    document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function setExpireTime(cookie_name, expires_in_minutes) {
    let cookie = getCookie(cookie_name);
    let date = new Date();
    date.setTime(date.getTime() + expires_in_minutes * 60 * 1000);
    let expires = "expires=" + date.toUTCString();
    document.cookie = cookie_name + "=" + cookie + ";" + expires + ";path=/";
    getCookieExpTime();
}

export default {setCookie, getCookie, remove, setExpireTime, setCookieBase64, getCookieBase64, incrementBase64};
