<template mode="md">
  <ion-grid style="width:100%">
    <ion-row style="padding-bottom:2px;">
      <ion-list class="radio-list ion-no-padding">

        <ion-list-header class="radio-list-header input ion-no-padding">
          <ion-label class="ion-text-wrap label default-font overflow-x-auto" v-bind:class="{'required':mustAnswer, 'error':error}">
            <label :for="'extra-info-'+id">
              <html-content :content="extraInfoQuestion.question" :allow-magnifying-image="true"></html-content>
            </label>
          </ion-label>

        </ion-list-header>

        <select :id="'extra-info-'+id" class="select" :class="displayErrorClass" @change="updateAnswer($event)"
                :value="state.selected_answer">

          <option class="radio-options-area" v-for="(answer, index) in extraInfoQuestion.answers" :key="index"
                  :selected="isSelected(answer, state.selected_answer)" @change="updateAnswer(answer)">
            {{ answer }}
          </option>

        </select>
        
      </ion-list>
    </ion-row>

    <ion-row>
      <div class="form_error">{{ error }}</div>
    </ion-row>
  </ion-grid>

</template>

<script>
import {IonGrid, IonItem, IonLabel, IonList, IonListHeader, IonRadio, IonRadioGroup, IonRow} from '@ionic/vue';
import {computed, reactive} from "vue";
import HtmlContent from "../html/HtmlContent";
import UpdateAnswerMessageEmitter from "./UpdateAnswerMessageEmitter";
import store from '@/store';

export default {
  name: "ExtraQuestionDropDown",
  components: {
    IonLabel,
    IonItem,
    IonRadio,
    IonRadioGroup,
    IonList,
    IonListHeader,
    IonGrid,
    IonRow,
    HtmlContent
  },
  emits: ['answer_change'],
  props: {
    extraInfoQuestion: Object,
    id: Number,
    mustAnswer: {
      type: Boolean
    },
    error: {
      type: String,
      required: false,
      default: null,
    }
  },
  setup(props, ctx) {
    const state = reactive({
      selected_answer: props.extraInfoQuestion.user_answer
    });
    const language_set = store.getters.languageSet;
    const {emitAnswers} = UpdateAnswerMessageEmitter(ctx);

    function updateAnswer(event) {
      state.selected_answer = event.target.value;
      if (state.selected_answer == '') {
        state.selected_answer = null;
      }
      // use composition api to setup answer message emitter
      emitAnswers(props.id, state.selected_answer);
    }

    const displayErrorClass = computed(() => {
      if (props.error) {
        return 'error';
      }

    })

    function isSelected(answer) {
      if (answer == state.selected_answer) {
        return true;
      } else {
        return false;
      }
    }


    return {
      state, updateAnswer, displayErrorClass, isSelected, language_set
    };
  },
};
</script>

<style lang="scss" scoped>
/*@import '@/theme/classmarker_theme';*/

ion-list {
  width: 100%;
}


.radio-list-header {
  min-height: 22px !important;
}

.radio-list ion-item {
  --min-height: 26px;
  margin-bottom: 0px !important;
}


/**
Drop down styling
 */
select {
  width: 250px;
  color: $color-gray-primary;
  padding-left: 4px;


  &.error {
    border: solid 2px $color-red !important;
    //color: $color-red !important;
  }
}

/**
Ensure items in ion-label's are clickable
 */
ion-label {
  pointer-events: all !important;
}

.label{
  max-width: 100%;
}
</style>