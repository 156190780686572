import axios from 'axios';
import constant from "../constant";

const API_DOMAIN = process.env.VUE_APP_API_DOMAIN


function get(token, path) {
    return axios.get(API_DOMAIN + path, {headers: getCommonHeader(token)});
}

function post(token, path, data) {
    return axios.post(API_DOMAIN + path, data, {headers: getCommonHeader(token)});
}

function put(token, path, data) {
    return axios.put(API_DOMAIN + path, data, {headers: getCommonHeader(token)});
}


function getCommonHeader(token) {
    let headers = {
        'Content-type': 'application/json',
    }
    if (token !== undefined || token !== '') {
        headers['Authorization'] = 'bearer ' + token;
    }
    return headers;
}

/**
 * Grant Auth function - this function return a access token
 * @param token
 * @returns {Promise<*>}
 */
function grantAuth(quiz_id, token, payload) {
    let request_path = '/auth';

    if (payload.group_type == constant.GROUP_TYPE_GROUP) {
        request_path = request_path + '?test_id=' + quiz_id + '&type=rg';

    } else {
        request_path = request_path + '?quiz=' + quiz_id
    }

    if (payload.is_test_preview == 1) {
        request_path = request_path + '&preview=1';
    }
    return post(token, request_path)
        .then((response) => {
            // when encounter cloudFlare challenge page response.

                let data = response.data;
                let token = data['cm_token'];
                return token;

        })
        .catch((error)=>{
            if(error.response.headers.hasOwnProperty('cf-mitigated') && error.response.headers['cf-mitigated'] === 'challenge'){
                window.location.reload();
            }else{
                throw error;
            }

        })
}

export default {get, post, put, grantAuth};
