<template>
  <ion-list class="checkbox-list question-content-area" lines="none" style="padding-top: 0;">
    <ion-list-header class="ion-no-padding">
      <div class="input">
        <div class="question-text">
          <html-content :content="question.question" :allow-magnifying-image="true"/>
        </div>
      </div>
    </ion-list-header>

    <div id="option-area-wrapper" :class="{'empty_answer':isEmptyUserAnswer()}" ref="options_area_ref">
      <ion-item v-for="(option,index) in question.options" :key="index" class="ion-item-option">
        <div class="checkbox-options-area question-text">
          <ion-checkbox mode="md" color="secondary" v-bind:value="option.id"
                        :checked="isSelcted(option.id, userAnswer)"
                        disabled="true"
          ></ion-checkbox>
          <ion-label class="ion-text-wrap">
            <div class="question-option">
              <div class="question-index" :class="isReverseClass"> {{ convertIntToAlphabetIndex(index, state.language_set) }}.</div>
              <div class="question-content with-index-text overflow-x-auto">
                <html-content :content="option.content" :allow-magnifying-image="true"  data-cy="question-option-text"/>
              </div>
            </div>


          </ion-label>
          <ion-icon class="icon icon-correct"
                    v-bind:class="getCorrectWrongClass(option.id, userAnswer, feedback)"
                    :aria-label="$getCorrectAriaText(getCorrectWrongClass(option.id, userAnswer, feedback))"
                    :aria-hidden="$getCorrectAriaText(getCorrectWrongClass(option.id, userAnswer, feedback))=='' ? true : false"/>
        </div>
      </ion-item>
    </div>
  </ion-list>
  <banner id="info-banner" :bannerType="error.type" :message="error.message"
          v-if="!feedback.full_mark"
  ></banner>
</template>

<script>
import {IonCheckbox, IonIcon, IonItem, IonLabel, IonList, IonListHeader} from '@ionic/vue';
import {computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref} from 'vue';
import stringUtils from '../../../utils/stringUtils';
import HtmlContent from "../../html/HtmlContent";
import Banner from "../../html/Banner";
import store from "@/store";

export default defineComponent({
  name: 'MultipleResponse',
  components: {
    IonList,
    IonListHeader,
    IonCheckbox,
    IonItem,
    IonLabel,
    HtmlContent,
    Banner,
    IonIcon,
  },
  props: {
    question: Object,
    userAnswer: Array,
    feedback: Object
  },
  setup(props, ctx) {
    const state = reactive({
      language_set: store.getters.languageSet
    });


    const {convertIntToAlphabetIndex} = stringUtils();

    const options_area_ref = ref();
    // initialise aria attributes
    let option_interval_id;
    onMounted(() => {
      option_interval_id = setInterval(() => {
        if (options_area_ref.value != null) {
          let option_area_dom = options_area_ref.value;
          let ion_labels = option_area_dom.querySelectorAll('ion-label');
          if (ion_labels.length > 0) {

            let hidden = ion_labels[0].getAttribute('aria-hidden');
            if (hidden != null && hidden == "true") {

              ion_labels.forEach((ion_label) => {
                clearInterval(option_interval_id);
                ion_label.setAttribute('aria-hidden', 'false');
              })
            }

          }
        }
      }, 100)
    })
    onBeforeUnmount(() => {
      clearInterval(option_interval_id);
    })

    /**
     * Check if user select the option
     * @param value
     * @param userAnswer
     * @returns {boolean}
     */
    function isSelcted(value, userAnswer) {
      if (userAnswer == null) {
        return false;
      }

      let index = userAnswer.indexOf(value);
      if (index >= 0) {
        return true;
      } else {
        return false;
      }
    }

    function getCorrectWrongClass(value, userAnswer, feedback, check_reverse = true) {
      if (userAnswer != null) {
        let selected_answer = userAnswer.indexOf(value) >= 0;
        let correct_answer = feedback.correct_answer.indexOf(value) >= 0;
        let icon = '';
        if (selected_answer) {
          if (correct_answer) {
            icon = 'circular-tick';
          } else {
            icon = 'circular-x';
          }
        } else {
          if (correct_answer) {
            icon = 'circular-tick-holo';
          }
        }

        if (check_reverse) {
          let reverse_text = store.getters.reverseText;
          if (reverse_text) {
            icon += ' rtl';
          }
        }
        return icon;

      }
    }

    const correctAnswerLength = computed(() => {
      let length = 0;
      if (props.feedback != null) {
        length = Object.keys(props.feedback.correct_answer).length;
      }
      return length;
    })

    function isEmptyUserAnswer() {
      if (props.userAnswer == null || props.userAnswer.length == 0) {
        return true;
      }
      return false;
    }

    const error = computed(() => {
      let data = {
        message: '',
        type: '',
      }

      if (isEmptyUserAnswer()) {
        data['message'] = state.language_set.no_answer_given;
        data['type'] = 'warning';
      } else {
        data['message'] = state.language_set.quiz_missed_some_answers;
        data['type'] = 'info';
      }

      return data;
    })

    let reverseText = store.getters.reverseText;
    const isReverseClass = computed(() => {
      if (reverseText) {
        return 'rtl'
      }
    })

    return {
      state,
      convertIntToAlphabetIndex,
      isSelcted,
      getCorrectWrongClass,
      correctAnswerLength,
      error,
      isEmptyUserAnswer,
      options_area_ref,
      isReverseClass
    };
  },


});
</script>

<style lang="scss" scoped>
@import '../../../theme/classmarker_theme';

/**
Overwrite Default CSS opacity 0.3 to 1 to show answers properly
 */
.item-interactive-disabled.sc-ion-label-md-h:not(.item-multiple-inputs), .item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-md-h {
  /* cursor: default; */
  opacity: 1;
  /* pointer-events: none; */
}

.item-interactive-disabled.sc-ion-label-ios-h:not(.item-multiple-inputs), .item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-ios-h {
  opacity: 1;
}

/**** */
#option-area-wrapper {
  width: 92%;
  margin-left: auto !important;
  margin-right: auto !important;


  ion-item {
    margin-top: 7px;
    margin-bottom: 0px !important;
    --min-height: $input-feedback-height;
    min-height: $input-feedback-height;

    &:first-of-type {
      margin-top: $medium-space !important;
    }

    &.item-checkbox-checked {
      --background: #F9FAFB;
      border-radius: 5px;
    }

    .checkbox-options-area {
      width: 100%;
      margin-right: 0px;
      margin-top: 0px !important;
      min-height: $input-feedback-height;
      padding-left:0px;

      ion-checkbox {
        margin-top: 11px;
        --background-checked: #BEC8D1;
        --border-color-checked: #BEC8D1;
        margin-left: 10px;
      }

      ion-label {
        margin-top: 11px;
      }
    }

  }


  &.empty_answer {
    border-left: $color-red 2px solid;
  }

}


.icon-correct.rtl {
  right: calc(100% - 57px) !important;
}

.question-content {

  &.with-index-text {
    margin-left: 20px;
  }
}


/**
Ensure items in ion-label's are clickable
 */
ion-label{
  pointer-events: all!important;
}


</style>