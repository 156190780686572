<template>
  <ion-row class="banner" v-bind:class="bannerType">
    <div class="banner--icon" v-if="displayIcon" :style="iconStyle" aria-hidden="true">
      <ion-icon class="banner-icon icon" v-bind:class="bannerType"></ion-icon>
    </div>
    <ion-col class="banner--description">
      <html-content :content="message" :bbcode="false">

      </html-content>
    </ion-col>
  </ion-row>
</template>

<script>
import {IonCol, IonIcon, IonRow} from '@ionic/vue';
import {defineComponent, reactive} from 'vue';
import HtmlContent from "./HtmlContent";


export default defineComponent({
  name: 'Banner',
  components: {
    IonIcon,
    IonCol,
    IonRow,
    HtmlContent
  },
  props: {
    message: {type: String, required: true},
    bannerType: {type: String, required: true},// value: info, alert, warning
    displayIcon: {
      type: Boolean, default: () => {
        return true;
      }
    },
    iconStyle: {type: Object, required: false},
  },


  setup(props, ctx) {
    const state = reactive({});
    return {state};
  },


});
</script>

<style lang="scss" scoped>
/*@import '@/theme/classmarker_theme';*/

ion-col {
  padding-left: 0px;
  padding-right: 0px;
}

ion-row {
  padding-left: 0px !important;

}

.banner {
  padding: $small-space;
  align-items: center;
  position: relative;
  min-height: 40px;

  &.info {
    background-color: $color-sky-blue-bg;
    border-left: 3px solid $color-sky-blue;
  }

  &.warning {
    background-color: $color-yellow-bg;
    border-left: 3px solid $color-yellow;
  }

  &.alert {
    background-color: $color-red-bg;
    border-left: 3px solid $color-red;
  }


  &--icon {
    font-size: $font-size-icon;
    margin-left: 16px;
    margin-right: 16px;
    position: absolute;
    left: 0px;
    top: 10px;
  }

  &--description {
    font-family: $default-font-family;
    font-size: $default-font-size;
    color: $color-gray-primary;
    margin-left: 48px;
  }
}


</style>