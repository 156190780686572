<script>

export default function (ctx) {

  function emitAnswers(question_id, value, message_name = null) {
    if (message_name == null) {
      message_name = 'answer_change';
    }
    ctx.emit(message_name, {'question_id': question_id, 'value': value});
  }

  return {
    emitAnswers
  }
};

</script>
