<template>
  <div class="item bookmark-item" :data-bookmark-item="index" v-for="(content, index) in contents" :key="content.id"
       v-bind:class="setupAppDefaultCssClasses()">
    <div class="bookmark-content">
      <div class="bookmark-content__left" @click="toggleContent(index)" v-bind:class="isReverseClass">
        <ion-icon class="icon" aria-hidden="true"
                  v-bind:class="getBookmarkIconClass(content.bookmarked)"
        />

        <rotatable-arrow-down :rotate="content.opened" :index="index"
                              :shadow-box="false"
                              :aria-label="'Toggle Question '+content.index+ ' text'"
                              data-cy="toggle-question"
                              class="rotatable-arrow"></rotatable-arrow-down>

        <span
            :aria-label="languageSet['question'] +' '+ content.index +' '+
                (content.answered?languageSet['link_question_filter_answered']:languageSet['link_question_filter_unanswered'])">
          <span aria-hidden="true">{{ languageSet['question'] }} {{ content.index }}</span>
          <span v-if="content.answered" class="bookmark-content__left__answered" aria-hidden="true">
              {{ languageSet['link_question_filter_answered'] }}
          </span>

          <span v-else class="bookmark-content__left__unanswered" aria-hidden="true">
            {{ languageSet['link_question_filter_unanswered'] }}
          </span>
        </span>

      </div>
      <div class="bookmark-content__right" v-bind:class="isReverseClass">
        <ion-button fill="clear " class="icon-button icon arrow-circle-right"
                    :aria-label="'Jump to question '+content.index+ ' text'"
                    data-cy="jump-to-question"
                    @click="jumpToPage(content.section_id, content.page, content.question_id)"/>
      </div>
    </div>
    <transition name="slide-down" :aria-labelledby="'bookmark-'+index+'-text'">
      <div class="question" v-if="content.opened" :id="'bookmark-'+index+'-text'">
        <html-content :content="content.question" data-cy="question-text"></html-content>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar
} from '@ionic/vue';
import {defineComponent} from 'vue';
import HtmlContent from "../html/HtmlContent";
import htmlSetupUtil from "../../utils/htmlSetupUtil";
import store from '@/store';
import {computed} from "@vue/reactivity";
import RotatableArrowDown from "../icons/RotatableArrowDown";

export default defineComponent({
  name: 'BookmarkList',
  components: {
    RotatableArrowDown,
    HtmlContent,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonPage,
    IonButton,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonSegment,
    IonSegmentButton
  },
  props: {
    contents: Array,
  },
  emits: ['jump-to-page'],
  setup(props, ctx) {
    let languageSet = store.getters.languageSet;
    let reverseText = store.getters.reverseText;

    /**
     * Toggle question content
     */
    function toggleContent(index) {
      props.contents[index].opened = !props.contents[index].opened;
    }

    function getBookmarkIconClass(bookmarked) {
      if (bookmarked) {
        return 'bookmark-selected';
      } else {
        return 'bookmark';
      }
    }

    function jumpToPage(section_index, page_num, question_id) {
      let data = {
        section_index: parseInt(section_index),
        page_num: parseInt(page_num),
        question_id: question_id
      }
      ctx.emit('jump-to-page', data);
    }

    const isReverseClass = computed(() => {
      if (reverseText) {
        return 'rtl'
      }
    })

    const {setupAppDefaultCssClasses} = htmlSetupUtil();


    return {toggleContent, getBookmarkIconClass, jumpToPage, setupAppDefaultCssClasses, isReverseClass, languageSet}
  }
});
</script>

<style lang="scss" scoped>
@import '../../theme/classmarker_theme';

/**
BookMark Icons
 */
.icon {
  visibility: visible !important;
}

.bookmark {
  width: 16px;
  height: 16px;
  margin-left: $between-space;
  font-size: $font-size-icon;

  top: 2px;
  position: relative;
}

.bookmark-selected {
  width: 16px;
  height: 16px;
  margin-left: $between-space;
  font-size: $font-size-icon;

  top: 2px;
  position: relative;
}

/**
Content List Item
 */

.item {
  background-color: var(--color-white);
  font-size: $default-font-size;
  font-family: $default-font-family;
  color: $text-color-primary;

  min-height: 56px;
  padding-top: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid $color-card-border;
}

.bookmark-content {
  height: calc(56px - 36px);
  padding: 0px 10px 10px 0px;
  color: $text-color-primary;

  &__left {
    float: left;
    cursor: pointer;
    width: calc(100% - 50px);
    color: $text-color-primary;

    &.rtl {
      float: right !important;
    }

    .arrow-down {
      height: 10px;
      width: 10px;
      background-size: 10px;
      position: relative;
      bottom: 5px;
    }

    &__answered {
      font-size: 10px;
      margin-left: $small-space;
      padding: 3px 8px;

      /* Grey 01 - BG */

      background: #F9FAFB;
      border-radius: 100px;
      color: $color-gray-secondary;
    }

    &__unanswered {
      font-size: 10px;
      margin-left: $small-space;
      padding: 3px 8px;
      /* Error BG */

      background: #FFF2F2;
      border-radius: 100px;
      color: $color-red;
    }

    .icon.rtl {
      left: 32px;
      right: 0px;
    }
  }

  &__right {
    width: 25px;
    cursor: pointer;
    float: right;

    &.rtl {
      float: left !important;;
    }

    .arrow-circle-right {
      position: relative;
      margin-right: 18px;
      width: 18.75px;
      height: 18.75px;
      background-size: 18.75px;
      bottom: 2px;
    }
  }
}
.rotatable-arrow{
  position: relative; bottom:5px;margin-left:12px; margin-right:8px;
}
.question {
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 19px;
  width: 78%;
}

</style>