import constant from "../../constant";
import bbCode from "../../utils/xbbcode";

export default class ThemeData {

    constructor(data) {
        // Header properties

        this.header_bg_color = this.__getValue(data.header_bg_color ?? null, this.__getCssVariable('--color-white'));
        this.__setHeaderContentAlign(data.header_content_align ?? null);


        this.header_img = this.__getValue(data.header_img ?? null, null);
        this.header_text_color = this.__getValue(data.header_text_color ?? null, this.__getCssVariable('--color-gray-primary'));
        this.header_width = this._setHeaderWidth(data.header_width ?? null);


        // Body properties
        this.body_bg_color = this.__getValue(data.body_bg_color ?? null, this.__getCssVariable('--color-white'));
        this.body_bg_img_url = this._setBodyImageURL(data.body_bg_img ?? null);
        this.body_bg_img_repeat = this.__getValue(data.body_bg_img_repeat ?? null, null);
        this.body_content_align = this.__setBodyContentAlign(data.body_content_align ?? 'auto');
        this.display_title = this.__getValue(data.display_title ?? null, null);
        this.is_custom_theme = data.is_custom_theme ?? false;
        this.test_title = this.__getValue(data.test_title ?? null, null);
        this.timer_bar_color = this.__getValue(data.timer_bar_color ?? null, this.__getCssVariable('--ion-color-primary'));
        this.certificate_button_color = this.__getValue(data.certificate_button_color ?? null, this.__getCssVariable('--ion-color-primary'));
        this.default_button_color = this.__getValue(data.default_button_color ?? null, this.__getCssVariable('--ion-color-primary'));
        this.finish_button_color = this.__getValue(data.finish_button_color ?? null, this.__getCssVariable('--color-green'));
        this.return_button_color = this.__getValue(data.return_button_color ?? null, this.__getCssVariable('--ion-color-primary'));
        this.see_all_question_button_color = this.__getValue(data.default_button_color ?? null, this.__getCssVariable('--ion-color-primary'));
        this.display_cm_powered_by = data.display_cm_powered_by ?? false;
        this.display_cm_free_trail_banner = data.display_cm_free_trail_banner ?? false;
        this.display_cm_free_trail_banner = data.display_cm_free_trail_banner ?? false;
        this.show_ads = data.display_ads ?? false;
    }

    __getValue(value, default_value) {
        if (value == null || value == '') {
            return default_value;
        }
        return value;
    }

    __getCssVariable(name) {
        return getComputedStyle(document.documentElement)
            .getPropertyValue(name);
    }

    __setBodyContentAlign(value) {

        if (value == constant.CSS_ALIGN_LEFT) {
            return '0px';
        }
        return 'auto';
    }

    __setHeaderContentAlign(value) {
        if (value == constant.CSS_ALIGN_LEFT) {
            this.header_content_align = '0px';
            this.header_text_align = 'left'
        } else {
            this.header_content_align = 'auto';
            this.header_text_align = 'center';
        }
    }

    _setHeaderWidth(value) {
        if (value == constant.FULL_WIDTH) {
            return '100%';
        }
        return this.__getCssVariable('--width-max-template')
    }

    _setBodyImageURL(value) {
        if (value != null && value != '') {
            if (value.includes('[cmimg]') || value.includes('[img]')) {
                {
                    let img_src = bbCode(value);
                    let doc = new DOMParser().parseFromString(img_src, "text/xml");
                    let img_dom = (doc.querySelector('img'));
                    let img_url = img_dom.getAttribute('src');
                    return img_url;
                }
            } else {
                // case of http(s) url of image
                return value;
            }
        }
        return null;
    }
}
