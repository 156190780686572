<script>

export default function (ctx) {

  function emitAnswers(question_id, value) {
    ctx.emit('answer_change', {'question_id': question_id, 'value': value});
  }

  return {
    emitAnswers
  }

};

</script>
