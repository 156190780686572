<template>
  <div class="reset">
    <ion-grid class="ion-no-padding">
      <ion-row class="reset__header">
        <ion-col>
          <h1 class="reset__header--title">
            {{ state.language_set.send_reset_password_link }}
          </h1>
        </ion-col>
        <ion-col class="reset__header--icon">
          <ion-icon class="icon close-button" @click="dismiss()"></ion-icon>
        </ion-col>
      </ion-row>
      <ion-row class="reset__description">
        {{ state.language_set.ex_send_password_instructions_1 }}<br/>
        {{ state.language_set.ex_send_password_instructions_2 }}

      </ion-row>
      <ion-row class="reset__input">
        <ion-col>
          <ion-item lines="none">
            <div class="input reset__input--content">
              <ion-label class="ion-text-wrap label" :class="{'error':state.error}">{{
                  state.language_set.ex_login_email_address
                }}
              </ion-label>
              <ion-input placeholder="Email address" v-model="state.email" :class="{'error':state.error}"
                         @ion-change="resetError()" type="email" mode="md" disabled></ion-input>
            </div>
          </ion-item>
        </ion-col>
      </ion-row>


      <banner v-if="state.error" :message="state.error" banner-type="warning"></banner>

      <ion-row class="reset__button">
        <ion-button class="button" fill="solid" @click="sendResetEmail" :disabled="state.disable_button">
          {{ state.language_set.send_reset_password_link }}
        </ion-button>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import {IonButton, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRow} from '@ionic/vue';
import {defineComponent, reactive} from "vue";
import store from "@/store";
import Banner from "../html/Banner";
import validateUtil from "../../utils/validateUtil";
import constant from "../../constant";

export default defineComponent({
  name: "ResetPassword",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonButton,
    IonIcon,
    IonLabel,
    IonInput,
    Banner
  },
  props: {
    email: String
  },
  setup(props, ctx) {
    const state = reactive({
      language_set: store.getters.languageSet,
      email: props.email ?? null,
      error: null,
      disable_button: false,
    });

    const {validateEmail} = new validateUtil()

    async function sendResetEmail() {
      state.disable_button = true;
      let valid_email = validateEmail(state.email);
      if (valid_email !== true) {
        state.error = state.language_set[valid_email];
        state.disable_button = false;
        return;
      }

      try {
        let result = await store.dispatch('User/forgetPassword', {email: state.email})
        if (result.result == 'success') {
          ctx.emit('reset_email_sent');
        }
        dismiss();
      } catch (error) {
        if (store.state.Error.error.error_code == constant.ERROR_NO_INTERNET) {
          ctx.emit('no-internet');
        } else {
          state.error = error.response.data.error_message;
        }
      } finally {
        state.disable_button = false;
      }
    }

    function dismiss() {
      ctx.emit('dismiss-modal');
    }

    function resetError() {
      if (state.error != null) {
        state.error = null;
      }
    }


    return {
      state,
      sendResetEmail,
      dismiss,
      resetError
    };
  },
});
</script>

<style lang="scss" scoped>
.item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-md-h {
  opacity: 1;
}
.reset {
  background-color: white;
  margin-bottom: $medium-space;


  & ion-grid ion-row:not(:first-child) {
    margin-left: $large-space;
    margin-right: $large-space;
  }

  &__header {
    padding: $medium-space $large-space;

    border-bottom: 1px solid $color-gray-02;

    & ion-col {
      padding: 0;
    }

    &--icon {
      text-align: end;
      cursor: pointer;

      & ion-icon {
        font-size: $font-size-icon;
        color: $color-gray-secondary;
      }
    }

    &--title {
      font-family: $default-font-family;
      font-size: $medium-font-size;
      font-weight: bold;
      line-height: 22px;
      width: 100%;
    }
  }

  &__description {
    font-size: $default-font-size;
    font-family: $default-font-family;
    letter-spacing: $letter-spacing;
    line-height: 22px;
    margin-top: $medium-space;
    margin-bottom: $medium-space;
  }

  &__input {
    margin-bottom: calc(#{$large-space} / 2);

    &--content {
      width: 100%;

      & > ion-input {
        width: 100%;
      }
    }
  }

  &__warning {
    background-color: rgba($color-tertiary, .05);;
    border-left: 3px solid $color-tertiary;

    padding: $small-space;
    align-items: center;

    &--icon {
      font-size: $font-size-icon;
    }

    &--description {
      font-family: $default-font-family;
      font-size: $default-font-size;
    }
  }

  &__button {
    margin-top: calc(#{$large-space} / 2);
  }
}

</style>
