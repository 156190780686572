export const AuthModule = {
    namespaced: true,

    state: {
        token: null
    },


    // Mutations are functions that effect the state. Never called by developer, it is called by actions function
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
        CLEAR_TOKEN(state){
            state.token = null;
        }
    },


    // Actions are functions that you call through your application that call mutations.
    actions: {
        setToken({ commit }, token) {
            commit('SET_TOKEN', token);
        },
        clearToken({commit}){
            commit('CLEAR_TOKEN');
        },
        setTokenFromErrorResponse({commit}, error){
            let data = error.response.data;
            if(data.hasOwnProperty('token')){
                commit('SET_TOKEN', data['token']);
            }
        }
    },

    // Modulising stores - spreading stores into different files
    modules: {

    }
}