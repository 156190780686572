<template>
  <ion-list class="question-content-area ion-no-padding" lines="none" style="padding-top: 0;">
    <ion-list-header class="check-list-header ion-no-padding">
      <div class="input">
        <div class="question-text" :id="'question-text'+questionIndex">
          <html-content :content="question.question" :allow-magnifying-image="true"/>
        </div>
      </div>
    </ion-list-header>

    <ion-item class="ion-no-margin answer-area">
      <div class="input question-option-list">
        <ion-input type="text" v-model="state.user_answer" @ionBlur="updateAnswer" @ionChange="updateAnswer"
                   :spellcheck="spellcheck"
                   :disabled="disabled" 
                   :aria-labelledby="'question-text'+questionIndex"
                   ></ion-input>
      </div>
    </ion-item>
  </ion-list>
</template>

<script>
import {IonInput, IonItem, IonLabel, IonList, IonListHeader} from '@ionic/vue';
import {defineComponent, reactive} from 'vue';
import HtmlContent from "../../html/HtmlContent";

export default defineComponent({
  name: 'TextBox',
  components: {
    IonInput,
    IonItem,
    IonLabel,
    IonListHeader,
    IonList,
    HtmlContent
  },
  props: {
    question: Object,
    userAnswer: String,
    spellcheck: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    disabled: {
      type: Boolean
    },
    questionIndex: {
      type: Number
    }
  },
  setup(props, ctx) {
    const state = reactive({
      register: true,
      user_answer: props.userAnswer
    });


    function updateAnswer(event) {
      ctx.emit('update:userAnswers', state.user_answer);
    }

    return {state, updateAnswer};
  },


});
</script>

<style lang="scss" scoped>
@import '../../../theme/classmarker_theme';

.answer-area {
  margin-top: $between-space;
}

</style>