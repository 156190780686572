import {modalController} from "@ionic/vue";
import ImageModal from "../components/html/ImageModal";


function setup(img) {
    if (img.naturalHeight - img.clientHeight > 0 || img.naturalWidth - img.clientWidth > 0) {

        let url = img.getAttribute('src');
        img.classList.add("magnifying");

        img.parentElement.style.zIndex="99999";
        img.onclick = function () {
            __openModal(url);
        }
    }
}


async function __openModal(url) {
    const modal = await modalController.create({
        component: ImageModal,
        cssClass: 'image-magnifier-modal'
    });
    modal.componentProps = {
        url: url,
        obj: modal
    };
    return modal.present();
}


export default {setup}