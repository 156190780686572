<template>
  <ion-button class="icon-button" fill="clear" :class="{'no-shadow':(shadowBox==false)}" :aria-hidden="true">
    <ion-icon :id="'arrow-'+index" class="icon arrow-down"></ion-icon>
  </ion-button>
</template>

<script>
import {IonButton, IonIcon} from "@ionic/vue";
import {defineComponent, watch} from 'vue';

/**
 * Arrow Down Icon with rotation animation.
 * When rotate = true, it rotate to 180 down, false, rotate to 180 up
 * If the icon's colour need to change by font color, check component/question/TextArea.vue file.
 */
export default defineComponent({
  name: 'RotatableArrowDown',
  components: {
    IonIcon,
    IonButton
  },
  props: {
    rotate: {
      type: Boolean, default: () => {
        return false;
      }
    },
    index: {
      type: Number, default: () => {
        return 0;
      }
    },
    shadowBox: {
      type: Boolean, default: () => {
        return true;
      }
    }
  },

  setup(props, ctx) {

    function toggleIcon() {
      let icon = document.querySelector('#arrow-' + props.index);

      if (props.rotate) {
        icon.classList.add('rotate-down-180');
        icon.classList.remove('rotate-up-180');
      } else {
        icon.classList.remove('rotate-down-180');
        icon.classList.add('rotate-up-180');
      }
    }

    watch(() => props.rotate, (to, from) => {
      toggleIcon();
    })
    return {};
  },


});
</script>

<style lang="scss" scoped>
/*@import '@/theme/classmarker_theme';*/

.arrow-down {
  width: 11px;
  height: 11px;
  background-size: 11px;
}

.icon-button.no-shadow:hover {
  border-radius: initial;
  --border-color: initial;
  box-shadow: initial;
  background-color: initial;
}


</style>