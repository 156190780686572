<template>
  <ion-card class="test__card" :class="{error: displayError}"
            :aria-label="state.language_set.question+' '+question_index +' '+ state.language_set.of +' '+numOfQuestion ">
    <ion-card-header class="test__card--header">
      <div>
        <ion-card-title>{{ state.language_set.question }} {{ question_index }} {{ state.language_set.of }}
          {{ numOfQuestion }}
        </ion-card-title>
        <ion-card-subtitle mode="md">

          <span v-if="settings.display_categories" data-cy="question-category">{{ question.category }}</span>
        </ion-card-subtitle>
      </div>
      <ion-button v-if="settings.allow_click_previous" fill="clear" color="medium" class="test__card--bookmark"
                  v-on:click="toggleBookMark()"
                  mode="ios"
                  :aria-label="'Bookmark question '+question_index"
      >
        <ion-icon class="test__card--bookmark--icon" slot="icon-only" aria-hidden="true"
                  v-bind:class="activeBookMark"
                  :src="require('../../assets/icons/bookmark.svg')"/>

      </ion-button>
    </ion-card-header>

    <ion-card-content class="ion-no-padding" v-if="state.editable">
      <div v-if="question.sub_type == constant.QUESTION_MULTIPLE_CHOICE">
        <MultipleChoice :question="question" :user-answer="state.user_answer" :disabled="disabled"
                        @update:userAnswers="updateUserAnswer" :question-index="question_index"></MultipleChoice>
      </div>
      <div v-if="question.sub_type == constant.QUESTION_MULTIPLE_RESPONSE">
        <MultipleResponse :question="question" :user-answer="state.user_answer" :disabled="disabled"
                          :question-index="question_index"
                          @update:userAnswers="updateUserAnswer"></MultipleResponse>
      </div>
      <div v-if="question.sub_type == constant.QUESTION_FREE_TEXT">
        <TextBox :question="question" :user-answer="state.user_answer" :spellcheck="settings.text_spell_check_allowed"
                 :disabled="disabled" :question-index="question_index"
                 @update:userAnswers="updateUserAnswer"></TextBox>
      </div>

      <div v-if="question.sub_type == constant.QUESTION_ESSAY">
        <TextArea :question="question" :user-answer="state.user_answer" :spellcheck="settings.text_spell_check_allowed"
                  :disabled="disabled" :question-index="question_index"
                  @update:userAnswers="updateUserAnswer"></TextArea>
      </div>


    </ion-card-content>
    <ion-card-content class="ion-no-padding not-editable-text" v-else>
      {{ state.language_set.question_already_taken }}
    </ion-card-content>
  </ion-card>
</template>

<script>
import {IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon} from '@ionic/vue';
import {computed, defineComponent, reactive} from 'vue';
import MultipleChoice from "./questions/MultipleChoice";
import MultipleResponse from "./questions/MultipleResponse";
import TextBox from "./questions/TextBox";
import TextArea from "./questions/TextArea";
import MatchingDropdown from "./questions/MatchingDropdown";
import MatchingMedia from "./questions/MatchingMedia";
import UpdateAnswerMessageEmitter from "@/utils/UpdateAnswerMessageEmitter";
import UserAnswer from "../../store/model/userAnswer";
import constant from "../../constant";
import store from "@/store";
import Grammar from "./questions/Grammar";
import stringUtils from "../../utils/stringUtils";

export default defineComponent({
  name: 'SurveyQuestion',
  components: {
    Grammar,
    MultipleChoice,
    MultipleResponse,
    TextBox,
    TextArea,
    MatchingMedia,
    MatchingDropdown,
    IonCard,
    IonCardHeader,
    IonButton,
    IonIcon,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,
  },
  props: {
    question: Object,
    question_index: Number,
    numOfQuestion: Number,
    userAnswer: {
      type: UserAnswer
    },
    flagged: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    displayError: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },

  setup(props, ctx) {

    const state = reactive({
      flagged: props.userAnswer.flagged,
      user_answer: props.userAnswer.answer,
      type: props.question.sub_type,
      language_set: store.getters.languageSet,
      editable: props.userAnswer.editable
    });


    const settings = store.getters.testSettings;
    const {emitAnswers} = UpdateAnswerMessageEmitter(ctx);
    const {formatNumber} = new stringUtils();


    function toggleBookMark() {
      state.flagged = !state.flagged;
      let data = {
        question_id: props.question.id,
        flagged: state.flagged
      }
      ctx.emit('flag_change', data);
    }

    const activeBookMark = computed(() => {
      if (state.flagged == true) {
        return "md hydrated test__card--bookmark--selected";
      }
      return 'md hydrated';
    })

    const updateUserAnswer = (answers => {

      let data = {};
      if (state.type == constant.QUESTION_MATCHING) {
        let converted_answer = {}
        let clue_ids = Object.keys(answers);
        for (const clue_id of clue_ids) {
          converted_answer[clue_id] = answers[clue_id];
        }

        data = {
          answers: {
            // HACK: answers object are not converted in POST request body properly. So, force convert as JSON object
            user_answer: converted_answer,
            clues: state.user_answer['clues'],
            matches: state.user_answer['matches'],
          },
          type: state.type
        };
      } else {
        data = {
          answers: answers,
          type: state.type
        };
      }

      // use composition api to setup answer message emitter
      emitAnswers(props.question.id, data);
    });

    const getMatchingUserAnswers = computed(function () {
      // case 1: user answer was set and updated by VueJS
      if (state.user_answer !== null && 'user_answer' in state.user_answer) {
        return state.user_answer.user_answer;
      }
      // case 2. user never answered question before
      else {
        return {};
      }
    });

    return {state, toggleBookMark, activeBookMark, updateUserAnswer, constant, getMatchingUserAnswers, formatNumber, settings};
  },


});
</script>

<style scoped lang="scss">
@import '../../theme/classmarker_theme';

.test {
  &__card {
    margin: 0px;
    page-break-inside: avoid;

    &.error {
      border-color: $color-red;
    }

    &--header {
      background-color: $color-gray-01;
      height: 72px;
      display: grid;
      grid-template-columns: 1fr max-content;
      padding-left: 24px;
      font-family: $default-font-family;
      page-break-inside: avoid;

      & ion-card-title {
        font-size: $medium-2-font-size;
        font-weight: bold;
        color: $text-color-primary;
        page-break-inside: avoid;
      }

      & ion-card-subtitle {
        font-size: $default-font-size;
        color: $text-color-secondary;
        line-height: 22px;
        letter-spacing: 0.02em;
        page-break-inside: avoid;
      }
    }


    &--bookmark {
      --ripple-color: transparent !important;
      --background-hover: transparent !important;

      &--icon {
        font-size: $font-size-icon;
        fill: $color-white;
        stroke: $color-gray-03;

        &:hover {
          stroke: $color-tertiary;
        }
      }

      &--selected {
        fill: $color-tertiary;
        stroke: $color-tertiary;
      }

      &:hover {

        & .test__card--bookmark--icon {
          fill: $color-white;
          stroke: $color-tertiary;
        }

        & .test__card--bookmark--selected {
          fill: $color-tertiary;
          stroke: $color-tertiary;
        }
      }
    }
  }
}

.not-editable-text {
  padding: $medium-space;
}

/**
Common CSS components for child components
 */
:deep(.question-text) {
  margin-left: $medium-space;
  margin-right: $medium-space;
  margin-top: $medium-space;
  //font-family: 'Arial';
  line-height: 22px;
  //font-size: $default-font-size !important;
  color: $text-color-primary;
  display: block;
  width: calc(100% - #{$medium-space} * 2);
}

:deep(.question-option-list) {
  margin-left: $medium-space;
  margin-right: $medium-space;
}

:deep(.question-option) {
  //display: grid;
  display: inline-block;
  grid-template-columns: max-content 1fr;
  column-gap: $small-space;
  width: 100%;
}


:deep(.question-content-area) {
  margin-bottom: $medium-space;
  font-family: $default-font-family;
  align-self: flex-start;
  justify-self: center;
  font-size: $default-font-size
}

:deep(.question-index) {
  font-weight: bold;
  width: 20px;
  position: relative;
  float: left;
}

:deep(ion-list-header .question-text) {
  font-weight: initial;
  font-size: 14px;
}


</style>