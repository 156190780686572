<template>
  <ion-header class="header ion-no-border" :class="defaultHeader">

    <div id="logo-area" class="header__logo" v-if="!hasCustomHeader">
      <img id="cm_logo" data-cy="logo-classmarker"
           :src="BASE_URL+'static/images/classmarker-logo.svg'"
           alt="ClassMarker logo" :style="logoStyle" width="150"/>

    </div>
    <div id="logo-area" class="header__logo_custom" v-bind:class="{'width-100': !state.theme.header_img}" v-else-if="!hasEmptyHeader">
      <div class="header__logo_custom__img" v-if="state.theme.header_img" data-cy="logo-custom">
        <div class="flex bbcode" v-if="isHeaderImageExternalURL">
          <img :src="state.theme.header_img" alt="logo"/>
        </div>
        <html-content :content="state.theme.header_img" :flex-display="true" v-else/>
      </div>
      <h1 class="width-100" v-else-if="state.theme.display_title" data-cy="title-custom">{{ state.theme.display_title }}</h1>
    </div>

    <BackToStudentUiButton class="student-ui-back-button"></BackToStudentUiButton>


  </ion-header>
</template>

<script>
import {IonCol, IonGrid, IonHeader, IonImg, IonRow, IonToolbar} from '@ionic/vue';
import {computed, defineComponent, reactive} from 'vue';
import store from "@/store";
import HtmlContent from "./HtmlContent";
import Banner from "./Banner";
import GoogleAds from "./GoogleAds";
import BackToStudentUiButton from "./BackToStudentUiButton";


export default defineComponent({
  name: 'Header',
  components: {
    HtmlContent,
    IonHeader,
    IonImg,
    IonToolbar,
    IonGrid,
    IonCol,
    IonRow,
    Banner,
    GoogleAds,
    BackToStudentUiButton
  },
  props: {
    logoStyle: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  setup(props, ctx) {
    const state = reactive({
      theme: store.getters.theme,
      price: store.state.Test.data.price,
    });

    const BASE_URL = process.env.BASE_URL;


    const hasCustomHeader = computed(() => {
      if (state.theme.is_custom_theme) {
        return true;
      }
      return false;
    });

    const hasEmptyHeader = computed(() => {
      if ((state.theme.header_img != null && state.theme.header_img != '') ||
            (state.theme.display_title != null && state.theme.display_title != '')) {
        return false;
      }
      return true;
    });

    const isHeaderImageExternalURL = computed(() => {
      if (state.theme.header_img != null && state.theme.header_img != '' && isValidHttpUrl(state.theme.header_img)) {
        return true;
      }
      return false;
    });

    const isValidHttpUrl = (string) => {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return true;
    }

    const defaultHeader = computed(() => {
      if (!hasCustomHeader.value) {
        return 'default-header';
      }
    })


    return {state, hasCustomHeader, hasEmptyHeader, isHeaderImageExternalURL, defaultHeader, BASE_URL};
  },


});
</script>

<style lang="scss" scoped>
/*@import '@/theme/classmarker_theme';*/

ion-toolbar {
  width: fit-content;
}

.header {

  &.default-header {
    min-height: $height-max-logo;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__logo {
    & img {
      margin-top: 24px;
      max-height: $height-max-logo;

      //margin-bottom: 58px;
      margin-bottom: 38px;

      @include responsive(size-medium) {
        max-height: $height-max-logo-mobile;
      }
    }
  }


  &__logo_custom {
    .header__logo_custom__img {
      :deep(img){
        margin-right: auto;
        max-width: 100%;
        width: max-content;
      }
    }
  }
}

.student-ui-back-button{
  display: inline-block;
  position: absolute;
  top: 25px;
  right: 0px;
}
</style>
