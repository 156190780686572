<template>
  <ion-content>
    <ion-list class='popup-content' lines="none">
      <ion-item class="popup-title">
        <ion-label>Not Available:</ion-label>
      </ion-item>
      <ion-item class="popup-item">
        Save results
      </ion-item>
      <ion-item class="popup-item">
        Email Results
      </ion-item>

      <ion-item class="popup-item">
        Webhooks
      </ion-item>
    </ion-list>

  </ion-content>
</template>

<script>
import {IonContent, IonItem, IonLabel, IonList, IonListHeader} from '@ionic/vue';
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'PreviewLimitedFeaturePopover',
  components: {IonContent,
    IonLabel,
    IonList,
    IonListHeader,
    IonItem
  }
});
</script>
<style lang="scss" scoped>
ion-content, ion-list, ion-item, ion-list{
  --background: #2D2F3F;
  background: #2D2F3F;


}

ion-list{
  margin:0px;
  padding-top:0px;
  padding-bottom:0px;

  ion-list-header, ion-item{
    color:$color-white;
  }
}



.popup-title{
  font-weight: bold;
  padding: 0px;
  font-size:11px;
  line-height: 22px;
  height:22px;
  font-family: $default-font-family;
  display: flex;
  align-items: center;
  margin-bottom:3px;
}

.popup-item{
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 22px;
  height: 22px;
  display: flex;
  align-items: center;
}
</style>