/*

 START XBBCODE

 Copyright (C) 2011 Patrick Gillespie, http://patorjk.com/

 Permission is hereby granted, free of charge, to any person obtaining a copy
 of this software and associated documentation files (the "Software"), to deal
 in the Software without restriction, including without limitation the rights
 to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 copies of the Software, and to permit persons to whom the Software is
 furnished to do so, subject to the following conditions:

 The above copyright notice and this permission notice shall be included in
 all copies or substantial portions of the Software.

 THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 THE SOFTWARE.
 */
/*
 Extendible BBCode Parser v1.0.0
 By Patrick Gillespie (patorjk@gmail.com)
 Website: http://patorjk.com/

 This module allows you to parse BBCode and to extend to the mark-up language
 to add in your own tags.
 */


/*
 * Modified for CLASSMARKER
 */
var freeAccount = null;
export default function bbCode(str, freeAcount) {
    freeAccount = freeAcount;

	if (str.indexOf("[") == -1) {
		return str;
	}

	var result = XBBCODE.process({
		text: str,
		removeMisalignedTags: false,
		addInLineBreaks: false,
	});
	return result.html;
};

function getDocPathForDropDown(str) {
	/**
	 * User to create paths for files from BBCode, including doc, image, video, audio
	 */
	let asset_ref_dir = new Array(
		/(0\/)/,
		/(1\/)/,
		/(2\/)/
	);


	let asset_actual_dir = new Array(
		process.env.VUE_APP_S3_FILE_BUCKET,//'https://0cm.classmarker.com/', /img/doc/pdf/files
		process.env.VUE_APP_S3_VIDEO_BUCKET,//'https://1cm.classmarker.com/', //video
		process.env.VUE_APP_S3_AUDIO_BUCKET,//'https://2cm.classmarker.com/'  //audio
	);


	for (let i = 0; i < asset_ref_dir.length; i++) {
		str = str.replace(asset_ref_dir[i], asset_actual_dir[i]);
	}

	return str;
}

function useIframe() {
	if (navigator.appVersion.indexOf("MSIE 9.") == -1 && navigator.appVersion.indexOf("MSIE 8.") == -1 && navigator.appVersion.indexOf("MSIE 7.") == -1 && navigator.appVersion.indexOf("MSIE 6.") == -1) {
		return false;
	}
	return true;
}



const XBBCODE = (function() {
	"use strict";
	// -----------------------------------------------------------------------------
	// Set up private variables
	// -----------------------------------------------------------------------------
	let audio_video_warning = "";
	var s3_assets_path = 'https://';//for S3 use 'https://s3.amazonaws.com/';
	var me = {},
	//urlPattern = /^(?:https?|file|c):(?:\/{1,3}|\\{1})[-a-zA-Z0-9:@#%&()~_?\+=\/\\\.]*$/,
		urlPattern = /^(http|mailto)/,//some old bbcode may use mailto:
		imgPattern = /^http/,
		colorNamePattern = /^(?:red|green|blue|orange|yellow|black|white|brown|gray|silver|purple|maroon|fushsia|lime|olive|navy|teal|aqua)$/,
		colorCodePattern = /^#?[a-fA-F0-9]{6}$/,
		tags,
		tagList,
		tagsNoParseList = [],
		bbRegExp,
		pbbRegExp,
		pbbRegExp2,
		openTags,
		closeTags;

	/* -----------------------------------------------------------------------------
	 * tags
	 * This object contains a list of tags that your code will be able to understand.
	 * Each tag object has the following properties:
	 *
	 *   openTag - A function that takes in the tag's parameters (if any) and its
	 *			 contents, and returns what its HTML open tag should be.
	 *			 Example: [color=red]test[/color] would take in "=red" as a
	 *			 parameter input, and "test" as a content input.
	 *			 It should be noted that any BBCode inside of "content" will have
	 *			 been processed by the time it enter the openTag function.
	 *
	 *   closeTag - A function that takes in the tag's parameters (if any) and its
	 *			  contents, and returns what its HTML close tag should be.
	 *
	 *   displayContent - Defaults to true. If false, the content for the tag will
	 *					not be displayed. This is useful for tags like IMG where
	 *					its contents are actually a parameter input.
	 *
	 *   restrictChildrenTo - A list of BBCode tags which are allowed to be nested
	 *						within this BBCode tag. If this property is omitted,
	 *						any BBCode tag may be nested within the tag.
	 *
	 *   restrictParentsTo - A list of BBCode tags which are allowed to be parents of
	 *					   this BBCode tag. If this property is omitted, any BBCode
	 *					   tag may be a parent of the tag.
	 *
	 *   noParse - true or false. If true, none of the content WITHIN this tag will be
	 *			 parsed by the XBBCode parser.
	 *
	 *
	 *
	 * LIMITIONS on adding NEW TAGS:
	 *  - Tag names should be alphanumeric (including underscores) and all tags should have an opening tag
	 *	and a closing tag.
	 *	The [*] tag is an exception because it was already a standard
	 *	bbcode tag. Technecially tags don't *have* to be alphanumeric, but since
	 *	regular expressions are used to parse the text, if you use a non-alphanumeric
	 *	tag names, just make sure the tag name gets escaped properly (if needed).
	 * --------------------------------------------------------------------------- */


	tags = {
		"b": {
			openTag: function(params,content) {
				return '<strong>';
			},
			closeTag: function(params,content) {
				return '</strong>';
			}
		},
		/*
		 This tag does nothing and is here mostly to be used as a classification for
		 the bbcode input when evaluating parent-child tag relationships
		 */
		"bbcode": {
			openTag: function(params,content) {
				return '';
			},
			closeTag: function(params,content) {
				return '';
			}
		},
		 "code": {
		 openTag: function(params,content) {
		 return '<span class="xbbcode-code">';
		 },
		 closeTag: function(params,content) {
		 return '</span>';
		 },
		 noParse: true
		 },
		"color": {
			openTag: function(params,content) {

				var colorCode = params.substr(1) || "black";
				colorNamePattern.lastIndex = 0;
				colorCodePattern.lastIndex = 0;
				if ( !colorNamePattern.test( colorCode ) ) {
					if ( !colorCodePattern.test( colorCode ) ) {
						colorCode = "black";
					} else {
						if (colorCode.substr(0,1) !== "#") {
							colorCode = "#" + colorCode;
						}
					}
				}

				return '<span style="color:' + colorCode + '">';
			},
			closeTag: function(params,content) {
				return '</span>';
			}
		},
		"i": {
			openTag: function(params,content) {
				return '<i>';
			},
			closeTag: function(params,content) {
				return '</i>';
			}
		},
		"img": {
			openTag: function(params,content) {

				var myUrl = content;

				urlPattern.lastIndex = 0;
				if ( !imgPattern.test( myUrl ) ) {
					myUrl = "";
				}
				return '<img src="' + myUrl + '" class="imgw" alt="" border="0"/>';
			},
			closeTag: function(params,content) {
				return '';
			},
			displayContent: false
		},
		"email": {
			openTag: function(params,content) {

				var myUrl;

				if (!params) {
					myUrl = content.replace(/<.*?>/g,"");
				} else {
					myUrl = params.substr(1);
				}

				//urlPattern.lastIndex = 0;
				//if ( !urlPattern.test( myUrl ) ) {
				//myUrl = "#";
				//}

				return '<a href="mailto:' + myUrl + '" target="_blank" class="custom_color" rel="nofollow">';//popup: go to new tab so we don't leave test (incase chrome opens gmail etc)
			},
			closeTag: function(params,content) {
				return '</a>';
			}
		},
		"cmimg": {
			openTag: function(params,content) {

				/*
				 content will be "dir/filename.ext" form cm database
				 [cmimg]0/46_HGTYHY.jpg[/cmimg]
				 */
				var path = content.replace(/<.*?>/g,"");
				//separate image path and dir
				var pathArray = path.split("/");

				if (pathArray.length == 2){
					//We have valid path - else don't display at all.
					let s3_path = process.env.VUE_APP_S3_FILE_BUCKET;
					return '<img src="'+s3_path +  pathArray[1] + '" class="imgw" alt="" border="0"/>';

					//return '<img src="'+s3_assets_path + pathArray[0] + 'cm.classmarker.com/' +  pathArray[1] + '" class="imgw" alt="" border="0"/>';
					//example path http://0cm.classmarker.com/image.jpg
				}
			},
			closeTag: function(params,content) {
				return '';
			},
			displayContent: false
		},
		"cmvideo": {
			openTag: function(params,content) {

				/*
				 content will be "dir/filename.ext" form cm database
				 [cmimg]0/46_HGTYHY.jpg[/cmimg]
				 //example path https://1cm.classmarker.com/video.mp4
				 */
				return '<video  data-cmvideo-url="' + content + '" width="560" height="315" controls controlsList="nodownload"> <source class="sceditor-ignore" src="' + getDocPathForDropDown(content) + '" type="video/mp4">' + audio_video_warning + '</video>';
			},
			closeTag: function(params,content) {
				return '';
			},
			displayContent: false
		},
		"exvideo": {
			openTag: function(params,content) {
				//We have valid path - else don't display at all.
				return '<video data-exvideo-url="' + content + '" width="560" height="315" controls controlsList="nodownload"> <source class="sceditor-ignore" src="' + content + '" type="video/mp4">' + audio_video_warning + '</video>';
				//example path https://example.com/video.mp4
			},
			closeTag: function(params,content) {
				return '';
			},
			displayContent: false
		},
		"cmaudio": {
			openTag: function(params,content) {

				/*
				 content will be "dir/filename.ext" form cm database
				 [cmaudio]2/46_HGTYHY.mp3[/cmaudio]
				 */
				var path = content.replace(/<.*?>/g,"");
				//separate image path and dir
				var pathArray = path.split("/");

				if (pathArray.length == 2){
					//We have valid path - else don't display at all.
					// return '<div style="position:relative; width:fit-content">' +
					// 	'<audio data-cmaudio-url="' + path + '" controls controlsList="nodownload"> <source class="sceditor-ignore" src="' + getDocPathForDropDown(path) + '" type="audio/mpeg">' + audio_video_warning + '</audio>' +
					// 	'<div style=" background : #F2F2F2; position : absolute; top : 3px; right : 3px; width : 2.8em; height : 3.4em; border-radius : 2.8em;"></div></div>';

					return '<audio data-cmaudio-url="' + path + '" controls controlsList="nodownload"> <source class="sceditor-ignore" src="' + getDocPathForDropDown(path) + '" type="audio/mpeg">' + audio_video_warning + '</audio>';
					//example path http://2cm.classmarker.com/audio.mp3
				}
			},
			closeTag: function(params,content) {
				return '';
			},
			displayContent: false
		},
		"exaudio": {
			openTag: function(params,content) {
				//We have valid path - else don't display at all.
				return '<audio data-exaudio-url="' + content + '" controls controlsList="nodownload"> <source class="sceditor-ignore" src="' + content + '" type="audio/mpeg">' + audio_video_warning + '</audio>';
                //example path https://example.com/audio.mp3
			},
			closeTag: function(params,content) {
				return '';
			},
			displayContent: false
		},
		"soundcloud": {
			openTag: function(params,content) {
				if (typeof isMSIE8 !== 'undefined'){
					return audio_video_warning;
				} else {
					return '<div class="video-container"><iframe scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?visual=true&url='+content+'&show_artwork=true&auto_play=false&maxheight=160&maxwidth=600" style="width:100%; height:160px"></iframe></div>';
				}
			},
			closeTag: function(params,content) {
				return '';
			},
			displayContent: false
		},
		"cmdoc": {
			openTag: function(params,content) {

				/*
				 content will be "dir/filename.ext" form cm database
				 [cmdoc=0/46_YTHGYT.pdf]Document link title[/cmdoc]
				 */
				var path = params.substr(1);
				//separate image path and dir
				var pathArray = path.split("/");

				if (pathArray.length == 2){
					let s3_path = process.env.VUE_APP_S3_FILE_BUCKET;
					return '<a href="'+s3_path +  pathArray[1] + '" target="_blank" class="custom_color" rel="nofollow">';
					//We have valid path
					//return '<a href="'+s3_assets_path + pathArray[0] + 'cm.classmarker.com/' +  pathArray[1] + '" class="popup custom_color" rel="nofollow">';
				} else {
					return '<a href="javascript:alert(\'Invalid document link.\');">';
					//example path http://0cm.classmarker.com/document.docx
				}
			},
			closeTag: function(params,content) {
				return '</a>';
			},
			displayContent: true
		},
		"ul": {
			openTag: function(params,content) {
				return '<ul>';
			},
			closeTag: function(params,content) {
				return '</ul>';
			},
			restrictChildrenTo: ["*", "li"]
		},
		"ol": {
			openTag: function(params,content) {
				return '<ol>';
			},
			closeTag: function(params,content) {
				return '</ol>';
			},
			restrictChildrenTo: ["*", "li"]
		},
		"li": {
			openTag: function(params,content) {
				return "<li>";
			},
			closeTag: function(params,content) {
				return "</li>";
			},
			restrictParentsTo: ["ul", "li"]
		},/*
		 "noparse": {
		 openTag: function(params,content) {
		 return '';
		 },
		 closeTag: function(params,content) {
		 return '';
		 },
		 noParse: true
		 },
		 "php": {
		 openTag: function(params,content) {
		 return '<span class="xbbcode-code">';
		 },
		 closeTag: function(params,content) {
		 return '</span>';
		 },
		 noParse: true
		 },
		 "quote": {
		 openTag: function(params,content) {
		 return '<blockquote class="xbbcode-blockquote">';
		 },
		 closeTag: function(params,content) {
		 return '</blockquote>';
		 }
		 },*/
		"s": {
			openTag: function(params,content) {
				return '<del>';
			},
			closeTag: function(params,content) {
				return '</del>';
			}
		},
		"sup": {
			openTag: function(params,content) {
				return '<span class="sup">';
			},
			closeTag: function(params,content) {
				return '</span>';
			}
		},
		"sub": {
			openTag: function(params,content) {
				return '<span class="sub">';
			},
			closeTag: function(params,content) {
				return '</span>';
			}
		},
		"sqr": {
			openTag: function(params,content) {
				return '&radic;<span class="sqr"> ';//keep space after >
			},
			closeTag: function(params,content) {
				return '</span>';
			}
		},
		"font": {
			openTag: function(params,content) {
				var myFont = params.substr(1);
				return '<span class="bbcodeFont' + myFont + '"> ';//keep space after >
			},
			closeTag: function(params,content) {
				return '</span>';
			}
		},
		"size": {
			openTag: function(params,content) {

				var mySize = parseInt(params.substr(1),10) || 0;
				if (mySize < 1 || mySize > 7) {
					mySize = 2;
				}

				return '<span class="bbcodeSize' + mySize + '">';
			},
			closeTag: function(params,content) {
				return '</span>';
			}
		},
		"table": {
			openTag: function(params,content) {
				return '<div class="cm-table-responsive"><table class="bbcodeTable">';
			},
			closeTag: function(params,content) {
				return '</table></div>';
			},
			restrictChildrenTo: ["tbody","thead", "tfoot", "tr"]
		},
		"tbody": {
			openTag: function(params,content) {
				return '<tbody>';
			},
			closeTag: function(params,content) {
				return '</tbody>';
			},
			restrictChildrenTo: ["tr"],
			restrictParentsTo: ["table"]
		},
		"tfoot": {
			openTag: function(params,content) {
				return '<tfoot>';
			},
			closeTag: function(params,content) {
				return '</tfoot>';
			},
			restrictChildrenTo: ["tr"],
			restrictParentsTo: ["table"]
		},
		"thead": {
			openTag: function(params,content) {
				return '<thead>';
			},
			closeTag: function(params,content) {
				return '</thead>';
			},
			restrictChildrenTo: ["tr"],
			restrictParentsTo: ["table"]
		},
		"td": {
			openTag: function(params,content) {
				return '<td>';
			},
			closeTag: function(params,content) {
				return '</td>';
			},
			restrictParentsTo: ["tr"]
		},
		"th": {
			openTag: function(params,content) {
				return '<td>';
			},
			closeTag: function(params,content) {
				return '</td>';
			},
			restrictParentsTo: ["tr"]
		},
		"tr": {
			openTag: function(params,content) {
				return '<tr>';
			},
			closeTag: function(params,content) {
				return '</tr>';
			},
			restrictChildrenTo: ["td","th"],
			restrictParentsTo: ["table","tbody","tfoot","thead"]
		},
		"u": {
			openTag: function(params,content) {
				return '<u>';
			},
			closeTag: function(params,content) {
				return '</u>';
			}
		},
		"url": {
			openTag: function(params,content) {

				var myUrl;

				if (!params) {
					myUrl = content.replace(/<.*?>/g,"");
				} else {
					myUrl = params.substr(1);
				}

				urlPattern.lastIndex = 0;
				if ( !urlPattern.test( myUrl ) ) {
					myUrl = "javascript:alert('Invalid link was entered here.');";
				}

                if(freeAccount){
                    return '<a href="' + myUrl + '" onclick="checkExternalLink(event, \''+ myUrl+'\');">';
                }else{
                    return '<a href="' + myUrl + '" target="_blank" class="custom_color" rel="nofollow">';
                }

			},
			closeTag: function(params,content) {
				return '</a>';
			}
		},
		"yt": {
			openTag: function(params,content) {


				// content will be 11 digit yt ID "ytgfR4j7yh" form cm database
				var myYouTubeId = content.replace(/<.*?>/g,"");
				if (myYouTubeId.length != 11){
					myYouTubeId = "";
				}

				//detect flash
				//var _flash_installed = ((typeof navigator.plugins != "undefined" && typeof navigator.plugins["Shockwave Flash"] == "object") || (window.ActiveXObject && (new ActiveXObject("ShockwaveFlash.ShockwaveFlash")) != false));
				if (useIframe()){
					return '<div class="video-container"><object style="width:100%; max-width:560px;" height="315"><param name="movie" value="https://www.youtube-nocookie.com/v/'+myYouTubeId+'?rel=0&amp;showsearch=0&amp;showinfo=0"></param><param name="allowFullScreen" value="true"></param><param name="allowscriptaccess" value="always"></param><embed src="https://www.youtube-nocookie.com/v/'+myYouTubeId+'?rel=0&amp;showsearch=0&amp;showinfo=0" type="application/x-shockwave-flash" allowscriptaccess="always" allowfullscreen="true" width="560" height="315"></embed></object></div>';
				} else {
					return '<div class="video-container"><iframe style="width:100%; max-width:560px;" height="315" src="https://www.youtube-nocookie.com/embed/'+myYouTubeId+'?rel=0&amp;showsearch=0&amp;showinfo=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe></div>';//ie7 throws JS errors
				}
			},
			closeTag: function(params,content) {
				return '';
			},
			displayContent: false
		},
		"vimeo": {
			openTag: function(params,content) {

				// content will full vimeo URL form cm database
				var myVimeoUrlId = content.replace(/<.*?>/g,"");
				if ( urlPattern.test(myVimeoUrlId) ){
					myVimeoUrlId = "";
				}
				return '<div class="video-container"><iframe style="width:100%; max-width:560px;" src="https://player.vimeo.com/video/'+myVimeoUrlId+'?title=0&amp;byline=0&amp;portrait=1" height="315" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>';
			},
			closeTag: function(params,content) {
				return '';
			},
			displayContent: false
		},
		"rtl": {
			openTag: function(params,content) {
				return '<div dir="rtl">';
			},
			closeTag: function(params,content) {
				return '</div>';
			}
		},
		"ltr": {
			openTag: function(params,content) {
				return '';
			},
			closeTag: function(params,content) {
				return '';
			}
		},
		/*
		 The [*] tag is special since the user does not define a closing [/*] tag when writing their bbcode.
		 Instead this module parses the code and adds the closing [/*] tag in for them. None of the tags you
		 add will act like this and this tag is an exception to the others.
		 */
		"*": {
			openTag: function(params,content) {
				return "<li>";
			},
			closeTag: function(params,content) {
				return "</li>";
			},
			restrictParentsTo: ["list"]
		}
	};




	// create tag list and lookup fields
	tagList = [];
	(function() {
		var prop,
			ii,
			len;
		for (prop in tags) {
			if (tags.hasOwnProperty(prop)) {
				if (prop === "*") {
					tagList.push("\\" + prop);
				} else {
					tagList.push(prop);
					if ( tags[prop].noParse ) {
						tagsNoParseList.push(prop);
					}
				}

				tags[prop].validChildLookup = {};
				tags[prop].validParentLookup = {};
				tags[prop].restrictParentsTo = tags[prop].restrictParentsTo || [];
				tags[prop].restrictChildrenTo = tags[prop].restrictChildrenTo || [];

				len = tags[prop].restrictChildrenTo.length;
				for (ii = 0; ii < len; ii++) {
					tags[prop].validChildLookup[ tags[prop].restrictChildrenTo[ii] ] = true;
				}
				len = tags[prop].restrictParentsTo.length;
				for (ii = 0; ii < len; ii++) {
					tags[prop].validParentLookup[ tags[prop].restrictParentsTo[ii] ] = true;
				}
			}
		}
	})();

	bbRegExp = new RegExp("<bbcl=([0-9]+) (" + tagList.join("|") + ")([ =][^>]*?)?>((?:.|[\\r\\n])*?)<bbcl=\\1 /\\2>", "gi");
	pbbRegExp = new RegExp("\\[(" + tagList.join("|") + ")([ =][^\\]]*?)?\\]([^\\[]*?)\\[/\\1\\]", "gi");
	pbbRegExp2 = new RegExp("\\[(" + tagsNoParseList.join("|") + ")([ =][^\\]]*?)?\\]([\\s\\S]*?)\\[/\\1\\]", "gi");

	// create the regex for escaping ['s that aren't apart of tags
	(function() {
		var closeTagList = [];
		for (var ii = 0; ii < tagList.length; ii++) {
			if ( tagList[ii] !== "\\*" ) { // the * tag doesn't have an offical closing tag
				closeTagList.push ( "/" + tagList[ii] );
			}
		}

		openTags = new RegExp("(\\[)((?:" + tagList.join("|") + ")(?:[ =][^\\]]*?)?)(\\])", "gi");
		closeTags = new RegExp("(\\[)(" + closeTagList.join("|") + ")(\\])", "gi");
	})();

	// -----------------------------------------------------------------------------
	// private functions
	// -----------------------------------------------------------------------------

	function checkParentChildRestrictions(parentTag, bbcode, bbcodeLevel, tagName, tagParams, tagContents, errQueue) {

		errQueue = errQueue || [];
		bbcodeLevel++;

		// get a list of all of the child tags to this tag
		var reTagNames = new RegExp("(<bbcl=" + bbcodeLevel + " )(" + tagList.join("|") + ")([ =>])","gi"),
			reTagNamesParts = new RegExp("(<bbcl=" + bbcodeLevel + " )(" + tagList.join("|") + ")([ =>])","i"),
			matchingTags = tagContents.match(reTagNames) || [],
			cInfo,
			errStr,
			ii,
			childTag,
			pInfo = tags[parentTag] || {};

		reTagNames.lastIndex = 0;

		if (!matchingTags) {
			tagContents = "";
		}

		for (ii = 0; ii < matchingTags.length; ii++) {
			reTagNamesParts.lastIndex = 0;
			childTag = (matchingTags[ii].match(reTagNamesParts))[2].toLowerCase();

			if ( pInfo.restrictChildrenTo.length > 0 ) {
				if ( !pInfo.validChildLookup[childTag] ) {
					errStr = "The tag \"" + childTag + "\" is not allowed as a child of the tag \"" + parentTag + "\".";
					errQueue.push(errStr);
				}
			}
			cInfo = tags[childTag] || {};
			if ( cInfo.restrictParentsTo.length > 0 ) {
				if ( !cInfo.validParentLookup[parentTag] ) {
					errStr = "The tag \"" + parentTag + "\" is not allowed as a parent of the tag \"" + childTag + "\".";
					errQueue.push(errStr);
				}
			}

		}

		tagContents = tagContents.replace(bbRegExp, function(matchStr, bbcodeLevel, tagName, tagParams, tagContents ) {
			errQueue = checkParentChildRestrictions(tagName, matchStr, bbcodeLevel, tagName, tagParams, tagContents, errQueue);
			return matchStr;
		});
		return errQueue;
	}

	/*
	 This function updates or adds a piece of metadata to each tag called "bbcl" which
	 indicates how deeply nested a particular tag was in the bbcode. This property is removed
	 from the HTML code tags at the end of the processing.
	 */
	function updateTagDepths(tagContents) {
		tagContents = tagContents.replace(/\<([^\>][^\>]*?)\>/gi, function(matchStr, subMatchStr) {
			var bbCodeLevel = subMatchStr.match(/^bbcl=([0-9]+) /);
			if (bbCodeLevel === null) {
				return "<bbcl=0 " + subMatchStr + ">";
			} else {
				return "<" + subMatchStr.replace(/^(bbcl=)([0-9]+)/, function(matchStr, m1, m2) {
					return m1 + (parseInt(m2, 10) + 1);
				}) + ">";
			}
		});
		return tagContents;
	}

	/*
	 This function removes the metadata added by the updateTagDepths function
	 */
	function unprocess(tagContent) {
		return tagContent.replace(/<bbcl=[0-9]+ \/\*>/gi,"").replace(/<bbcl=[0-9]+ /gi,"&#91;").replace(/>/gi,"&#93;");
	}

	var replaceFunct = function(matchStr, bbcodeLevel, tagName, tagParams, tagContents) {

		tagName = tagName.toLowerCase();

		var processedContent = tags[tagName].noParse ? unprocess(tagContents) : tagContents.replace(bbRegExp, replaceFunct),
			openTag = tags[tagName].openTag(tagParams,processedContent),
			closeTag = tags[tagName].closeTag(tagParams,processedContent);

		if ( tags[tagName].displayContent === false) {
			processedContent = "";
		}

		return openTag + processedContent + closeTag;
	};




    function parse(config) {
		var output = config.text;
		output = output.replace(bbRegExp, replaceFunct);
		return output;
	}

	/*
	 The star tag [*] is special in that it does not use a closing tag. Since this parser requires that tags to have a closing
	 tag, we must pre-process the input and add in closing tags [/*] for the star tag.
	 We have a little levaridge in that we know the text we're processing wont contain the <> characters (they have been
	 changed into their HTML entity form to prevent XSS and code injection), so we can use those characters as markers to
	 help us define boundaries and figure out where to place the [/*] tags.
	 */
	function fixStarTag(text) {
		text = text.replace(/\[(?!\*[ =\]]|list([ =][^\]]*)?\]|\/list[\]])/ig, "<");
		text = text.replace(/\[(?=list([ =][^\]]*)?\]|\/list[\]])/ig, ">");

		while (text !== (text = text.replace(/>list([ =][^\]]*)?\]([^>]*?)(>\/list])/gi, function(matchStr,contents,endTag) {

			var innerListTxt = matchStr;
			while (innerListTxt !== (innerListTxt = innerListTxt.replace(/\[\*\]([^\[]*?)(\[\*\]|>\/list])/i, function(matchStr,contents,endTag) {
				if (endTag === ">/list]") {
					endTag = "</*]</list]";
				} else {
					endTag = "</*][*]";
				}
				var tmp = "<*]" + contents + endTag;
				return tmp;
			})));

			innerListTxt = innerListTxt.replace(/>/g, "<");
			return innerListTxt;
		})));

		// add ['s for our tags back in
		text = text.replace(/</g, "[");
		return text;
	};

	function addBbcodeLevels(text) {
		while ( text !== (text = text.replace(pbbRegExp, function(matchStr, tagName, tagParams, tagContents) {
			matchStr = matchStr.replace(/\[/g, "<");
			matchStr = matchStr.replace(/\]/g, ">");
			return updateTagDepths(matchStr);
		})) );
		return text;
	}

	// -----------------------------------------------------------------------------
	// public functions
	// -----------------------------------------------------------------------------

	me.process = function(config) {

		var ret = {html: "", error: false},
			errQueue = [];

		/* CM uses php function nl2br on text before buffering to output - so we need to preserve HTML BR tags without replacing them */
		config.text = config.text.replace(/(<br\/>|<br \/>|<br>|<br >)/ig, "x6yWQ4Vs");//unique text no one should use - replace back below

		config.text = config.text.replace(/</g, "&lt;"); // escape HTML tag brackets
		config.text = config.text.replace(/>/g, "&gt;"); // escape HTML tag brackets

		config.text = config.text.replace(openTags, function(matchStr, openB, contents, closeB) {
			return "<" + contents + ">";
		});
		config.text = config.text.replace(closeTags, function(matchStr, openB, contents, closeB) {
			return "<" + contents + ">";
		});

		config.text = config.text.replace(/\[/g, "&#91;"); // escape ['s that aren't apart of tags
		config.text = config.text.replace(/\]/g, "&#93;"); // escape ['s that aren't apart of tags
		config.text = config.text.replace(/</g, "["); // escape ['s that aren't apart of tags
		config.text = config.text.replace(/>/g, "]"); // escape ['s that aren't apart of tags

		// process tags that don't have their content parsed
		while ( config.text !== (config.text = config.text.replace(pbbRegExp2, function(matchStr, tagName, tagParams, tagContents) {
			tagContents = tagContents.replace(/\[/g, "&#91;");
			tagContents = tagContents.replace(/\]/g, "&#93;");
			tagParams = tagParams || "";
			tagContents = tagContents || "";
			return "[" + tagName + tagParams + "]" + tagContents + "[/" + tagName + "]";
		})) );

		config.text = fixStarTag(config.text); // add in closing tags for the [*] tag
		config.text = addBbcodeLevels(config.text); // add in level metadata

		errQueue = checkParentChildRestrictions("bbcode", config.text, -1, "", "", config.text);

		ret.html = parse(config);

		if ( ret.html.indexOf("[") !== -1 || ret.html.indexOf("]") !== -1) {
			errQueue.push("Some tags appear to be misaligned.");
		}

		if (config.removeMisalignedTags) {
			ret.html = ret.html.replace(/\[.*?\]/g,"");
		}
		if (config.addInLineBreaks) {
			ret.html = ret.html.replace(/\r\n/g, "\n");
			ret.html = ret.html.replace(/(\r|\n)/g, "$1<br/>");
		}

		ret.html = ret.html.replace("&#91;", "["); // put ['s back in
		ret.html = ret.html.replace("&#93;", "]"); // put ['s back in

		ret.html = ret.html.replace(/x6yWQ4Vs/g, "<br/>");//x6yWQ4Vs must match string above in initial replace()

		ret.error = (errQueue.length === 0) ? false : true;
		ret.errorQueue = errQueue;

		return ret;
	}

	return me;
})();
/*****   END XBBCODE	*****/
