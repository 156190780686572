import {useGtm} from "@gtm-support/vue-gtm";
import store from "@/store";
import constant from "@/constant";

export default function googleTagManagerUtils() {
    const pushEventData = function (type, info) {
        const gtm = useGtm();
        const theme = store.getters.theme;
        const is_free = theme.show_ads;
        const group_type_const = store.getters.groupType;

        gtm.trackEvent({
            event: 'test_app_event',
            group_type: group_type_const,
            is_free: is_free,
            event_type: type,
            event_info: info
        });

    }


    return {pushEventData}
}


