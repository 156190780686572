<template>
  <ion-grid>
    <ion-list class=" ion-no-padding">
      <div class="question-text">
        <html-content :content="question.question" :allow-magnifying-image="true"/>
      </div>
      <ion-item class="match-list" v-for="(clue, index) in question.options.clues" :key="clue.id">
        <ion-row role="group">
          <ion-col class="match-text-area" size-xs="12" size-sm="6">
            <ion-label class="ion-text-wrap overflow-x-auto" :id="'question-'+questionIndex+'-clue-'+index">
              <html-content :content="clue.content" :allow-magnifying-image="true"/>
            </ion-label>
          </ion-col>
          <ion-col class="match-select-area" size-xs="12" size-sm="6">
            <select name="matches" class="match-select" @change="updateAnswer($event, clue.id)"
                    :aria-labelledby="'question-'+questionIndex+'-clue-'+index">
              <option value=""> {{ state.language_set.matching_select }}</option>
              <option v-for="match in question.options.matches" :key="match.id" :value="match.id"
                      :selected="isSelected(clue.id, match.id)"> {{ match.content }}
              </option>
            </select>
          </ion-col>
        </ion-row>
      </ion-item>
    </ion-list>
  </ion-grid>
</template>

<script>
import {IonCol, IonGrid, IonItem, IonLabel, IonList, IonRow} from '@ionic/vue';
import {defineComponent, reactive} from 'vue';
import HtmlContent from "../../html/HtmlContent";
import store from "@/store";

export default defineComponent({
  name: 'MatchingDropdown',
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    HtmlContent,
  },
  props: {
    question: Object,
    userAnswer: {
      type: Object,
      default: () => {
        return {};
      }
    },
    questionIndex: {
      type: Number
    }
  },
  emits: ['update:userAnswers'],
  setup(props, ctx) {
    const state = reactive({
      user_answer: props.userAnswer,
      language_set: store.getters.languageSet,
    });

    function updateAnswer(event, clue_id) {
      state.user_answer[clue_id] = event.target.value
      ctx.emit('update:userAnswers', state.user_answer);
    }

    const isSelected = (function (clue_id, match_id) {
      if (props.userAnswer != null) {
        if (clue_id in props.userAnswer && match_id === props.userAnswer[clue_id]) {
          return true;
        }
      }
      return false;
    });

    return {state, isSelected, updateAnswer};
  },


});
</script>

<style lang="scss" scoped>
@import '../../../theme/classmarker_theme';

.question-text {
  margin-top: $medium-space;
  margin-bottom: $medium-space;

}

.match-list {
  padding-left: $medium-space;
  padding-right: $medium-space;
  --min-height: 66px;

  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: $default-font-size;
  line-height: 22px;
  /* or 169% */

  letter-spacing: 0.02em;
  --border-color: #{$border-color-gray};

  &:first-of-type {
    --inner-border-width: 1px 0 1px 0;
  }

  &:last-child {
    --border-color: white;
    margin-bottom: $between-space;
  }
}

.match-text-area {
  @include responsive(size-small) {
    padding: 16px 0px 4px 0px;

  }
}

.match-select-area {
  @include responsive(size-small) {
    padding: 4px 0px 16px 0px;

  }

  .match-select {
    float: right;
  }

}

ion-row {
  width: 100%;
}

</style>