<template>
  <ion-grid>
    <ion-list class=" ion-no-padding">
      <ion-label class="question-text ion-text-wrap" text-wrap>
        <html-content :content="question.question" :allow-magnifying-image="true"/>
      </ion-label>
      <ion-item class="match-list" v-for="clue in question.options.clues" :key="clue.id">
        <ion-row>
          <ion-col class="match-text-area" size-xs="12" size-sm="6">
            <ion-label class="ion-text-wrap overflow-x-auto">
              <html-content :content="clue.content" :allow-magnifying-image="true"/>

            </ion-label>
          </ion-col>
          <ion-col size-xs="12" size-sm="6"  style="padding-right:0px;">
            <div class="answer-area" v-bind:class="{'no-answer' : !getMatchedUserContent(clue.id)}">
              <div v-if="getMatchedUserContent(clue.id)">
                {{ getMatchedUserContent(clue.id) }}
              </div>
              <div v-else>
                {{ state.language_set.no_answer_given }}
              </div>

              <ion-icon class="icon icon-correct" :class="getCorrectIconClassName(clue.id)"
                        :aria-label="$getCorrectAriaText(getCorrectIconClassName(clue.id))"
                        :aria-hidden="$getCorrectAriaText(getCorrectIconClassName(clue.id))=='' ? true : false"

              ></ion-icon>
            </div>
            <div class="correct-answer-text"
                 v-if="feedback.correct_answer !==null && correctAnswerLength>0 && !isCorrect(clue.id) && getCorrectContent(clue.id)">
              <strong>{{ state.language_set.quiz_correct_answer }}:</strong> {{ getCorrectContent(clue.id) }}
            </div>
          </ion-col>
        </ion-row>
      </ion-item>
    </ion-list>
  </ion-grid>
</template>

<script>
import {IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonRow} from '@ionic/vue';
import {computed, defineComponent, reactive} from 'vue';
import HtmlContent from "../../html/HtmlContent";
import store from "@/store";

export default defineComponent({
  name: 'MatchingDropdown',
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    HtmlContent,
    IonIcon
  },
  props: {
    question: Object,
    feedback: Object,
    userAnswer: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  emits: ['update:userAnswers'],
  setup(props, ctx) {
    const state = reactive({
      match_contents: {},
      language_set: store.getters.languageSet,
    });

    // set match contents
    props.question.options.matches.forEach((match) => {
      state.match_contents[match.id] = match.content;
    });


    const isSelected = (function (clue_id, match_id) {
      if (props.userAnswer != null) {
        if (clue_id in props.userAnswer && match_id === props.userAnswer[clue_id]) {
          return true;
        }
      }
      return false;
    });

    function getMatchedUserContent(clue_id) {
      if (props.userAnswer != null && clue_id in props.userAnswer) {
        let content = state.match_contents[props.userAnswer[clue_id]];
        if (content != '' && content != undefined) {
          return state.match_contents[props.userAnswer[clue_id]];
        } else {
          return null;
        }
      }
      return null;
    }

    function getCorrectContent(clue_id) {
      let match_id = props.feedback.correct_answer[clue_id];
      let content = state.match_contents[match_id];
      return content;
    }

    function isCorrect(clue_id) {
      // The Vue not stop call isCorrect although condition is not meet
      if (props.feedback.correct_answer == null) {
        return;
      }

      let user_match_id = null;
      if (props.userAnswer != null && clue_id in props.userAnswer) {
        user_match_id = props.userAnswer[clue_id];
        if(user_match_id == null){
          return false;
        }else if (user_match_id == props.feedback.correct_answer[clue_id]) {
          return true;
        }
      }

      return false;
    }

    function getCorrectIconClassName(clue_id) {
      if (props.feedback.correct_answer == null) {
        return '';
      }
      let icon = '';
      let correct = isCorrect(clue_id)

      if (correct) {
        icon = 'circular-tick';
      } else {
        icon = 'circular-x';
      }

      let reverse_text = store.getters.reverseText;
      if (reverse_text) {
        icon += ' rtl';
      }
      return icon;

    }

    const correctAnswerLength = computed(() => {
      let length = 0;
      if (props.feedback != null) {
        length = Object.keys(props.feedback.correct_answer).length;
      }
      return length;
    })


    return {
      state,
      isSelected,
      getMatchedUserContent,
      getCorrectContent,
      isCorrect,
      getCorrectIconClassName,
      correctAnswerLength
    };
  },


});
</script>

<style lang="scss" scoped>
@import '../../../theme/classmarker_theme';

.question-text {
  margin-top: $medium-space;
  margin-bottom: $medium-space;

}

.match-list {
  padding-left: $medium-space;
  //padding-right: $medium-space;
  padding-right: 4%;
  --min-height: 66px;

  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: $default-font-size;
  line-height: 22px;
  /* or 169% */

  letter-spacing: 0.02em;

  /* Text primary */

  color: #454F59;
  --border-color: #{$border-color-gray};


  &:first-of-type {
    --inner-border-width: 1px 0 1px 0;
  }

  &:last-child {
    --border-color: white;
    margin-bottom: $between-space;
  }
}

.match-select {
  float: right;
  padding-left: 10px;
  color: $color-gray-secondary;
  border: 1px solid #BEC8D1;
  box-sizing: border-box;
  border-radius: 4px;
  height: 35px;
  text-align: left;
  min-height: 35px;
  min-width: 244px;
  @include responsive(size-medium) {
    min-width: 100%;
  }
}

ion-row {
  width: 100%;
  padding: 18px 0px 18px 0px;
}

.answer-area {
  border-radius: 5px;
  border: none;
  background-color: $color-gray-01;
  padding: 10px 20px 10px 20px;
  width: 100%;
  min-height: $input-feedback-height;

  &.no-answer {
    width: calc(100% - 2px);
    background-color: $color-white;
    border: 1px solid #E7E7E7;
  }
}

.correct-answer-text {
  margin-top: 12px;
  padding-left: 1px;
}

.icon-correct {
  top: 16px !important;
  right: 13px !important;

  &.rtl {
    right: calc(100% - 32px) !important;
  }
}

</style>