<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="template">
        <test-title :display-price="false"></test-title>
        <transition name="fade">
          <ion-card v-if="state.display">
            <GlobalErrorModal button-action="button_clicked" :button-message="'Check again'"
                              v-on:button_clicked="refreshBrowser()" :bbcode="false" :disable-button="disableButton">
              <template v-slot:extra>
                <span id="avail-count-down" v-if="disableButton">Check availability: {{ state.availability_count }} Seconds</span>
              </template>
            </GlobalErrorModal>
          </ion-card>
        </transition>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonCard, IonCardContent, IonContent, IonPage} from '@ionic/vue';
import {computed, defineComponent, getCurrentInstance, reactive} from 'vue';
import GlobalErrorModal from "@/components/html/GlobalErrorModal";
import Header from "@/components/html/Header";
import TestTitle from "@/components/html/TestTitle";
import testClient from "../../api/testClient";
import store from "@/store";
import constant from "../../constant";


export default defineComponent({
  name: 'SimUserLimitMessage',
  components: {
    GlobalErrorModal,
    IonCardContent,
    IonCard,
    IonPage,
    IonContent,
    Header,
    TestTitle,
  },
  beforeMount() {
  },
  mounted() {
    /**
     * Remove before unload event. The APP.vue file is loaded later than this page,
     * unloadEvent setup should be delayed until App.vue file is loaded
     */
    setTimeout(() => {
      window.removeEventListener('beforeunload', this.$popupUnLoadEvent);
    }, 10);
  },
  setup(props, ctx) {

    // get current instance just like this
    const internalInstance = getCurrentInstance();
    let globalEmitter = internalInstance.appContext.config.globalProperties.$globalEmitter


    let quiz_id = new URL(location.href).searchParams.get('quiz') ?? '';
    let token = store.getters.accessToken;
    const state = reactive({
      display: true,
      availability_count:60

    });


    const offline_state = computed(() => {
      return store.getters.offlineStatus;
    });



    async function refreshBrowser() {

      // hide offline message
      state.display = false;

      if (token === null) {
        window.location.reload();
      }

      //when status is offline => check online status
      if (offline_state.value == constant.STATUS_ONLINE_WAITING_SIM_USER_SLOT) {
        await checkCanTakeTest();
        state.availability_count = 60;
      }

    }

    async function checkCanTakeTest() {
      testClient.canStartTest(token).then((can_take_test) => {

        if (can_take_test == true) {
          // Start test
          window.location.reload();
        }
        setTimeout(() => {
          state.display = true;
        }, 1000)
      });
    }

    setInterval(()=>{
      if(disableButton.value === true){
        state.availability_count = state.availability_count -1;
      }
    }, 1000)


    let disableButton = computed(()=>{
      return (state.availability_count > 0 ? true: false);
    })

    return {state, quiz_id, refreshBrowser,  disableButton};
  },
});
</script>

<style scoped lang="scss">
.template {
  padding-top: 30px;
}

.icon-flag {
  width: 53px;
  height: 68.96px;
  background-size: 53px;
  position: relative;
  display: block;
  margin: auto;
  margin-top: 93px;
}

ion-card-header {
  color: $text-color-primary;
  font-size: 24px;
  //styleName: H1;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 28px;
}

ion-card-content {
  //styleName: Body / Regular;
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
  width: 62%;
  display: block;
  margin: auto;
  margin-bottom: 74px;

}

:deep(#avail-count-down) {
  display: block;
  margin-top:20px;
  text-align:center;
  width:100%;
}
</style>