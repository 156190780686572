export default function timeUtil() {

    const convertDurationString = ((input_seconds, format='HH:MM:SS') => {

        let hours = Math.floor(input_seconds / 60 / 60);
        let minutes = Math.floor((input_seconds - hours * 60 * 60) / 60);
        let seconds = input_seconds - (hours * 60 * 60) - (minutes * 60);

        if(format  == 'HH:MM:SS') {
            return doubleDigitInteger(hours) + ':' + doubleDigitInteger(minutes) + ':' + doubleDigitInteger(seconds);
        }else if(format == 'HH:MM'){
            return doubleDigitInteger(hours) + ':' + doubleDigitInteger(minutes);
        }

    });

    const doubleDigitInteger = (($num) => {
        if ($num < 10) {
            return '0' + $num.toString();
        } else
            return $num.toString();
    })

    const timestampToString = ((timestamp, locale_code) => {
        let date = new Date(timestamp * 1000);
        let datetime = date.toLocaleDateString(locale_code).toString();
        let timeString = date.toLocaleTimeString(locale_code).toString();
        return datetime + " " + timeString;
    });

    const getCurrentTimestamp = (()=>{
        return Math.floor(Date.now() / 1000)
    });


    return {convertDurationString, timestampToString, getCurrentTimestamp}
}


