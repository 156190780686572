<template>
  <ion-card class="timer">
    <span class="timer__text">
      <ion-icon class="icon clock" aria-hidden="true"/>
      <strong>{{ state.language_set.test_time_left }}:</strong>
      <span class="test-time-left" data-cy="test-time-left-card">{{
          convertDurationString(timeoutRemainingTime)
        }}</span>
    </span>

    <div class="timer__progress">
      <ion-progress-bar class="timer_progress_bar" :value="progressRatio"></ion-progress-bar>
    </div>
  </ion-card>
</template>

<script>
import {IonCard, IonIcon, IonProgressBar} from '@ionic/vue';
import {defineComponent, reactive} from 'vue';
import store from "@/store";
import timeUtil from "../../utils/timeUtil";

export default defineComponent({
  name: 'TimerCard',
  components: {IonIcon, IonProgressBar, IonCard},
  props: {
    progressRatio: Number,
    timeoutRemainingTime: Number
  },
  setup(props, ctx) {
    const state = reactive({
      language_set: store.getters.languageSet,
    });
    const {convertDurationString} = new timeUtil();
    return {state, convertDurationString}
  }
});
</script>

<style lang="scss" scoped>
@import '../../theme/classmarker_theme';

.timer {
  height: 79px;
  padding: 24px 24px 24px 24px;
  margin: 24px 0px 12px 0px;
  font-family: $default-font-family;

  @include responsive(size-small) {
    margin: 12px 0px 12px 0px
  }


  .clock {
    position: relative;
    top: 2px;
    margin-right: 9px;
  }

  &__progress {
    margin-top: 10px;

    & ion-progress-bar {
      --background: #E9ECEF;
      border-radius: 10px;
    }
  }

  .test-time-left {
    float: right;
  }
}

html[dir=rtl] {
  .timer {
    .clock{
      margin-right: auto;
      margin-left: 9px;
    }

    .test-time-left {
      float: left;
    }
  }
}

</style>