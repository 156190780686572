<template>
  <ion-list class="question-content-area ion-no-padding" lines="none" style="padding-top: 0;">
    <ion-list-header class="check-list-header ion-no-padding">
      <div class="input">
        <ion-label class="question-text ion-text-wrap">
          <html-content :content="question.question" :allow-magnifying-image="true"/>
        </ion-label>
      </div>
    </ion-list-header>

    <ion-item class="answer-area">
      <div class="user-answer">
        <strong>{{ state.language_set.answer_given }}:</strong> <br/>
        <div id="user-answer-text-area">
          <text id="user-answer-text">{{ userAnswer }}</text>

        </div>
      </div>
    </ion-item>
  </ion-list>
</template>

<script>
import {IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader} from '@ionic/vue';
import {computed, defineComponent, reactive} from 'vue';
import HtmlContent from "../../html/HtmlContent";
import store from '@/store';

export default defineComponent({
  name: 'SurveyFreeText',
  components: {
    IonInput,
    IonItem,
    IonLabel,
    IonListHeader,
    IonList,
    HtmlContent,
    IonIcon
  },
  props: {
    question: Object,
    userAnswer: String
  },
  setup(props, ctx) {
    const state = reactive({
      register: true,
      user_answer: props.userAnswer,
      language_set: store.getters.languageSet
    });

    return {state};
  },
});
</script>

<style lang="scss" scoped>
@import '../../../theme/classmarker_theme';

.answer-area {
  margin-top: $between-space;
  margin-bottom: $between-space;
  margin-left: $medium-space;
  margin-right: $medium-space;
}

#user-answer-text-area {
  margin-top:2px;
  background: $color-gray-01;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
  padding-right: 18px;
  position: relative;
  min-height: $input-feedback-height;
  border-radius: $default-border-radius;
}

#user-answer-text {
  width: 90%;
  display: inline-block;
}

.user-answer {
  width: 100%;
}

.question-text {
  padding-bottom: 20px;
  border-bottom: 1px solid #E8E8E8;
}

.icon-correct {
  margin-right: 5px;
}


</style>