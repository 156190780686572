import httpClient from "./httpClient";
import store from "@/store";

const USER_PATH = '/user'

/**
 *
 * @param token : API Access Token
 * @param current_page: target page for submit answers
 * @param answers: List of UserAnswer Object
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function resume(token, email, password) {
    let quiz_id = store.getters.quizId;
    // construct payload to submit an answer to api end points
    let payload = {
        login_email: email,
        password: password
    };


    return httpClient.post(token, USER_PATH + '/resume?quiz='+quiz_id, payload);
}

function checkEmailInUse(token, email){
    let quiz_id = store.getters.quizId;
    let payload = {
        login_email : email
    }
    return httpClient.post(token, USER_PATH+'/check-email?quiz='+quiz_id, payload);
}

function forgetPassword(token, email){
    let quiz_id =store.getters.quizId
    let payload = {
        email : email
    }
    return httpClient.post(token, USER_PATH+'/forget-password?quiz='+quiz_id, payload)
}


export default {resume, checkEmailInUse, forgetPassword};