<template>
  <ion-list class="checkbox-list question-content-area" lines="none" style="padding-top: 0;" ref="question_ref">
    <ion-list-header class="ion-no-padding">
      <div class="input">
        <div class="question-text">
          <html-content :content="question.question" :allow-magnifying-image="true"/>
        </div>
      </div>
    </ion-list-header>

    <ion-item v-for="(option,index) in question.options" :key="index"

    >
      <div class="checkbox-options-area question-text">
        <ion-checkbox class="checkbox" mode="md" color="secondary" v-bind:value="option.id"
                      @ionChange="updateAnswers($event, option.id)"
                      :disabled="disabled"
                      :checked="isSelcted(option.id, userAnswer)"
                      :id="generateOptionId(question, option)"
                      aria-hidden="true"></ion-checkbox>
        <ion-label class="option-area ion-text-wrap"
                   @mouseover="labelHover(generateOptionId(question, option))"
                   @mouseleave="labelLeave(generateOptionId(question, option))"
                   @click="$multipleChoiceLabelClicked($event, generateOptionId(question, option))"
        >

          <div class="question-option">
            <div class="question-index" :class="isReverseClass"> {{ convertIntToAlphabetIndex(index, language_set) }}.</div>
            <div class="question-content with-index-text overflow-x-auto">
              <html-content :content="option.content" :allow-magnifying-image="true" css-class="cursor-pointer"
                            data-cy="question-option-text"/>
            </div>
          </div>

        </ion-label>
      </div>
    </ion-item>
  </ion-list>
</template>

<script>
import {IonCheckbox, IonItem, IonLabel, IonList, IonListHeader} from '@ionic/vue';
import {computed, defineComponent, onMounted, onUnmounted, reactive, ref} from 'vue';
import stringUtils from '../../../utils/stringUtils';
import HtmlContent from "../../html/HtmlContent";
import store from '@/store';

export default defineComponent({
  name: 'MultipleResponse',
  components: {
    IonList,
    IonListHeader,
    IonCheckbox,
    IonItem,
    IonLabel,
    HtmlContent,
  },
  props: {
    question: Object,
    userAnswer: {
      type: Array,
      default: () => {
        return []
      }
    },
    disabled: {
      type: Boolean
    },
    questionIndex: {
      type: Number
    }
  },
  setup(props, ctx) {
    const state = reactive({
      register: true,
      user_answer: props.userAnswer
    });
    const language_set = store.getters.languageSet;

    const {convertIntToAlphabetIndex} = stringUtils();

    const question_ref = ref({});

    function updateAnswers(event) {

      if (state.user_answer == null) {
        state.user_answer = [];
      }
      let value = event.detail['value'];
      let checked = event.detail['checked'];
      let update_required = true;

      if (checked == true) {
        // Add answers when
        // 1. max_num_of_option is not set or,
        // 2. the number of user's answers is less than max_num_of_options
        if (props.question.max_num_of_option == null
            || state.user_answer.length < props.question.max_num_of_option) {
          state.user_answer.push(value);
        } else {
          //if user's cannot add more answers, then show popup message of information.
          event.target.setAttribute('checked', false);
          update_required = false;
          store.dispatch('Error/display', {
            type: 'info',
            error_code: 'cannot_select_more_options',
            message: language_set['cannot_select_more_options']
          });
        }
      } else {
        // Remove answers
        let index = state.user_answer.indexOf(value);
        if (index >= 0) {
          state.user_answer.splice(index, 1);
        }
      }

      if (update_required) {
        ctx.emit('update:userAnswers', state.user_answer);
      }
    }


    function isSelcted(value, userAnswer) {
      if (userAnswer == null) {
        return false;
      }
      let index = userAnswer.indexOf(value);
      if (index >= 0) {
        return true;
      } else {
        return false;
      }
    }

    function generateOptionId(question, option) {
      return 'question-' + question.id + '-' + option.id;
    }

    function labelHover(radio_button_id) {
      let element = document.getElementById(radio_button_id);
      if (element != null) {
        element.classList.add('radio-checkbox-hover');
      }
    }

    function labelLeave(radio_button_id) {
      let element = document.getElementById(radio_button_id);
      if (element != null) {
        element.classList.remove('radio-checkbox-hover');
      }
    }


    let timeout_id = null;

    onMounted(() => {

      /**
       * Hack remove label from ion checkbox shadow root.
       * Ionic Framework generate shadowRoot that overwrapping original HTML tags
       * So, users are not able to click label contents such as image and links also not able to play
       * video and audio in the options.
       * Below code will remove shadow root from Dom element.
       */
      let loop_count = 0;

      // wait for 1 second to create shadow root and label area
      timeout_id = setInterval(() => {
        // Get Radio reference element
        const radio_dom = question_ref.value.$el;
        if (radio_dom != null) {
          let labels = radio_dom.querySelectorAll('ion-checkbox');

          if (labels != null) {

            labels.forEach((label) => {

              if (label != null && label.shadowRoot != null) {
                let inner_label = label.shadowRoot.querySelector('label');
                if (inner_label != null) {

                  // TO support aria text reader, width has to be 0px instead of display none
                  inner_label.style.width = '0px';
                  // inner_label.style.display = 'none';
                  clearInterval(timeout_id);
                  timeout_id = null;
                }
              }

            });
          }
        }

        // if loop count reaches 50, kill the loop;
        loop_count++;
        if (loop_count == 100) {
          clearInterval(timeout_id);
        }
      }, 200);
    })

    onUnmounted(() => {
      if (timeout_id != null) {
        clearTimeout(timeout_id);
      }
    })


    let reverseText = store.getters.reverseText;
    const isReverseClass = computed(() => {
      if (reverseText) {
        return 'rtl'
      }
    })


    return {
      state,
      question_ref,
      convertIntToAlphabetIndex,
      updateAnswers,
      isSelcted,
      generateOptionId,
      labelHover,
      labelLeave,
      language_set,
      isReverseClass
    };
  },


});
</script>

<style lang="scss" scoped>
@import '../../../theme/classmarker_theme';

ion-item {
  //margin-top: 0px !important;
  //margin-bottom: 0px !important;
  ion-checkbox {
    cursor: pointer;
  }

  &:first-of-type {
    margin-top: 12px !important;
  }

  .checkbox-options-area {
    column-gap: 0px !important;


    .option-area {
      margin-left: 16px;
      cursor: pointer;


    }
  }
}

/**
Ensure items in ion-label's are clickable
 */
ion-label {
  pointer-events: all !important;
  min-height: 36px !important;

  //line-height: 36px !important;
  margin-left:0px !important;
  padding-left:16px;
}

.question-content {

  &.with-index-text {
    margin-left: 20px;
  }
}


</style>