<template>
  <ion-page :aria-hiden="contentAriaHidden">

    <ion-header class="ion-no-border" v-bind:class="setupAppDefaultCssClasses()">
      <ion-button id="close-button-wrapper" type="button" fill="clear" class="icon-button " @click="dismiss()" aria-label="Close Questions Modal">
        <ion-icon class="icon close-button" aria-hidden="true"></ion-icon>

      </ion-button>
    </ion-header>

    <ion-content class="ion-no-padding" v-bind:class="setupAppDefaultCssClasses()" style="--background:white">
      <ion-grid>
        <ion-row>
          <ion-col size-xs="11" size-sm="6" class="ion-no-padding">

            <ion-segment class="filter-menu" mode="md" @ionChange="segmentChanged($event)"
                         :value="state.content_filter">
              <ion-segment-button value="all" class="filter-menu__all">
                {{ state.language_set.link_question_filter_all }}

              </ion-segment-button>
              <ion-segment-button value="bookmarks" class="filter-menu__bookmark">
                <div>
                  <ion-icon class="icon bookmark-selected"/>
                  {{ state.language_set.link_question_filter_flagged }} <span
                    class="filter-menu__bookmark__bookmark_num">{{ state.num_of_bookmark }}</span>
                </div>
              </ion-segment-button>
            </ion-segment>
          </ion-col>
          <ion-col size-xs="1" size-sm="6">
            <ion-button id="expand-button" fill="clear" class="icon-button" @click="toggleContent()" aria-label="Toggle all Questions text">
              <ion-icon class="icon icon-expand" aria-hidden="true"> </ion-icon>

            </ion-button>
          </ion-col>
        </ion-row>
        <div class="divider"></div>


      </ion-grid>

      <bookmark-list :contents="filteredContents" v-on:jump-to-page="jumpToPage"></bookmark-list>

    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from '@ionic/vue';
import {computed, defineComponent, reactive} from 'vue';
import store from "@/store";
import constant from "../../constant";
import TestHelper from "../../views/TestHelper";
import HtmlContent from "../html/HtmlContent";
import BookmarkList from './BookmarkList';
import htmlSetupUtil from "../../utils/htmlSetupUtil";

export default defineComponent({
  name: 'BookmarkModal',
  components: {
    HtmlContent,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonPage,
    IonButton,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonSegment,
    IonSegmentButton,
    BookmarkList,
    IonGrid,
    IonRow,
    IonCol,

  },
  emits: ['jump-to-page', 'dismiss-bookmark-modal'],
  props:{
    contentAriaHidden:{
      type:Boolean,
    }
  },
  setup(props, ctx) {
    const state = reactive({
      language_set: store.getters.languageSet,
      sections: store.state.Test.data.test.sections,
      contents: [],
      num_of_bookmark: 0,
      content_filter: 'all',
      opened: false
    });

    /**
     * setup data
     */
    if (state.contents.length == 0) {
      state.contents = __setContents();
    }

    /**
     * Send a message to the parent to close modal
     */
    function dismiss() {
      ctx.emit('dismiss-bookmark-modal');
    }


    /**
     * Toggle question content
     */
    function toggleContent() {
      let all_opened = true;
      for (let content of state.contents) {
        if (!content.opened) {
          all_opened = false;
          break;
        }
      }


      state.contents.forEach((content) => {
        content.opened = !all_opened;
      });

    }

    /**
     * Pass message to the parent to next page*/
    function jumpToPage(object) {
      ctx.emit('jump-to-page', object);
    }

    /**
     * Setup Modal Data for render
     */
    function __setContents() {
      let result = [];

      let index = 1;
      for (let section_index in state.sections) {
        let section = state.sections[section_index];

        for (let page_num in section.pages) {
          let page = section.pages[page_num];
          for (let content_index in page.contents) {
            let content = page.contents[content_index];

            if (content.type === constant.CONTENTS_QUESTION||content.type === constant.CONTENTS_SURVEY) {

              let item = {
                "index": index,
                "question": content.question,
                "answered": false,
                "bookmarked": false,
                "section_id": section_index,
                "page": page_num,
                "question_id": content.id,
                "opened": false
              }

              let user_answer = null;
              if (section.user_answers[page_num] && section.user_answers[page_num][content.id]) {
                user_answer = section.user_answers[page_num][content.id];
                if (user_answer.flagged) {
                  item.bookmarked = true;
                  state.num_of_bookmark += 1;
                }

                let answered_status = TestHelper.validateUserAnswer(user_answer);
                item.answered = (answered_status === constant.QUESTION_ANSWERED);

              }
              result.push(item);
              index++;
            }
          }
        }
      }
      return result;
    }

    /**
     * Filter Content by selected filter options
     * @type {ComputedRef<unknown>}
     */
    const filteredContents = computed(() => {
      if (state.content_filter == 'all') {
        return state.contents;
      } else {
        let result = [];
        state.contents.forEach((content) => {
          if (content.bookmarked) {
            result.push(content);
          }
        })
        return result;
      }
    });

    /**
     * Filter option change event
     * @param event
     */
    function segmentChanged(event) {
      state.content_filter = event.detail.value;
    }

    const {setupAppDefaultCssClasses} = htmlSetupUtil();

    return {state, dismiss, toggleContent, filteredContents, segmentChanged, jumpToPage, setupAppDefaultCssClasses}
  }
});
</script>

<style lang="scss" scoped>
@import '../../theme/classmarker_theme';

ion-content {
  background-color: white;
}

ion-header {
  padding: 24px 0px 24px 32px;
  border-bottom: 1px solid $color-gray-02;
  height: 68px;
  font-size: 20px;
}


.modal-content {
  padding-left: 32px;
  padding-right: 27px;
  margin-top: 41px;
  margin-bottom: 52px;
}

.button-area {
  padding-left: 32px;
  padding-right: 27px;
}

#back-button {
  --background: $color-white;
  border: 1px solid $color-gray-02;
  border-radius: 4px;
  color: $text-color-primary;
}


.filter-menu {
  --background: white;

  & ion-segment-button {
    text-transform: capitalize;
    font-weight: normal;

    &.segment-button-checked {
      font-weight: bold;
    }
  }

  &__bookmark {
    .bookmark-selected {
      margin-right: 10px;
      position: relative;
      top: 2px;
    }

    &__bookmark_num {
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      border-radius: 100px;
      min-width: 15px;
      margin-left: 4px;
      padding: 4px 8px;
      font-size: 10px;
      height: 22px;
    }
  }
}

#expand-button {
  position: absolute;
  right: 17px;
  top: 16px;
}

.divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: $border-color-gray;
  position: relative;
  bottom: 1px;
}

#close-button-wrapper {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 22px;
  right: 26px;
  cursor: pointer;
}
</style>