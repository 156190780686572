import constant from "../../constant";

export default class UserAnswer {
    constructor() {
        this.flagged = null;
        this.answer = null;
        this.type = null;
        this.expected_num_of_answer = null; //This is required for matching question. the answer number should be exactly same.
        this.editable = true;
    }

    setAnswer(answer) {
        if( typeof answer == 'string'){
            answer = answer.trim();
        }
        this.answer = answer;
    }

    setFlagged(flagged) {
        this.flagged = flagged;
    }

    setType(type) {
        this.type = type;
    }

    setAnswerFromQuestionObject(question) {

        if (this.type == constant.QUESTION_MATCHING) {
            let answer = {};
            if (question['user_answer'].hasOwnProperty('user_answer')) {
                answer['user_answer'] = question['user_answer']['user_answer'];
            } else {
                answer['user_answer'] = null;
            }
            answer['clues'] = [];
            answer['matches'] = [];
            for (const [_, match] of Object.entries(question.options.matches)) {
                answer['matches'].push(match.id);
            }

            for (const [_, clue] of Object.entries(question.options.clues)) {
                answer['clues'].push(clue.id);
            }

            this.answer = answer;
        } else {
            this.answer = question['user_answer'];
        }


    }

    setNonEditable() {
        if (this.answer != null) {
            if ((typeof this.answer === 'string' || this.answer instanceof String) && this.answer != '') {
                this.editable = false;
            } else if (Array.isArray(this.answer) && this.answer.length > 0) {
                this.editable = false;
            } else if (this.__isAnswered()) { //case matching
                this.editable = false;
            }
            // this.editable = false;
        }
    }

    __isAnswered() {
        // if user answer has its answer property 'user_answer'
        // This is for matching question
        if (this.answer.hasOwnProperty('user_answer')) {
            let user_answer = this.answer.user_answer;

            // when user_answer is empty
            if (user_answer == null ||
                (Array.isArray(user_answer) && user_answer.length == 0)
            ) {
                return false;
            }

            // when question type is matching
            if (this.type == constant.QUESTION_MATCHING) {

                let answered = true;
                for (const [key, value] of Object.entries(user_answer)) {
                    if (value == null) {
                        answered = false;
                    }
                }

                return answered;
            }
        }
    }
}